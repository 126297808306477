import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { protectedRoutes, unprotectedRoutes } from '../../constants/appRoutes';
import ProtectedScreen from '../protected/index';
import { Login } from './Login/LoginMain';
import MainLandingScreen from './LandingPage/MainLandingScreen';
import { useSelector } from 'react-redux';
import TermsAndCondition from './TermsAndCondition/TermsAndCondition';

const UnprotectedScreen = () => {
  const isAuthUser = useSelector(state => state.authReducer.isAuthUser);
    return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLandingScreen />} />
        <Route path={unprotectedRoutes.termsAndCondition} element={<TermsAndCondition />} />
        <Route
          path={protectedRoutes.app}
          element={
            isAuthUser ? (
              <ProtectedScreen />
            ) : (
              <Navigate to={unprotectedRoutes.login} replace />
            )
          }
        />
        <Route
          path={unprotectedRoutes.login}
          element={
            isAuthUser ? (
              <Navigate to={protectedRoutes.connections} replace />
            ) : (
              <Login />
            )
          }
        />
        <Route path="*" element={<Navigate to={'/'} replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default UnprotectedScreen;
