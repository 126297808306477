import React from 'react';
import { Outlet, Route, Router, Routes } from 'react-router-dom';
import Sequence from './SequenceBoard';
import SequenceOption from './SequenceOption';
import SequenceSubscribers from './SequenceSubscriber';
import SequenceReview from './SequenceReview';
import SequenceMain from './SequenceMain';

const SequenceLayout = () => {
  return (
    <Routes>
      <Route index element={<SequenceMain />} />
      <Route path="create" element={<Outlet />}>
        <Route path="options" element={<SequenceOption />} />
        <Route path="subscriber" element={<SequenceSubscribers />} />
        <Route path="sequence" element={<Sequence />} />
        <Route path="review" element={<SequenceReview />} />
      </Route>
      <Route path=":id" element={<Outlet />}>
        <Route path="options" element={<SequenceOption />} />
        <Route path="subscriber" element={<SequenceSubscribers />} />
        <Route path="sequence" element={<Sequence />} />
        <Route path="review" element={<SequenceReview />} />
      </Route>
    </Routes>
  );
};

export default SequenceLayout;
