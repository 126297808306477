export const mainContainer = {
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#f0f2f5',
  paddingX: 3,
  paddingY: 2.3,
  overflowY:"scroll",
  overflowX:"hidden",
};

export const profileContainer = {
  mt: 2,
  display: "flex",
  flexDirection: "column",
  gap: 3,
};

export const profileGrid = {
  bgcolor: "white",
  border: "1px solid #CDCDCD",
  boxShadow: ".5px .5px 2px #CDCDCD",
  padding: 2,
  borderRadius: 2,
};

export const avatar = {
  width: 150,
  height: 150,
  color: "#000",
  fontSize: 30,
  boxShadow: "1px 1px 15px -5px black",
  transition: "all .3s ease",
  "&:hover": {
    transform: "scale(1.05)",
    cursor: "pointer",
  },
  "&:hover .MuiAvatar-img": {
    opacity: 0.5,
  },
};

export const hoveredAvatar = {
  position: "absolute",
  top: 0,
  left: 0,
  width: 100,
  height: 100,
  "& .MuiIconButton-root": {
    opacity: 0,
    transition: "all .3s ease",
  },
  "&:hover .MuiIconButton-root": {
    opacity: 0.9,
  },
};

export const securityGrid = {
  display: "flex",
  gap: 2,
  flexDirection: "column",
  bgcolor: "white",
  border: "1px solid #CDCDCD",
  boxShadow: ".5px .5px 2px #CDCDCD",
  borderRadius: 2,
  padding: 2,
  width: "100%",
};

export const securityGridEmail = {
  width: "80%",
  "& input:disabled": { color: "black" },
  "& .MuiInputLabel-root": { color: "#black" },
  "& .focus": { border: "none" },
};

export const deleteGrid = {
  display: "flex",
  gap: 2,
  flexDirection: "column",
  overflow: "hidden",
  bgcolor: "white",
  border: "1px solid #CDCDCD",
  boxShadow: ".5px .5px 2px #CDCDCD",
  borderRadius: 2,
  padding: 2,
};
