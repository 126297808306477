import toast from 'react-hot-toast';

export const isEmailValid = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (emailRegex.test(email) && !email.includes('+')) {
    return true;
  }
  return false;
};

export const UrlValidation = (inputValue) => {
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  return urlRegex.test(inputValue);
};

export const NameValidation = (inputValue) => {
  const regex = /^[a-zA-Z0-9 ]+$/;
  return regex.test(inputValue);
};

export const handleTitleChange = (inputValue) => {
  const regex = /^[a-zA-Z0-9\s ]+$/;
  return regex.test(inputValue);
};

export const MobileNumberValidation = (Input) => {
  const regex = /^[0-9]{10}$/;
  return regex.test(Input);
};

export const PasswordValidation = (event) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return regex.test(event);
};

export const formatDateTime = (dateTimeStr) => {
  const dateObj = new Date(dateTimeStr);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  const seconds = String(dateObj.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const getCurrentDateTimeLocal = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const day = now.getDate().toString().padStart(2, '0');
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const imageValidation = (file, setErrors) => {
  const allowedExtensions = ['image/jpeg', 'image/jpg', 'image/png'];
  const maxFileSizeMB = 5;
  const fileSizeMB = file.size / 1024 / 1024;

  if (!allowedExtensions.includes(file.type)) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      connectionImage: true,
    }));
    toast.error('Only JPG, JPEG, and PNG files are supported');
    return false;
  }

  if (fileSizeMB > maxFileSizeMB) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      connectionImage: true,
    }));
    toast.error('Image size must be less than 5MB');
    return false;
  }

  setErrors((prevErrors) => ({
    ...prevErrors,
    connectionImage: false,
  }));
  return true;
};
