import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { CustomSelect } from 'components/CustomSelect';
import DeleteIcon from '@mui/icons-material/Delete';
import { TotalSubscribers, UpdateSegment } from 'Api/Api';
import toast from 'react-hot-toast';
import { SideDrawer } from 'components/SideDrawer';
import { Loading } from 'components/Loading';
import { SegmentConditionArray, SegmentFieldsArray} from 'constants/appConstant';
import { formatDateTime } from 'utils/commonFunctions';
import { InnerTextField } from 'components/InputFields';

const EditSegmentModal = ({ open, onClose, itemId, isDetail, refresh }) => {
  const initialField = { field: '', condition: '', value: '' };
  const [segmentData, setSegmentData] = useState({
    segmentName: '',
    status: 'Active',
    subscriberCount: 0,
    condition: {
      And: [],
      Or: [],
    },
  });
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEditChange = () => {
    setEdit(!edit);
  };

  const addCondition = (type) => {
    const newSegmentData = { ...segmentData };
    newSegmentData?.condition[type]?.push({ ...initialField });
    setSegmentData(newSegmentData);
  };

  const removeCondition = (type, index) => {
    const newSegmentData = { ...segmentData };
    newSegmentData?.condition[type].splice(index, 1);
    setSegmentData(newSegmentData);
  };

  const handleTypeChange = (e, type, index) => {
    const { name, value } = e.target;
    const newSegmentData = { ...segmentData };
    newSegmentData.condition[type][index][name] = value;
    setSegmentData(newSegmentData);
  };

  const handleToggle = (e) => {
    const name = e.target.name;
    if (name === 'status') {
      const newStatus =
        segmentData?.status === 'Active' ? 'Inactive' : 'Active';
      setSegmentData((prevState) => ({
        ...prevState,
        status: newStatus,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSegmentData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCount = async () => {
    try {
      let countData = segmentData?.condition;
      const response = await TotalSubscribers(countData);
      if (response?.data?.status === true) {
        const newCount = response?.data?.data;
        setSegmentData((prevState) => ({
          ...prevState,
          subscriberCount: newCount || 0,
        }));
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!segmentData.segmentName.trim()) {
      errors.segmentName = true;
    }
    segmentData.condition.And.forEach((condition, index) => {
      if (!condition.field || !condition.condition || !condition.value) {
        errors[`And_${index}`] = true;
      }
    });
    segmentData.condition.Or.forEach((condition, index) => {
      if (!condition.field || !condition.condition || !condition.value) {
        errors[`Or_${index}`] = true;
      }
    });
    if (Object.keys(errors).length > 0) {
      toast.error('All fields are required.');
      return;
    }
    if (Object.keys(errors).length === 0) {
      try {
        setLoading(true);
        const response = await UpdateSegment(segmentData?.id, segmentData);
        if (response?.data?.status === true) {
          toast.success(response?.data?.message);
          refresh();
          onClose();
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (itemId && itemId.condition !== undefined) {
      try {
        const newItemId = { ...itemId };
        if (itemId.condition && typeof itemId?.condition === 'string') {
          newItemId.condition = JSON.parse(itemId?.condition);
        }
        setSegmentData(newItemId);
      } catch (error) {
        toast.error(error);
      }
    }
  }, [itemId]);

  return (
    <>
      <Loading state={loading} />
      <SideDrawer
        open={open}
        onClose={onClose}
        isDetail={isDetail}
        edit={edit}
        setEdit={handleEditChange}
        title={!edit && isDetail ? 'Segment Details' : 'Edit Segment'}
        handleSubmit={handleSubmit}
      >
        <Box>
          <InnerTextField
            required
            name="segmentName"
            label="Segment Name"
            value={segmentData?.segmentName}
            placeholder="Enter segment name"
            onChange={handleChange}
            readOnly={!edit && isDetail}
          />
        </Box>
        <Typography variant="subtitle2" my={1}>
          All Conditions (All conditions must be met)
        </Typography>
        <>
          {segmentData?.condition?.And?.map((condition, index) => (
            <Grid
              container
              spacing={2}
              key={index}
              alignItems={'center'}
              justifyContent="space-between"
            >
              <Grid item xs={12} sm={4} md={3.5} mt={1}>
                <CustomSelect
                  label="Field"
                  name="field"
                  margin="normal"
                  fullWidth
                  options={SegmentFieldsArray}
                  value={condition?.field}
                  onChange={(e) => handleTypeChange(e, 'And', index)}
                  readOnly={!edit && isDetail}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3.5} mt={1}>
                <CustomSelect
                  label="Condition"
                  name="condition"
                  margin="normal"
                  fullWidth
                  options={SegmentConditionArray}
                  value={condition?.condition}
                  onChange={(e) => handleTypeChange(e, 'And', index)}
                  readOnly={!edit && isDetail}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <InnerTextField
                  label="Value"
                  name="value"
                  value={condition?.value}
                  onChange={(e) => handleTypeChange(e, 'And', index)}
                  readOnly={!edit && isDetail}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={1}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  color="secondary"
                  onClick={() => removeCondition('And', index)}
                  sx={{
                    padding: '0px',
                    width: 'auto',
                    '& .MuiSvgIcon-root': {
                      color: '#F4511E',
                    },
                  }}
                >
                  <DeleteIcon color="#FF7043" />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </>
        <Box my={2}>
          <Button
            onClick={() => addCondition('And')}
            disabled={segmentData?.condition?.And?.length >= 3}
            variant="outlined"
          >
            + Add Condition
          </Button>
        </Box>

        <Typography variant="subtitle2" my={1}>
          Any Conditions (At least one of the conditions must be met)
        </Typography>
        <>
          {segmentData?.condition?.Or?.map((condition, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={10} sm={4} md={3.5} mt={2}>
                <CustomSelect
                  label="Field"
                  name="field"
                  margin="normal"
                  options={SegmentFieldsArray}
                  value={condition?.field}
                  onChange={(e) => handleTypeChange(e, 'Or', index)}
                  readOnly={!edit && isDetail}
                />
              </Grid>
              <Grid item xs={10} sm={3} md={3.5} mt={2}>
                <CustomSelect
                  label="Condition"
                  name="condition"
                  margin="normal"
                  options={SegmentConditionArray}
                  value={condition?.condition}
                  onChange={(e) => handleTypeChange(e, 'Or', index)}
                  readOnly={!edit && isDetail}
                />
              </Grid>
              <Grid item xs={10} sm={4} md={4}>
                <InnerTextField
                  label="Value"
                  name="value"
                  value={condition?.value}
                  onChange={(e) => handleTypeChange(e, 'Or', index)}
                  readOnly={!edit && isDetail}
                />
              </Grid>
              <Grid
                xs={1}
                md={1}
                sm={1}
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {index >= 0 && (
                  <IconButton
                    onClick={() => removeCondition('Or', index)}
                    sx={{
                      padding: '0px',
                      width: 'auto',
                      '& .MuiSvgIcon-root': {
                        color: '#F4511E',
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}
        </>
        <Box my={2}>
          <Button
            onClick={() => addCondition('Or')}
            disabled={segmentData?.condition?.Or?.length >= 3}
            variant="outlined"
          >
            + Add Condition
          </Button>
        </Box>
        <Box my={1}>
          <Typography variant="black_4">Status</Typography>
          <Switch
            name="status"
            size="large"
            checked={segmentData?.status === 'Active'}
            onChange={handleToggle}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          mb={
            segmentData?.condition?.And?.length === 0 &&
            segmentData?.condition?.Or?.length === 0
              ? 14
              : 4
          }
        >
          <Typography variant="black_h4">
            Total numbers of Subscribers : {segmentData?.subscriberCount}
          </Typography>
          <Button variant="outlined" onClick={handleCount}>
            Apply
          </Button>
        </Box>
        <Box>
          {isDetail && !edit ? (
            <Box>
              <Typography variant="subtitle2" my={1}>
                Created time : {formatDateTime(segmentData?.createdTime)}
              </Typography>
              <Typography variant="subtitle2" my={1}>
                Modified time : {formatDateTime(segmentData?.modifiedTime)}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </SideDrawer>
    </>
  );
};

export default EditSegmentModal;
