import {
  Box,
  Button,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ClockIcon from 'assets/Images/Landing/Heading/clock-icon.svg';
import UserIcon from 'assets/Images/Landing/Heading/users-plus.svg';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { useEffect } from 'react';
import logo from '../../../assets/Images/Common/logo.png';

const CommunityBtn = styled(Button)(({ theme }) => ({
  background: 'rgb(62 62 62 / 25%) !important',
  backdropFilter: 'blur(8px)',
  border: '0.5px solid #fff',
  padding: '0.5rem 1rem',
  borderRadius: '0.5rem',
  textTransform: 'capitalize',
  color: '#FFFFFF',
  boxShadow:
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
}));

const EarlyAccessBtn = styled(Button)(({ theme }) => ({
  background: 'rgba(3, 151, 128, 1)',
  color: 'rgba(255, 255, 255, 1)',
  padding: '0.5rem 1.2rem',
  borderRadius: '0.5rem',
  textTransform: 'capitalize',
  border: '1px solid rgba(3, 151, 128, 1)',
  '&:hover': {
    filter: 'dropShadow(0 0 10px #fffdef)',
    borderRadius: '0.5rem',
    transition: '0.3s ease-in-out background',
  },
}));

const HeaderLayout = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap.to('.navbar', {
      duration: 1,
      backgroundColor: 'rgb(62 62 62 / 25%) !important',
      backdropFilter: 'blur(15px)',
      scrollTrigger: {
        trigger: '.navbar',
        start: '100% 2%',
        end: 'top 5%',
        scrub: 2,
      },
    });
  }, []);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: { xs: 1, lg: 0 },
        position: 'fixed',
        top: { xs: 0, lg: 10 },
        left: 0,
        right: 0,
        margin: 'auto',
        zIndex: 1200,
      }}
    >
      <Stack
        className="navbar"
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        width={{ xs: '100%', lg: '80%' }}
        sx={{
          bgcolor: 'inherit',
          backdropFilter: 'blur(0px)',
          borderRadius: '1rem',
          paddingX: '0.5rem',
          paddingY: { xs: '0.5rem', lg: '1.2rem' },
        }}
      >
        <div className="flex h-10  items-center">
          <img
            src={logo}
            alt="logo"
            className="h-8 md:h-12"
            //onClick={HandlerClick}
            loading="lazy"
          />
          <Typography
            sx={{
              fontSize: { xs: '18px', lg: '24px' },
              color: 'rgba(255, 255, 255, 1)',
              fontWeight: 600,
              ml: 2,
            }}
          >
            Lets Notify
          </Typography>
        </div>

        {!isMediumScreen && (
          <Stack direction={'row'} alignItems={'center'} spacing={3}>
            <CommunityBtn
              variant="text"
              onClick={() => {
                window.open('https://discord.gg/qE2dKhArhn');
              }}
            >
              Join Community
            </CommunityBtn>
            <EarlyAccessBtn
              onClick={() => {
                window.open(
                  'https://docs.google.com/forms/d/e/1FAIpQLScAAYJZEj2FsUTrJ8RHNtEUXbPPcLEA87bqV8l8XiPI3yZrkQ/viewform'
                );
              }}
            >
              Get early access
            </EarlyAccessBtn>
          </Stack>
        )}

        {isMediumScreen && (
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <CommunityBtn onClick={() => {
                window.open('https://discord.gg/qE2dKhArhn');
              }}>
              <img src={UserIcon} />
            </CommunityBtn>
            <EarlyAccessBtn onClick={() => {
                window.open(
                  'https://docs.google.com/forms/d/e/1FAIpQLScAAYJZEj2FsUTrJ8RHNtEUXbPPcLEA87bqV8l8XiPI3yZrkQ/viewform'
                );
              }}>
              <img src={ClockIcon} />
            </EarlyAccessBtn>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default HeaderLayout;
