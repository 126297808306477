export const TemplateStyles = {
  fileUploadButton: {
    margin: '-5px',
    marginRight: '10px',
    border: 'none',
    background: '#058270',
    padding: '5px 10px',
    borderRadius: '10px',
    color: '#fff',
    cursor: 'pointer',
    transition: 'background 0.2s ease-in-out',
  },
  InputBorder: {
    '& fieldset': {
      borderWidth: '1px',
      borderColor: '#BEBFC5',
    },
    '&:hover fieldset': {
      borderWidth: '1px',
      borderColor: '#BEBFC5',
    },
    '&.Mui-focused fieldset': {
      borderWidth: '1px',
      borderColor: '#BEBFC5',
    },
  },
};

export const imageFileInput = {
  '& input::file-selector-button': TemplateStyles.fileUploadButton,
  '& input::file-selector-button:hover': {
    backgroundColor: '#045e50',
  },
};