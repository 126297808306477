import { Box, Typography } from '@mui/material'
import React from 'react'
import VideoSrc from "assets/launch-video.mp4"

const VideoContent = ({ classname }) => {
  return (
    <Box className={classname}>
      <Box sx={{position:'relative', top:{xs:0,lg:150}}}> 
        <Box sx={{ display: "flex", alignItems: 'center', flexDirection: "column", justifyContent: "center", padding: 2, height: "100%" }}>
          <Typography variant='h5' color={"#fff"}>Hit Play and Learn How <span style={{ color: 'rgba(5, 255, 216, 1)' }}>Push Notifications</span></Typography>
          <Typography variant='h5' color={"#fff"}>Can Keep You Connected with <span style={{ color: 'rgba(5, 255, 216, 1)' }}>Your Audience!</span></Typography>

          <Box width={{ xs: "100%", md: "50%" }} my={4}>
            <video
              style={{ borderRadius: 5 }}
              src={VideoSrc}
              autoPlay
              controls
              muted
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default VideoContent