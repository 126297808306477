import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import logoSrc from '../../../assets/Images/Common/logo.png';
import loginImage from '../../../assets/Images/UnProtected/Login-page.png';
import signupImage from '../../../assets/Images/UnProtected/Signup-page.png';
import { SignUpContainer } from './SignUpContainer';
import { LoginContainer } from './LoginContainer';
import { ForgotPwdModal } from 'components/Modals';
import ConfirmPasswordContainer from './ChangePwdContainer';

export const Login = () => {
  const [isLogin, setLogin] = useState('login');
  const [openForgetPass, setOpenForgetPass] = useState(false);

  const showSignUp = () => {
    setLogin('signup');
  };

  const showLogin = () => {
    setLogin('login');
  };

  const showForgetPass = () => {
    setOpenForgetPass(true);
  };

  const sendToConfirmPass = () => {
    setOpenForgetPass(false);
    setLogin('confirm-pass');
  };

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        backgroundColor: '#044E43',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <Grid container sx={{ flex: 1, height: '100%', overflow: 'hidden' }}>
        <Grid
          item
          md={7.5}
          xs={12}
          sx={{
            display: { sm: 'none', xs: 'none', md: 'flex' },
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            padding: 3,
            boxSizing: 'border-box',
          }}
        >
          <Box display="flex" alignItems="center" mb={2}>
            <IconButton disableRipple>
              <img width={50} src={logoSrc} alt="Logo" />
            </IconButton>
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '2rem',
                color: '#FFFFFF',
              }}
            >
              Lets Notify
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            <Box width="70%" pl={4} pt={2}>
              <Typography color="#FFFFFF" fontSize="1.8rem">
                {isLogin === 'login'
                  ? 'Make every message count with precise push notifications using  '
                  : 'Never miss a beat—engage your customers with timely push alerts by using  '}
                <span
                  style={{
                    fontSize: '1.8rem',
                    fontWeight: 'bold',
                    color: '#08FFD9',
                  }}
                >
                  Lets Notify
                </span>
              </Typography>
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start',
                backgroundColor: 'transparent',
              }}
            >
              <img
                src={isLogin === 'login' ? loginImage : signupImage}
                style={{ maxWidth: '60%' }}
                alt="Form Filling"
              />
            </Box>
          </Box>
        </Grid>
        <ForgotPwdModal
          open={openForgetPass}
          handleClose={() => setOpenForgetPass(false)}
          sendToConfirmPass={sendToConfirmPass}
        />
        <Grid item md={4.5} xs={12}>
          <Box
            height="100vh"
            width="100%"
            backgroundColor="#FFFFFF"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              padding: 3,
              boxSizing: 'border-box',
            }}
          >
            {isLogin === 'login' ? (
              <LoginContainer
                showSignUp={showSignUp}
                openForgetPass={showForgetPass}
              />
            ) : isLogin === 'signup' ? (
              <SignUpContainer showLogin={showLogin} />
            ) : (
              <ConfirmPasswordContainer navigateToLogin={showLogin} />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};