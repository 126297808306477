import { Box, IconButton, Stack, Typography } from '@mui/material'
import { InputField } from 'components/InputField/InputField'
import { commonIcons } from 'constants/appConstant'
import React from 'react';


const SubscriberDetails = () => {
  return (
    <Box>
        <Typography fontSize={'16px'} color={'#747272'}>Subscriber ID</Typography>
        <Stack direction={'row'} alignItems={'center'} spacing={2} my={2}>
            <Box sx={{
                border: '1px solid #ABABAB',
                borderRadius: '4px',
                paddingX: 2,
                height: "45px",
                display: 'flex',
                width: '60%',
                alignItems: 'center'
            }}>
                4ee7552d-610a-45d8-957a-331516f5b566
            </Box>
            <IconButton sx={{
                background: '#F1F2F7',
                borderRadius: '4px',
                paddingX: 2,
                height: "45px",
            }}>
                <img src={commonIcons.copyIcon} alt='' />
            </IconButton>
        </Stack>
        <Typography fontSize={'16px'} color={'#747272'}>Status</Typography>
        <Stack direction={'row'} alignItems={'center'} gap={2} mt={1}>
            <Box sx={{
                width: '1rem',
                height: '1rem',
                background: 'radial-gradient(53.12% 53.12% at 50% 50%, #4FABFF 0%, rgba(255, 255, 255, 0.4) 100%)'
            }} />
            <Typography color={'#4E4E4E'}>Active</Typography>
        </Stack>

        <Box my={2} width={'60%'}>
            <Typography color={'#747272'} mb={1}>Mobile </Typography>
            <InputField placeholder={'Ex. Oppo'} isReadable={true} />
            <Typography color={'#747272'} mb={1} mt={3}>Platform </Typography>
            <InputField placeholder={'Ex. Web'} isReadable={true}/>
            <Typography color={'#747272'} mb={1} mt={3}>Browser </Typography>
            <InputField placeholder={'Ex. Chrome'} isReadable={true}/>
            <Typography color={'#747272'} mb={1} mt={3}>Visitor URL </Typography>
            <InputField isReadable={true}/>
            <Typography color={'#747272'} mb={1} mt={3}>Created Time </Typography>
            <InputField isReadable={true}/>
            <Typography color={'#747272'} mb={1} mt={3}>Updated Time </Typography>
            <InputField isReadable={true}/>
        </Box>
    </Box>
  )
}

export default SubscriberDetails