import {
  Box,
  Stack,
  Typography
} from '@mui/material';
import { InputField } from 'components/InputField/InputField';
import ApexCharts from 'react-apexcharts';

const chartOptions = {
  chart: {
    type: 'donut',
  },
  labels: ['Button 1', 'Button 2'],
  colors: ['#FFD700', '#FF6347'],
};

const chartSeries = [53, 19.2];

const Analytics = () => {
  return (
    <Box sx={{ padding: '16px' }}>
      <Typography
        variant="h6"
        sx={{ fontWeight: 'bold', marginBottom: '16px' }}
      >
        Feeds
      </Typography>
      <ApexCharts
        options={chartOptions}
        series={chartSeries}
        type="donut"
        height={250}
      />

      <Typography variant="h6" sx={{ fontWeight: 500, marginTop: '24px' }}>
        Campaigns
      </Typography>
      <Box
        sx={{
          border: '1px solid #B9B9B9',
          padding: 2,
          borderRadius: '10px',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          spacing={3}
          my={2}
        >
          <InputField isReadable={true} />
          <InputField isReadable={true} />
          <InputField isReadable={true} />
        </Stack>

        <Box
          sx={{
            border: '1px solid #B9B9B9',
            padding: 2,
            borderRadius: '10px',
          }}
        >
          <Box
            sx={{
              background: '#DDF8F4',
              borderRadius: '10px',
              display: 'flex',
              height: '50px',
              alignItems: 'center',
            }}
          >
            <Typography width={'33.33%'} pl={2} color={'#747272'}>
              Campaigns Name
            </Typography>
            <Box sx={{ width: '1px', border: '1px solid', height: '30px' }} />
            <Typography width={'33.33%'} pl={2} color={'#747272'}>
              Status
            </Typography>
            <Box sx={{ width: '1px', border: '1px solid', height: '30px' }} />
            <Typography width={'33.33%'} pl={2} color={'#747272'}>
              Clicks
            </Typography>
          </Box>

          <Stack spacing={1} sx={{ my: 1 }}>
            <Box
              sx={{
                background: '#F1F2F7',
                borderRadius: '10px',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                alignItems: 'center',
                height: '50px',
              }}
            >
              <Typography pl={2} color={'#00519B'} className="underline">
                Test
              </Typography>
              <Box display={'flex'} alignItems={'center'}>
                <Box
                  sx={{
                    width: '1rem',
                    height: '1rem',
                    background:
                      'radial-gradient(53.12% 53.12% at 50% 50%, #4FABFF 0%, rgba(255, 255, 255, 0.4) 100%)',
                    marginRight: '8px',
                  }}
                />
                <Typography color={'#747272'}>Active</Typography>
              </Box>
              <Typography pr={2} color={'#00519B'} textAlign="center">
                Yes
              </Typography>
            </Box>

            <Box
              sx={{
                background: '#F1F2F7',
                borderRadius: '10px',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                alignItems: 'center',
                height: '50px',
              }}
            >
              <Typography pl={2} color={'#00519B'} className="underline">
                Test
              </Typography>
              <Box display={'flex'} alignItems={'center'}>
                <Box
                  sx={{
                    width: '1rem',
                    height: '1rem',
                    background:
                      'radial-gradient(53.12% 53.12% at 50% 50%, #FF5A4F 0%, rgba(255, 255, 255, 0.4) 100%)',
                    marginRight: '8px',
                  }}
                />
                <Typography color={'#747272'}>Inactive</Typography>
              </Box>
              <Typography pr={2} color={'#00519B'} textAlign="center">
                No
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>

      <Typography variant="h6" sx={{ fontWeight: 500, marginTop: '24px' }}>
        Subscribers
      </Typography>
      <Box
        sx={{
          border: '1px solid #B9B9B9',
          padding: 2,
          borderRadius: '10px',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          spacing={3}
          my={2}
        >
          <InputField isReadable={true} />
          <InputField isReadable={true} />
          <InputField isReadable={true} />
        </Stack>

        <Box
          sx={{
            border: '1px solid #B9B9B9',
            padding: 2,
            borderRadius: '10px',
          }}
        >
          <Box
            sx={{
              background: '#DDF8F4',
              borderRadius: '10px',
              display: 'flex',
              height: '50px',
              alignItems: 'center',
            }}
          >
            <Typography width={'33.33%'} pl={2} color={'#747272'}>
              Subscribers ID
            </Typography>
            <Box sx={{ width: '1px', border: '1px solid', height: '30px' }} />
            <Typography width={'33.33%'} pl={2} color={'#747272'}>
              Status
            </Typography>
            <Box sx={{ width: '1px', border: '1px solid', height: '30px' }} />
            <Typography width={'33.33%'} pl={2} color={'#747272'}>
              Mobile
            </Typography>
          </Box>

          <Stack spacing={1} sx={{ my: 1 }}>
            <Box
              sx={{
                background: '#F1F2F7',
                borderRadius: '10px',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                alignItems: 'center',
                height: '50px',
              }}
            >
              <Typography pl={2} color={'#00519B'} className="underline">
                4ee7552d-610a-45d8... 
              </Typography>
              <Box display={'flex'} alignItems={'center'}>
                <Box
                  sx={{
                    width: '1rem',
                    height: '1rem',
                    background:
                      'radial-gradient(53.12% 53.12% at 50% 50%, #4FABFF 0%, rgba(255, 255, 255, 0.4) 100%)',
                    marginRight: '8px',
                  }}
                />
                <Typography color={'#747272'}>Subscribed</Typography>
              </Box>
              <Typography pr={2} color={'#00519B'} textAlign="center">
                Oppo
              </Typography>
            </Box>

            <Box
              sx={{
                background: '#F1F2F7',
                borderRadius: '10px',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                alignItems: 'center',
                height: '50px',
              }}
            >
              <Typography pl={2} color={'#00519B'} className="underline">
                4ee7552d-610a-45d8... 
              </Typography>
              <Box display={'flex'} alignItems={'center'}>
                <Box
                  sx={{
                    width: '1rem',
                    height: '1rem',
                    background:
                      'radial-gradient(53.12% 53.12% at 50% 50%, #FF5A4F 0%, rgba(255, 255, 255, 0.4) 100%)',
                    marginRight: '8px',
                  }}
                />
                <Typography color={'#747272'}>Unsubscribed</Typography>
              </Box>
              <Typography pr={2} color={'#00519B'} textAlign="center">
                Oppo
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default Analytics;
