import React, { useEffect, useState } from 'react';
import { Box, Switch, Typography, TextField } from '@mui/material';
import { imageFileInput } from './Style';
import toast from 'react-hot-toast';
import { UpdateTemplate } from 'Api/Api';
import { Loading } from 'components/Loading';
import { SideDrawer } from 'components/SideDrawer';
import { InnerTextField } from 'components/InputFields';
import { formatDateTime } from 'utils/commonFunctions';

const EditTemplateModal = ({ open, onClose, itemId, isDetail, refresh }) => {
  const [templateDetails, setTemplateDetails] = useState(itemId || {});
  const [images, setImages] = useState({
    banner: '',
    icon: '',
  });
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [errors, setErrors] = useState({
    templateName: false,
    title: false,
    message: false,
    pageURL: false,
    icon: false,
    banner: false,
  });

  const handleEditChange = () => {
    setEdit(!edit);
  };

  const handleToggle = (e) => {
    const { name } = e.target;
    if (name === 'status') {
      const newStatus =
        templateDetails?.status === 'Active' ? 'Inactive' : 'Active';
      setTemplateDetails((prevState) => ({
        ...prevState,
        status: newStatus,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name !== 'banner' && name !== 'icon') {
      setTemplateDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === '' ? true : false,
      }));
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === 'banner' || name === 'icon') {
      const file = files[0];
      setTemplateDetails((prevState) => ({
        ...prevState,
        [name]: file,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: !file,
      }));
    }
  };

  const handleSubmit = async () => {
    const newErrors = {};
    if (templateDetails.templateName.trim() === '') {
      newErrors.templateName = true;
    }
    if (templateDetails.title.trim() === '') {
      newErrors.title = true;
    }
    if (templateDetails.message.trim() === '') {
      newErrors.message = true;
    }
    if (templateDetails.pageURL.trim() === '') {
      newErrors.pageURL = true;
    }
    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => !error)) {
      try {
        setLoading(true);
        let formData = new FormData();
        Object.entries(templateDetails).forEach(([key, value]) => {
          formData.append(key, value);
        });
        const response = await UpdateTemplate(templateDetails?.id, formData);
        if (response?.data?.status === true) {
          toast.success(response?.data?.message);
          refresh();
          onClose();
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    const { banner, icon, ...restItemId } = itemId || {};
    setTemplateDetails((prev) => ({ ...prev, ...restItemId }));

    if (banner && icon) {
      setImages(() => ({
        banner: banner,
        icon: icon,
      }));
    }
  }, [itemId]);

  return (
    <>
      <Loading state={loading} />
      <SideDrawer
        open={open}
        onClose={onClose}
        isDetail={isDetail}
        edit={edit}
        setEdit={handleEditChange}
        title={!edit && isDetail ? 'Template Details' : 'Edit Template'}
        handleSubmit={handleSubmit}
      >
        <Box>
          <InnerTextField
            required
            error={errors.templateName}
            name="templateName"
            label="Template Name"
            value={templateDetails?.templateName || ''}
            placeholder="Enter template name"
            onChange={handleChange}
            helperText={errors.templateName ? 'Field required' : ''}
            readOnly={!edit && isDetail}
          />
        </Box>
        <Box>
          <InnerTextField
            required
            error={errors.title}
            name="title"
            label="Title"
            value={templateDetails?.title || ''}
            onChange={handleChange}
            helperText={errors.title ? 'Field required' : ''}
            readOnly={!edit && isDetail}
          />
        </Box>
        <Box>
          <InnerTextField
            required
            error={errors.pageURL}
            name="pageURL"
            label="Redirecting URL"
            value={templateDetails?.pageURL || ''}
            placeholder="http://example.com"
            onChange={handleChange}
            helperText={errors.pageURL ? 'Field required' : ''}
            readOnly={!edit && isDetail}
          />
        </Box>
        <Box mb={2}>
          <InnerTextField
            required
            multiline
            error={errors.message}
            name="message"
            label="Message"
            rows={2}
            value={templateDetails?.message || ''}
            onChange={handleChange}
            helperText={errors.message ? 'Field required' : ''}
            readOnly={!edit && isDetail}
          />
        </Box>
        <Typography variant="subtitle3">Upload Notification Icon </Typography>
        <Box>
          <TextField
            type="file"
            name="icon"
            fullWidth
            margin="dense"
            onChange={handleFileChange}
            disabled={!edit && isDetail}
            sx={imageFileInput}
          />
          {images?.icon && (
            <img
              src={images.icon}
              alt="Notification Icon"
              width={200}
              height={500}
            />
          )}
        </Box>
        <br />
        <Typography variant="subtitle3">Upload Banner Image</Typography>
        <Box>
          <TextField
            type="file"
            name="banner"
            fullWidth
            required
            margin="dense"
            onChange={handleFileChange}
            disabled={!edit && isDetail}
            sx={imageFileInput}
          />
        </Box>
        {images.banner && (
          <img src={images?.banner} alt="Your Image" width={200} height={500} />
        )}
        <Box>
          <Box>
            <Typography variant="black_4">Status</Typography>
            <Switch
              name="status"
              size="large"
              checked={templateDetails?.status === 'Active'}
              onChange={handleToggle}
              readOnly={!edit && isDetail}
            />
          </Box>
        </Box>
        <Box>
          {isDetail && !edit ? (
            <Box>
              <Typography variant="subtitle2" my={1}>
                Created time : {formatDateTime(templateDetails?.createdTime)}
              </Typography>
              <Typography variant="subtitle2" my={1}>
                Modified time : {formatDateTime(templateDetails?.modifiedTime)}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </SideDrawer>
    </>
  );
};

export default EditTemplateModal;
