import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  Drawer,
} from '@mui/material';
import { MdDelete } from 'react-icons/md';
import { FaPlus, FaMinus } from 'react-icons/fa6';

const AddCondition = ({deletenode, data,close,saveData, open }) => {
  const [formState, setFormState] = useState({
    waitingTime:data.waitingTime,
    waitingTimeType:data.waitingTimeType,
    trigger:data.trigger,
  });

  const handleWaitingTimeChange = (increment) => {
    setFormState((prev) => ({
      ...prev,
      waitingTime: Math.min(60, Math.max(0, prev.waitingTime + increment)),
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const save = () => {
    saveData(formState);
  }
  return (
    <Drawer anchor={'right'} onClose={close} open={open}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 350,
          gap: 1,
          mt: 5,
          p: 1,
          px: 2,
        }}
      >
        <Typography variant="black_h4" sx={{ fontSize: 25, lineHeight: '90%' }}>
          Add Condition
        </Typography>
        <Typography variant="p">
          Conditions will help to sort interested subscribers from unengaged
          recipients
        </Typography>
        <Box sx={{ mt: 2 }}>
          <InputLabel sx={{ color: 'black' }}>Add Trigger</InputLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="trigger"
            value={formState.trigger}
            onChange={handleInputChange}
          >
            <FormControlLabel
              value="Opened"
              control={<Radio />}
              label="Opened"
            />
            <FormControlLabel value="button_clicked_1" control={<Radio />} label="Button Clicked 1" />
            <FormControlLabel value="button_clicked_2" control={<Radio />} label="Button Clicked 2" />
          </RadioGroup>
        </Box>
        <Box sx={{ mt: 2 }}>
          <InputLabel sx={{ color: 'black' }}>Waiting Time</InputLabel>
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              pl: 1,
            }}
          >
            <Button
              onClick={() => handleWaitingTimeChange(-1)}
              sx={{ p: 1, minWidth: 0, borderRadius: '50%' }}
              variant="contained"
            >
              <FaMinus />
            </Button>
            <Box
              sx={{ display: 'flex', justifyContent: 'center', width: 3 }}
            >
              {formState.waitingTime}
            </Box>
            <Button
              onClick={() => handleWaitingTimeChange(1)}
              sx={{ p: 1, minWidth: 0, borderRadius: '50%' }}
              variant="contained"
            >
              <FaPlus />
            </Button>
            <FormControl>
              <Select
                name="waitingTimeType"
                value={formState.waitingTimeType}
                onChange={handleInputChange}
                sx={{ color: 'black' }}

              >
                <MenuItem sx={{
              borderRadius: 2,
              mx: 1,}} value="Minutes">Minutes</MenuItem>
                <MenuItem sx={{
              borderRadius: 2,
              mx: 1,}}  value="Hours">Hours</MenuItem>
                <MenuItem sx={{
              borderRadius: 2,
              mx: 1,}}  value="Days">Days</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          borderTop: '1px solid gray',
          width: '100%',
          padding: 2,
          height: 60,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div className="flex gap-2">
          <Button
            type="submit"
            variant="contained"
            sx={{ bgcolor: '#033A32', color: 'white', fontSize: 13 }}
            onClick={save}
          >
            Save
          </Button>
          <Button
            variant="text"
            sx={{ border: '1px solid #033A32', color: '#033A32', fontSize: 13 }}
            onClick={close}
          >
            Cancel
          </Button>
        </div>
        <Button
          sx={{ padding: 0.2, minWidth: 0, pointerEvents: 'all' }}
          variant="text"
          onClick={deletenode}
        >
          <MdDelete color="red" size={25} />
        </Button>
      </Box>
    </Drawer>
  );
};

export default AddCondition;
