import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PersonIcon from '@mui/icons-material/Person';
import { useForm } from 'react-hook-form';
import {
  GenerateNewAccessKey,
  GetAccessKey,
  GetUserDetails,
  UpdateProfile,
} from 'Api/Api';
import toast from 'react-hot-toast';
import {
  avatar,
  deleteGrid,
  hoveredAvatar,
  mainContainer,
  profileContainer,
  profileGrid,
  securityGrid,
} from './style';
import { CustomFormInput } from 'components/Style';
import { ContainedButton, OutlinedButton } from 'components/Buttons';
import EmailLink from 'components/Email';
import { isEmailValid, MobileNumberValidation } from 'utils/commonFunctions';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ProfileScreen = () => {
  const [userDetails, setUserDetails] = useState({
    profileImageURL: '',
    username: '',
    email: '',
    mobileNumber: '',
    companyName: '',
    role: '',
    profileImage: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [accessKey, setAccessKey] = useState('');
  const [image, setImage] = useState(userDetails?.profileImageURL || '');
  const [hovered, setHovered] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    reset,
  } = useForm({
    defaultValues: userDetails,
    mode: 'onChange',
  });

  const fetch = async () => {
    try {
      setIsLoading(true);
      const response = await GetUserDetails();
      if (response?.data?.status === true) {
        setUserDetails(response?.data?.data);
        reset(response?.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const fetchAccessKey = async () => {
    try {
      const response = await GetAccessKey();
      if (response?.data?.status === true) {
        setAccessKey(response?.data?.accessKey);
      }
    } catch (error) {
      toast.success(error?.response?.data?.message);
    }
  };

  const generateAccessKey = async () => {
    try {
      const response = await GenerateNewAccessKey();
      if (response?.data?.status === true) {
        setAccessKey(response?.data?.accessKey);
        toast.success(response?.data?.message);
        fetchAccessKey();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUserDetails((prev) => ({ ...prev, profileImage: file }));
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const { profileImageURL, email, ...filteredData } = data;
      let formData = new FormData();
      Object.entries(filteredData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      if (userDetails.profileImage) {
        formData.append('profileImage', userDetails.profileImage);
      }
      const response = await UpdateProfile(formData);
      if (response?.data?.status === true) {
        toast.success(response?.data?.message);
        fetch();
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response?.data?.message);
    }
  };

  const deleteProfileImage = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const { profileImageURL, profileImage, ...submitData } = userDetails;
      const updatedData = {
        ...submitData,
        profileImage: null,
      };
      let formData = new FormData();
      Object.entries(updatedData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      const response = await UpdateProfile(formData);
      if (response?.data?.status === true) {
        toast.success('Profile image successfully deleted');
        setImage('');
        fetch();
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response?.data?.message);
    }
  };

  const navigateBack = () => {
    window.history.back();
  };

  useEffect(() => {
    fetch();
    fetchAccessKey();
  }, []);

  useEffect(() => {
    if (userDetails.profileImageURL) {
      setImage(userDetails.profileImageURL);
    }
  }, [userDetails.profileImageURL]);

  const handleCopy = () => {
    if (accessKey) {
      navigator.clipboard.writeText(accessKey).then(() => {
        toast.success('Access key copied to clipboard');
      });
    }
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (confirmed) => {
    if (confirmed) {
      generateAccessKey();
    }
    setOpenDialog(false);
  };
  return (
    <Box sx={mainContainer}>
      {/* <Loading state={isLoading} /> */}
      <Typography variant="dashboardTitle">My Accounts</Typography>
      <Box sx={profileContainer}>
        <Grid container sx={profileGrid}>
          <Typography variant="black_h3">Profile</Typography>
          <Grid container>
            <Grid
              item
              xs={12}
              md={3}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  className="avatar-wrapper"
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                >
                  <Avatar
                    alt="Profile Picture"
                    src={image}
                    className="profile-pic"
                    sx={avatar}
                  >
                    {(!image || hovered) && <PersonIcon fontSize="large" />}
                  </Avatar>
                  <input
                    type="file"
                    id="profileImage"
                    name="profileImage"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  {hovered && (
                    <label htmlFor="profileImage">
                      <IconButton
                        className="upload-button"
                        component="span"
                        sx={hoveredAvatar}
                      >
                        <CloudUploadIcon fontSize="large" />
                      </IconButton>
                    </label>
                  )}
                </div>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <IconButton
                    onClick={() => {}}
                    sx={{
                      border: '2px solid #BEBFC5',
                      borderRadius: 2,
                      p: 0.5,
                    }}
                  >
                    <DeleteIcon
                      sx={{ color: '#FF3800' }}
                      onClick={deleteProfileImage}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={9} mt={-1} mb={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack direction="column" spacing={0.5}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      Full Name
                    </Typography>
                    <CustomFormInput
                      name="username"
                      fullWidth
                      margin="normal"
                      focused
                      placeholder="Enter your Full name"
                      {...register('username', {
                        required: 'Field is Required',
                      })}
                    />
                    {errors.username && (
                      <Typography sx={{ color: '#FF0000' }}>
                        {errors.username.message}
                      </Typography>
                    )}
                  </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Stack direction="column" spacing={0.5}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      Email
                    </Typography>
                    <CustomFormInput
                      name="email"
                      type="email"
                      fullWidth
                      focused
                      margin="normal"
                      placeholder="Enter your email"
                      {...register('email', {
                        required: 'Field is Required',
                        validate: (value) =>
                          isEmailValid(value) || 'Email format is not valid',
                      })}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title={
                                <>
                                  If you would like to change the email address,
                                  please drop an email - <EmailLink />
                                </>
                              }
                              placement="bottom"
                            >
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors.email && (
                      <Typography sx={{ color: '#FF0000' }}>
                        {errors.email.message}
                      </Typography>
                    )}
                  </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Stack direction="column" spacing={0.5}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      Mobile Number
                    </Typography>
                    <CustomFormInput
                      name="mobileNumber"
                      type="text"
                      fullWidth
                      focused
                      margin="normal"
                      placeholder="Enter your mobile number"
                      {...register('mobileNumber', {
                        required: 'Field is Required',
                        validate: (value) =>
                          MobileNumberValidation(value) ||
                          'Mobile No. is not valid',
                      })}
                    />
                    {errors.mobileNumber && (
                      <Typography sx={{ color: '#FF0000' }}>
                        {errors.mobileNumber.message}
                      </Typography>
                    )}
                  </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Stack direction="column" spacing={0.5}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      Organisation Name
                    </Typography>
                    <CustomFormInput
                      name="companyName"
                      type="text"
                      fullWidth
                      focused
                      margin="normal"
                      placeholder="Enter your Organisation name"
                      {...register('companyName', {
                        required: 'Field is Required',
                      })}
                    />
                    {errors.companyName && (
                      <Typography sx={{ color: '#FF0000' }}>
                        {errors.companyName.message}
                      </Typography>
                    )}
                  </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Stack direction="column" spacing={0.5}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      Role
                    </Typography>
                    <CustomFormInput
                      name="role"
                      type="text"
                      fullWidth
                      focused
                      margin="normal"
                      placeholder="Enter your Role"
                      {...register('role', {
                        required: 'Field is Required',
                      })}
                    />
                    {errors.role && (
                      <Typography sx={{ color: '#FF0000' }}>
                        {errors.role.message}
                      </Typography>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container sx={securityGrid}>
          <Typography variant="black_h3">Security</Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Stack direction="column" spacing={0.5} flexGrow={1}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                Access Key
              </Typography>

              <CustomFormInput
                name="role"
                type="text"
                fullWidth
                focused
                margin="normal"
                placeholder="Enter your access key"
                value={accessKey || ''}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleCopy}>
                        <ContentCopyIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <Button
              sx={{
                padding: '0px 13px',
                backgroundColor: '#15B097',
                mt: 4,
                color: 'white',
                width: 'auto',
                minWidth: '218.5px',
                height: '52px',
                '&:hover': { backgroundColor: '#07826F' },
              }}
              size="large"
              onClick={handleClickOpen}
            >
              Generate new key
            </Button>

            <Dialog
              open={openDialog}
              onClose={() => handleClose(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {'Confirm Access Key Change'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  If you create a new access key, the old one will become
                  invalid and will need to be updated.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleClose(false)} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() => handleClose(true)}
                  color="primary"
                  autoFocus
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Grid>

        <Box sx={deleteGrid}>
          <Typography variant="black_h3">Delete account</Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="black_h5">
              Keep in mind that upon deleting your account all of your account
              information will be deleted without the possibility of
              restoration.
            </Typography>
            <Tooltip
              title={
                <>
                  We currently do not support account deletion. For assistance,
                  please contact us via email - <EmailLink />
                </>
              }
              placement="top-start"
              arrow
            >
              <Button
                variant="outlined"
                sx={{
                  border: '1px solid #FF3800 !important',
                  cursor: 'not-allowed',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: '150px',
                }}
              >
                <IconButton sx={{ cursor: 'not-allowed' }}>
                  <DeleteIcon fontSize="30" sx={{ color: '#FF3800' }} />
                </IconButton>
                <Typography sx={{ fontWeight: '500', color: '#FF3800' }}>
                  Delete Account
                </Typography>
              </Button>
            </Tooltip>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={4}
          mt={1}
        >
          <OutlinedButton
            title="Cancel"
            sx={{ width: '100px' }}
            onClick={navigateBack}
          />
          <ContainedButton
            title="Save"
            onClick={handleSubmit(onSubmit)}
            sx={{ width: '100px' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileScreen;
