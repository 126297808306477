import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import DateTimepicker from 'components/DateTimePicker';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateOptions } from '../../../redux/reducers/sequenceReducer';
import dayjs from 'dayjs';

const SequenceOption = () => {
  const dispatch = useDispatch();
  const currentDate = dayjs();
  const options = useSelector((state) => state.sequence.options);
  const [formState, setFormState] = useState({
    schedule: options.schedule || 'now',
    convertIntoAutomation: options.convertIntoAutomation || false,
    triggerCampaign: options.triggerCampaign || false,
    subscribersInteractionNeeded: options.subscribersInteractionNeeded || false,
    dateTime: options.dateTime || currentDate,
  });
  const navigate = useNavigate();
  let { id } = useParams();
  const handleCheckboxChange = (event) => {
    const { name } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      schedule: name,
    }));
  };

  const handleDateTimeChange = (dateTime) => {
    setFormState((prevState) => ({
      ...prevState,
      dateTime,
    }));
  };

  const handleOtherCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const PreviousPage = () => {
    if (id) {
      navigate('/sequence/' + id + '/sequence');
    } else {
      navigate('/sequence/create/sequence');
    }
  };
  const NextPage = () => {
    dispatch(updateOptions({ formState }));
    if (id) {
      navigate('/sequence/' + id + '/review');
    } else {
      navigate('/sequence/create/review');
    }
  };

  return (
    <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
      <Box sx={{ p: 5 }}>
        <Typography
          variant="black_h4"
          sx={{ fontWeight: 'bold', fontSize: 30 }}
        >
          Option
        </Typography>
        <Box sx={{ p: 2 }}>
          <Typography
            variant="black_h5"
            sx={{ fontWeight: 'bold', fontSize: 20 }}
          >
            Frequency
          </Typography>
          <FormGroup>
            <Box sx={{ display: 'flex' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.schedule === 'now'}
                    onChange={handleCheckboxChange}
                    name="now"
                  />
                }
                label="Schedule Now"
                sx={{
                  '.MuiFormControlLabel-label': {
                    fontSize: '1rem',
                    color: 'black',
                  },
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.schedule === 'later'}
                    onChange={handleCheckboxChange}
                    name="later"
                  />
                }
                label="Schedule Later"
                sx={{
                  '.MuiFormControlLabel-label': {
                    fontSize: '1rem',
                    color: 'black',
                  },
                }}
              />
              {formState.schedule === 'later' && (
                <DateTimepicker onChangeval={handleDateTimeChange} />
              )}
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formState.convertIntoAutomation}
                  onChange={handleOtherCheckboxChange}
                  name="convertIntoAutomation"
                />
              }
              label="Convert into Automation"
              sx={{
                '.MuiFormControlLabel-label': {
                  fontSize: '1rem',
                  color: 'black',
                },
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formState.triggerCampaign}
                  onChange={handleOtherCheckboxChange}
                  name="triggerCampaign"
                />
              }
              label="Trigger campaign based on the subscriber timezone"
              sx={{
                '.MuiFormControlLabel-label': {
                  fontSize: '1rem',
                  color: 'black',
                },
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formState.subscribersInteractionNeeded}
                  onChange={handleOtherCheckboxChange}
                  name="subscribersInteractionNeeded"
                />
              }
              label="Subscribers interaction needed"
              sx={{
                '.MuiFormControlLabel-label': {
                  fontSize: '1rem',
                  color: 'black',
                },
              }}
            />
          </FormGroup>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid gray',
          padding: 2,
          height: 60,
          display: 'flex',
          gap: 3,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          variant="text"
          sx={{ border: '1px solid #033A32', color: '#033A32', fontSize: 13 }}
          onClick={PreviousPage}
        >
          Back
        </Button>
        <Button
          type="submit"
          variant="contained"
          sx={{ bgcolor: '#033A32', color: 'white', fontSize: 13 }}
          onClick={NextPage}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default SequenceOption;
