import {
  Avatar,
  AvatarGroup,
  Box,
  Grow,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Aos from 'aos';
import Ava1Img from "assets/Images/Landing/Heading/user1.webp";
import Ava2Img from "assets/Images/Landing/Heading/user2.jpeg";
import Ava3Img from "assets/Images/Landing/Heading/user3.jpg";
import Img1 from 'assets/Images/Landing/Body/landing1.png';
import { forwardRef, useEffect, useRef } from 'react';
import { useLoadingContext } from 'react-router-loading';
import Typed from 'typed.js';
import { FilledBtn, GadiantShape, OutlinedBtn, shapes } from './CommonUi';

const Page1 = forwardRef(({ classname, currentSection }, ref) => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const loadingContext = useLoadingContext();
  setInterval(function () {
    loadingContext.done();
  }, 1000);

  const el = useRef(null);

  useEffect(() => {
    Aos.init();

    const typed = new Typed(el.current, {
      strings: [
        "<a>Drive Traffic!</a>",
        "<a>Engage!</a>",
        "<a>Monetize!</a>",
        "<a>Soar!</a>",
      ],
      typeSpeed: 50,
      loop: true,
      showCursor: false,
    });

    return () => {
      typed.destroy();
    };
  }, []);
  return (
    <Box
      className={classname}
      sx={{
        position: 'relative',
        paddingTop: { xs: '7.8vh', lg: '150px' },
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >

      {shapes.map((shape, index) => (
        <GadiantShape key={index} sx={shape} />
      ))}

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column-reverse', lg: 'row' },
          justifyContent: 'space-between',
          width: { xs: '90%', lg: '75%' },
          position: 'relative',
          top: { xs: '2rem', lg: 'auto' },
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', lg: '50%' },
            padding: { xs: '1vh', lg: '10px' }
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '1.3em', lg: '2.3em' },
              fontWeight: 600,
              color: 'rgba(255, 255, 255, 1)',
            }}
          >
            SuperCharge your <br />
            Website{' '}
            <span style={{ color: 'rgba(5, 255, 216, 1)' }}>Engagement.</span>
          </Typography>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: { xs: '1.3em', lg: '2.5em' },
              color: 'white'
            }}
          >
            <span ref={el} />
            <span> 🚀</span>
          </Typography>
          <Typography
            sx={{
              color: '#66FFE8',
              fontSize: { xs: '1em', lg: '1.5em' },
            }}
          >
            Seamless Web Push Notifications Software <br />
            Tailored for You!
          </Typography>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, lg: 3 }} my={2}>
            {/* <Link target='#' to=""> */}
            <OutlinedBtn onClick={() => { window.open("https://docs.google.com/forms/d/e/1FAIpQLScAAYJZEj2FsUTrJ8RHNtEUXbPPcLEA87bqV8l8XiPI3yZrkQ/viewform") }}>
              <Typography mr={2} color={'#fff'}>Get Early Access</Typography>
              <AvatarGroup>
                <Avatar
                  alt="Remy Sharp"
                  sx={{
                    width: 18,
                    height: 18,
                  }}
                  src={Ava1Img}
                />
                <Avatar alt="Travis Howard" sx={{ width: 18, height: 18 }} src={Ava2Img} />
                <Avatar alt="Cindy Baker" sx={{ width: 18, height: 18 }} src={Ava3Img} />
              </AvatarGroup>
            </OutlinedBtn>
            {/* </Link> */}
            <FilledBtn onClick={() => { window.open('mailto:support@letsnotify.co.in') }}>Get in touch</FilledBtn>
          </Stack>
        </Box>

        <Box
          sx={{
            width: { xs: '100%', sm: '80%', md: '70%', lg: '50%' },
            marginX: { xs: 'auto', },
            display: 'flex',
            justifyContent: { xs: 'center', lg: 'flex-end' },
            padding: '0px'
          }}
        >
          <Box
            sx={{
              width: { xs: '70%', sm: '70%', md: '70%', lg: '75%' },
            }}
          >
            <img
              src={Img1}
              style={{ objectFit: 'cover' }}
            />
          </Box>
        </Box>

      </Box>
      <Box width={'100%'}>
        <Grow in={true}>
          <Box
            sx={{
              position: 'relative',
              top: { xs: 50 },
              height: '10rem',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: { xs: 'end', lg: 'center' },

            }}
          >
            <Box
              sx={{
                width: { xs: "90%", lg: "60%" },
                height: { xs: "6rem", lg: '7rem' },
                background: 'rgba(10,10,10,.4)',
                border: '1px solid transparent',
                borderImageSource: 'linear-gradient(36.83deg, rgba(8, 255, 217, 0.05) 27.48%, rgba(8, 255, 217, 0.4) 43.79%, rgba(8, 255, 217, 0.1) 54.46%), linear-gradient(210.42deg, rgba(8, 255, 217, 0.12) 27.57%, rgba(8, 255, 217, 0.08) 39.97%, rgba(8, 255, 217, 0.016) 54.36%)',
                borderImageSlice: 1,
                borderRadius: '22px',
                boxShadow: '0px 0px 20.1px 0px #08FFD926',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 1,
                display: { xs: 'none', md: 'block' }  
              }}
            >
              <Typography textAlign={'center'} sx={{ fontSize: { xs: ".9em", lg: "1.3em" }, fontWeight: { xs: 500, lg: 600 } }} color={'#fff'}>
                “Understand your customer like never before”
              </Typography>

              <Typography
                textAlign={'center'}
                sx={{
                  fontSize: { xs: ".65em", lg: "1em" },
                 
                }}
                color={'#fff'}
              >
                Engage your audience with personalised push notifications that drive traffic and keep your brand top of your customer mind.
              </Typography>
            </Box>
          </Box>
        </Grow>
      </Box>
    </Box>
  );
});

export default Page1;
