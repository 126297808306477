import React, { useEffect, useState } from 'react';
import { Slide } from '@mui/material';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  DeleteConnection,
  FetchAllConnnection,
  UpdateConnection,
} from 'Api/Api';
import ListView from 'components/ListView';
import { DeleteModal } from 'components/Modals';
import SearchBar from 'components/SearchBar';
import { MainDashboard } from 'components/Style';
import { ConnectionTableColumns } from 'constants/appConstant';
import EditConnectModal from './EditConnectModal';
import { protectedRoutes } from 'constants/appRoutes';
import NoRecord from 'components/NoRecord';
import EnlargeCustomModal from './EnlargeCustomModal';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ConnectDashboard = () => {
  const [connectionData, setConnectionData] = useState([]);
  const [searchTerms, setSearchTerms] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editId, setEditId] = useState(0);
  const [deleteId, setDeleteId] = useState(0);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const [isDetail, setIsDetail] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tempItem, setTempItem] = useState({});
  const [openEnlarge, setEnlarge] = useState(false);


  const navigate = useNavigate();
  
  const handleCreateModal = (e) => {
    if (connectionData.length >= 1) {
      toast.error('You can not create more than one Connection');
    } else {
      navigate(protectedRoutes.createConnect);
    }
  };

  const helperDocs = () => {
    navigate(protectedRoutes.codeInjection);
  };

  const handleEnlargeModal = (item) => {
    setTempItem(item);
    setEnlarge(true);
  };

  const handleEditModel = (recordId, isDetail) => {
    setEditModalOpen(true);
    setEditId(recordId);
    setIsDetail(isDetail);
  };

  const handleDeleteModel = (recordId) => {
    setDeleteModalOpen(true);
    setDeleteId(recordId);
  };

  const onClose = () => {
    setEditModalOpen(false);
    setDeleteModalOpen(false);
  };

  const closeEnlargeModal = () => {
    setEnlarge(false);
  };

  async function fetch() {
    try {
      setInitialLoading(true);
      let response = await FetchAllConnnection();
      if (response?.data?.status === true) {
        var connectionId = response?.data?.data?.[0].id;
        localStorage.setItem('connectionId', connectionId);
        setConnectionData(response?.data?.data);
        setInitialLoading(false);
      }
    } catch (error) {
      setInitialLoading(false);
    }
  }

  async function deleteConnect(recordId) {
    try {
      const response = await DeleteConnection(recordId);
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        fetch();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  async function updateStatus(item) {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [item.id]: true,
    }));
    if (item?.status === 'Active') {
      const response = await UpdateConnection({
        id: item.id,
        name: item.name,
        status: item.status === 'Active' ? 'Inactive' : '',
      });
      if (response?.status === 200) {
        fetch();
      }
      setLoading((prevLoading) => ({
        ...prevLoading,
        [item.id]: false,
      }));
    }
  }

  useEffect(() => {
    fetch();
    onClose();
  }, []);
  
  useEffect(() => {

    let data = connectionData.find((item) => item.id === tempItem.id);
 
    if (data) {
       setTempItem(data);
    }
 }, [connectionData]);
 
  return (
    <MainDashboard>
      <SearchBar
        placeHolder="Connections"
        handleOpen={handleCreateModal}
        secondButton={true}
        customPrompt={true}
        createButton={connectionData.length > 0 ? false : true}
        helperDocs={() => helperDocs()}
      />
      <ListView
        tableData={connectionData}
        rowData={ConnectionTableColumns}
        updateStatus={updateStatus}
        handleEditModel={handleEditModel}
        initialLoading={initialLoading}
        loading={loading}
        handleDeleteModel={handleDeleteModel}
        icons={['edit', 'delete', 'detail']}
        sortingIndex={['name', 'createdTime', 'modifiedTime']}
        searchTerms={searchTerms}
        setSearchTerms={setSearchTerms}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        handleEnlargeModal={handleEnlargeModal}
        noRecordComponent={
          <NoRecord
            type="callback"
            moduleName="Connection"
            onAction={handleCreateModal}
          />
        }
      />

      <EnlargeCustomModal
        onCloseModal={closeEnlargeModal}
        open={openEnlarge}
        item={tempItem}
        refresh={fetch}
      />

      <EditConnectModal
        TransitionComponent={Transition}
        open={editModalOpen}
        onClose={onClose}
        itemId={editId}
        isDetail={isDetail}
        refresh={fetch}
      />
      <DeleteModal
        open={deleteModalOpen}
        close={onClose}
        placeholder="Connection"
        deleteFunction={() => deleteConnect(deleteId)}
      />
    </MainDashboard>
  );
};

export default ConnectDashboard;
