import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Drawer,
  IconButton,
  Stack,
  styled,
  Tab,
  Typography
} from '@mui/material';

export function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  );
}

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontSize: '18px',
  color: '#727274',
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 10,
  minHeight: '55px',
  width:'auto',
  minwidth: '21.33%',
  flexGrow : 1,
  whiteSpace:'nowrap',

  '&.Mui-selected': {
    color: '#07826F',
  },
}));

const EnlargeModal = ({ children, open, onCloseModal, title }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onCloseModal}
      PaperProps={{
        sx: {
          width: { xs: '100%', md: 550 },
          border: '1px solid #ABABAB',
        },
      }}
    >
      <Box p={3}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant="black_h3">{title}</Typography>
          <IconButton onClick={onCloseModal}>
            <CloseIcon />
          </IconButton>
        </Stack>

        {children}
      </Box>
    </Drawer>
  );
};

export default EnlargeModal;
