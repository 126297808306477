import { Box, Button, styled } from "@mui/material";

export const OutlinedBtn = styled(Button)(({theme}) => ({
    background:
    'rgb(62 62 62 / 25%) !important',
    backdropFilter: 'blur(8px)',
    border: '0.5px solid #fff',
    padding: "0.8rem 1rem",
    borderRadius: "0.5rem",
    textTransform: 'capitalize',
    color: "#FFFFFF",
}));


export const FilledBtn = styled(Button)(({theme}) => ({
    background: "rgba(3, 151, 128, 1)",
    color: "rgba(255, 255, 255, 1)",
    padding: "0.5rem 1rem",
    borderRadius: "0.5rem",
    textTransform: 'capitalize',
    border: '1px solid rgba(3, 151, 128, 1)',
    '&:hover':{
    filter: 'dropShadow(0 0 10px #fffdef)',
    borderRadius: '0.5rem',
    transition: '0.3s ease-in-out background'

  }
}));

export const GadiantShape = styled(Box)(({theme}) => ({
  background:
  '#08FFD999 !important',
  filter: 'blur(150px)',
  borderRadius: '50%',
  position:'absolute',
  height: '100%',
  width:'100%'
}));

export const shapes = [
  { height: '20%', width: '40%', top: '-10%', left: '10%' },
  { height: '50%', width: '50%', top: '80%', left: '-30%' },
  { height: '70%', width: '45%', top: '100%', right: '-30%' },
  { height: '30%', width: '30%', top: '180%', left: '40%' },
  { height: '60%', width: '40%', top: '230%', left: '-35%' },
  { height: '60%', width: '50%', top: '290%', right: '-35%' },
  { height: '60%', width: '40%', top: '380%', left: '-35%' },
  { height: '30%', width: '30%', top: '480%', left: '35%' },
  { height: '60%', width: '50%', top: '520%', right: '-35%' },
  { height: '60%', width: '50%', top: '620%', right: '-35%' },
  { height: '20%', width: '50%', top: '720%', right: '85%' },
  { height: '20%', width: '50%', top: '820%', right: '85%' },
  { height: '20%', width: '50%', top: '920%', right: '85%' },
];