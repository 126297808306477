import { Box, TextField, Typography } from '@mui/material';
import { SubmitButton } from './Style';
import { useState } from 'react';
import { CreateConnection, GenerateAPIKeys } from 'Api/Api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { TemplateStyles } from '../Templates/Style';
import { InnerTextField } from 'components/InputFields';
import { imageValidation } from 'utils/commonFunctions';

const CreateConnections = ({
  setConnectionName,
  setFlag,
  setApiKey,
  setSecretKey,
  setConnectionId,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [connectionDetails, setConnectionDetails] = useState({
    connectionName: '',
    connectionUrl: '',
    connectionImage: '',
  });

  const [errors, setErrors] = useState({
    connectionName: false,
    connectionUrl: false,
    connectionImage: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name !== 'connectionImage') {
      setConnectionDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === '' ? true : false,
      }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const isValid = imageValidation(file, setErrors); 
      if (isValid) {
        setConnectionDetails((prev) => ({
          ...prev,
          connectionImage: file,
        }));
      }
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        connectionImage: true,
      }));
    }
  };

  const createApiKeys = async (id) => {
    try {
      const response = await GenerateAPIKeys(id);
      if (response?.data?.status === true) {
        const newApiKey = response.data.data?.apiKey;
        const newSecretKey = response.data.data?.secretKey;
        toast.success(response.data.message);
        setApiKey(newApiKey);
        setSecretKey(newSecretKey);
      }
    } catch (error) {
      toast.error(error.response?.data?.message);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = {
      connectionName: connectionDetails.connectionName.trim() === '',
      connectionUrl: connectionDetails.connectionUrl.trim() === '',
      connectionImage: connectionDetails.connectionImage === '',
    };
    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) {
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      let formData = new FormData();
      Object.entries(connectionDetails).forEach(([key, value]) => {
        formData.append(key, value);
      });
      const response = await CreateConnection(formData);
      if (response?.data?.status === true) {
        var connectionId = response?.data?.data;
        localStorage.setItem('connectionId', connectionId);
        toast.success(response.data.message);
        setConnectionName(connectionDetails?.connectionName);
        setConnectionId(connectionId);
        setFlag(true);
        setLoading(false);
        createApiKeys(connectionId);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Box sx={{ width: '80%' }}>
      <Box>
        <InnerTextField
          required
          error={errors.connectionName}
          name="connectionName"
          label="Connection Name"
          value={connectionDetails?.connectionName}
          placeholder="Enter connection name"
          onChange={handleChange}
          helperText={errors.connectionName ? 'Field required' : ''}
        />
      </Box>
      <Box mb={1}>
        <InnerTextField
          required
          error={errors.connectionUrl}
          name="connectionUrl"
          label="Connection URL"
          value={connectionDetails?.connectionUrl}
          placeholder="https://www.example.com"
          onChange={handleChange}
          helperText={errors.connectionUrl ? 'Field required' : ''}
        />
      </Box>
      <Typography variant="subtitle3"> Upload Connection Icon </Typography>
      <Box>
        <TextField
          type="file"
          name="connectionImage"
          fullWidth
          margin="dense"
          error={errors.connectionImage}
          onChange={handleFileChange}
          helperText={errors.connectionImage ? 'Field required' : ''}
          sx={{
            '& .MuiOutlinedInput-root': TemplateStyles.InputBorder,
            '& input::file-selector-button': TemplateStyles.fileUploadButton,
            '& input::file-selector-button:hover': {
              backgroundColor: '#045e50',
            },
          }}
        />
      </Box>
      <SubmitButton
        sx={{ width: '25%', mt: 2 }}
        onClick={handleSubmit}
        variant="contained"
      >
        Submit
      </SubmitButton>
    </Box>
  );
};

export default CreateConnections;
