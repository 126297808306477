import styled from "@emotion/styled";
import { Switch } from "@mui/material";
import { tableScrollbar } from "components/Style";

export const InputStyleCustomPrompt = {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      padding: 1,
      "& fieldset": {
        padding: 1,
      },
      "& .MuiInputBase-input": {
        padding: "0 1px", // Add horizontal padding to keep the label centered properly
      },'&.Mui-disabled .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: 'black', // Set text color to black for disabled state
      },
    },
    "& .MuiInputLabel-root": {
      transform: "translate(14px, 12px) scale(1)", // Center the label when focused
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)", // Center the label when shrunk
    },
  }

  export const SwitchForCustomPrompt = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));


  export const PannelStyleCP = {
    ...tableScrollbar,
    height:'calc(100% - 120px)',
    overflowY:'scroll',
    px: 1.5,
    py: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    overflowY: 'scroll',
  }