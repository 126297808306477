import {
  Box,
  Icon,
  Tabs
} from '@mui/material';
import EnlargeModal, {
  CustomTab,
  CustomTabPanel,
  a11yProps,
} from 'components/EnlargeModal';
import { commonIcons } from 'constants/appConstant';
import React from 'react';
import CampaignDetails from './CampaignDetails';
import SegmentDetails from './SegmentDetails';
import SubscriberDetails from './SubscriberDetails';

const ViewSubscribersModal = ({ open, onCloseModal }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <EnlargeModal open={open} onCloseModal={onCloseModal} title={'Subscriber'}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ minHeight: '40px' }}
          >
            <CustomTab label="Subscriber detail " {...a11yProps(0)} />
            <CustomTab
              label="Segments"
              {...a11yProps(1)}
              icon={
                <Icon sx={{ marginTop: '5px' }}>
                  <img src={commonIcons.segmentIcon} alt="" />
                </Icon>
              }
              iconPosition="start"
            />
            <CustomTab
              label=" Campaigns"
              {...a11yProps(2)}
              icon={
                <Icon sx={{ marginTop: '5px' }}>
                  <img src={commonIcons.campaignIcon} alt="" />
                </Icon>
              }
              iconPosition="start"
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <SubscriberDetails />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <SegmentDetails />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <CampaignDetails />
        </CustomTabPanel>
      </Box>
    </EnlargeModal>
  );
};

export default ViewSubscribersModal;
