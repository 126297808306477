import React, { useEffect, useMemo, useState } from 'react';
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  addEdge,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import StartNode from './components/Nodes/startNode';
import AddNode from './components/Nodes/addNode';
import CustomNode from './components/Nodes/customNode';
import { useDispatch, useSelector } from 'react-redux';
import { CreateSequence, UpdateSequence } from 'Api/Api';
import toast from 'react-hot-toast';
import { resetState } from '../../../redux/reducers/sequenceReducer';

const SequenceReview = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { nodes: reduxNodes, edges: reduxEdges } = useSelector(
    (state) => state.sequence.sequence
  );
  const data = useSelector((state) => state.sequence);
  const [nodes, setNodes, onNodesChange] = useNodesState(reduxNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(reduxEdges);
  const onConnect = (params) => setEdges((eds) => addEdge(params, eds));
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const nodeTypes = useMemo(
    () => ({
      custom: (props) => <CustomNode {...props} edges={reduxEdges} />,
      startNode: (props) => <StartNode {...props} edges={reduxEdges} />,
      addNode: (props) => <AddNode {...props} edges={reduxEdges} />,
    }),
    []
  );

  const PreviousPage = () => {
    if (id) {
      navigate('/sequence/' + id + '/options');
    } else {
      navigate('/sequence/create/options');
    }
  };
  const SaveData = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('schedule', data.options.schedule);
      formData.append(
        'convertIntoAutomation',
        data.options.convertIntoAutomation
      );
      formData.append('triggerCampaign', data.options.triggerCampaign);
      formData.append(
        'subscribersInteractionNeeded',
        data.options.subscribersInteractionNeeded
      );
      formData.append('scheduledTriggeredTime', data.options.dateTime);

      formData.append('nodes', JSON.stringify(data.sequence.nodes));
      formData.append('edges', JSON.stringify(data.sequence.edges));

      formData.append('sequenceName', data.subscribers.sequenceName);
      formData.append('segmentId', data.subscribers.selectedSegment);
      let response;
      if (id) {
        response = await UpdateSequence(id, formData);
      } else {
        response = await CreateSequence(formData);
      }

      if (response.statusCode === 200 || response.status) {
        toast.success(response.data.message);
        setLoading(false);
        dispatch(resetState());
        navigate('/sequence');
      } else {
        setLoading(false);
        toast.error(response?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error.response?.data.message);
    }
  };

  return (
    <div
      style={{
        height: '100vh',
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ flex: 1 }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onConnect={onConnect}
          fitView
        >
          <Background />
          <Controls />
          <MiniMap />
        </ReactFlow>
      </div>
      <Typography
        variant="black_h4"
        sx={{
          position: 'absolute',
          top: 20,
          left: 10,
          fontWeight: 'bold',
          fontSize: 30,
        }}
      >
        Review
      </Typography>

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          padding: 2,
          height: 60,
          display: 'flex',
          gap: 3,
          zIndex: 10,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          variant="text"
          sx={{ border: '1px solid #033A32', color: '#033A32', fontSize: 13 }}
          onClick={PreviousPage}
        >
          Back
        </Button>
        <Button
          type="submit"
          variant="contained"
          sx={{ bgcolor: '#033A32', color: 'white', fontSize: 13 }}
          onClick={SaveData}
        >
          Save
        </Button>
      </Box>
    </div>
  );
};

export default SequenceReview;
