import React from 'react';

const EmailLink = ({ email = 'support@letsnotify.co.in', text, style }) => {
  return (
    <a
      href={`mailto:${email}`}
      style={{ textDecoration: 'underline', ...style }}
    >
      {email}
    </a>
  );
};

export default EmailLink;
