import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import Image from '../assets/Images/Common/NoRecordImage.png';
import PropTypes from 'prop-types';

const NoRecord = ({ moduleName, onAction }) => {
  const isSubscribers = moduleName === 'Subscriber';
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: 'auto',
        textAlign: 'center',
        backgroundColor: 'transparent',
      }}
    >
      <Box
        component="img"
        src={Image}
        alt="No record illustration"
        sx={{ maxWidth: '100%', height: 'auto', width: 200, mb: 2, pt: '10%' }}
      />

      <Typography variant="body1" sx={{ mb: 0.5, color: 'black' }}>
        No {moduleName} record found
      </Typography>
      {!isSubscribers && (
        <>
          <Typography
            variant="body2"
            sx={{ mb: 1, color: 'black', fontWeight: '500' }}
          >
            Please click the button below to create the new {moduleName}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddToPhotosIcon />}
            onClick={onAction}
          >
            Create
          </Button>
        </>
      )}
    </Box>
  );
};

NoRecord.propTypes = {
  moduleName: PropTypes.string.isRequired,
  onAction: PropTypes.func.isRequired,
};

export default NoRecord;
