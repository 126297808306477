import React, { useEffect, useState } from 'react';
import { Slide } from '@mui/material';
import {
  DeleteCampaign,
  FetchAllCampaign,
  FetchAllSegment,
  FetchAllTemplate,
  UpdateCampaign,
} from 'Api/Api';
import ListView from 'components/ListView';
import { DeleteModal } from 'components/Modals';
import SearchBar from 'components/SearchBar';
import { MainDashboard } from 'components/Style';
import { CampaignTableColumns } from 'constants/appConstant';
import CreateCampaignModal from './CreateCampaignModal';
import EditCampaignModal from './EditCampaignModal';
import toast from 'react-hot-toast';
import NoRecord from 'components/NoRecord';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Campaign = () => {
  const [campaignData, setCampaignData] = useState([]);
  const [templateDetails, setTemplateDetails] = useState([]);
  const [segmentDetails, setSegmentDetails] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [editId, setEditId] = useState();
  const [searchTerms, setSearchTerms] = useState({});
  const [isDetail, setIsDetail] = useState(false);
  const [loading, setLoading] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleCreateModal = () => {
    setCreateModalOpen(true);
  };
  const handleDeleteModel = (recordId) => {
    setDeleteModalOpen(true);
    setDeleteId(recordId);
  };
  const handleEditModel = (recordId, isDetail) => {
    setEditModalOpen(true);
    setEditId(recordId);
    setIsDetail(isDetail);
  };

  const onClose = () => {
    setCreateModalOpen(false);
    setEditModalOpen(false);
    setDeleteModalOpen(false);
  };

  const fetch = async () => {
    try {
      setInitialLoading(true);
      const response = await FetchAllCampaign();
      if (response?.data?.status === true) {
        setCampaignData(response?.data?.data || []);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setInitialLoading(false);
    }
  };

  const fetchTemplates = async () => {
    try {
      const response = await FetchAllTemplate();
      if (response?.data?.status === true) {
        setTemplateDetails(response?.data?.data || []);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const fetchSegments = async () => {
    try {
      const response = await FetchAllSegment();
      if (response?.data?.status === true) {
        setSegmentDetails(response?.data?.data || []);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const updateStatus = async (item) => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [item.id]: true,
    }));
    try {
      const response = await UpdateCampaign({
        id: item.id,
        status: item.status === 'Active' ? 'Inactive' : 'Active',
      });
      if (response?.data?.status === true) {
        await fetch();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading((prevLoading) => ({
        ...prevLoading,
        [item.id]: false,
      }));
    }
  };

  const deleteCampaign = async (recordId) => {
    try {
      const response = await DeleteCampaign(recordId);
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        fetch();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetch();
    fetchTemplates();
    fetchSegments();
    onClose();
  }, []);

  return (
    <MainDashboard>
      <SearchBar placeHolder="Campaigns" handleOpen={handleCreateModal} />
      <ListView
        tableData={campaignData}
        rowData={CampaignTableColumns}
        handleDeleteModel={handleDeleteModel}
        handleEditModel={handleEditModel}
        updateStatus={updateStatus}
        initialLoading={initialLoading}
        loading={loading}
        icons={['edit', 'delete', 'detail']}
        sortingIndex={['status', 'campaignName', 'createdTime', 'modifiedTime']}
        searchTerms={searchTerms}
        setSearchTerms={setSearchTerms}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        noRecordComponent={
          <NoRecord
            type="callback"
            moduleName="Campaign"
            onAction={handleCreateModal}
          />
        }
      />
      {templateDetails && (
        <CreateCampaignModal
          TransitionComponent={Transition}
          open={createModalOpen}
          onClose={onClose}
          template={templateDetails}
          segments={segmentDetails}
          refresh={fetch}
          FetchTemplate={fetchTemplates}
          FetchSegment={fetchSegments}
        />
      )}
      <EditCampaignModal
        TransitionComponent={Transition}
        open={editModalOpen}
        onClose={onClose}
        itemId={editId}
        isDetail={isDetail}
        refresh={fetch}
        templates={templateDetails}
        segments={segmentDetails}
        FetchTemplate={fetchTemplates}
        FetchSegment={fetchSegments}
      />
      <DeleteModal
        open={deleteModalOpen}
        close={onClose}
        placeholder="campaign"
        deleteFunction={() => deleteCampaign(deleteId)}
      />
    </MainDashboard>
  );
};

export default Campaign;
