import MenuIcon from '@mui/icons-material/Menu';
import { Button, Grid, Hidden } from '@mui/material';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { AppSidebar } from './AppSidebar';

const AppScreenLayout = () => {
  const [open, setOpen] = useState(false);

  const toggleSidebar = () => {
    setOpen(!open);
  };

  return (
    <Grid
      container
      sx={{
        height: '100vh',
        width: '100vw',
      }}
    >
      {/* Sidebar for mobile */}
      <Hidden mdUp>
        <Grid
          item
          xs={12}
          sx={{
            display: open ? 'block' : 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            zIndex: 999,
            backgroundColor: 'white',
          }}
        >
          <AppSidebar toggleSidebar={toggleSidebar} />
        </Grid>
      </Hidden>

      {/* Sidebar for desktop */}
      <Hidden mdDown>
        <Grid item md={2.5} lg={2}>
          <AppSidebar />
        </Grid>
      </Hidden>

      <Grid
        item
        xs={12}
        md={9.5}
        lg={10}
        sx={{
          backgroundColor: 'white',
        }}
      >
        <Button
          sx={{
            position: 'absolute',
            top: 0,
            right: 7,
            zIndex: 10,
            display: { sx: 'block', md: 'none' },
            // border:"2px solid green"
          }}
          onClick={toggleSidebar}
        >
          <MenuIcon />
        </Button>
        <Outlet />
      </Grid>
    </Grid>
  );
};
export default AppScreenLayout;
