import { Box, Card, LinearProgress, Typography } from '@mui/material'
import React from 'react'

const CountryCard = ({data}) => {
  return (
    <Card sx={{
        display: "flex",
        flexDirection: "column",
        py: 1,
        px:2,
        borderRadius: 2,
        height:441,
      }}>
      <Box
        sx={{
          display: "flex",
          width:'100%',
          alignItems: "end",
          justifyContent: "space-between",
          height:30,
          marginBottom:2
        }}
      >
   <Typography sx={{ fontSize:23,color: 'black' }}>
        Country
    </Typography>
        <Typography variant="black_h5" sx={{ color: "gray" }}>
        Users
    </Typography>
   </Box>
   {data&&data.map((data)=>(<Box sx={{
    width:`100%`,
    height:50,
    display: "flex",
    flexDirection:'column',
    justifyContent:'center',
    gap:.5,
    borderTop:'1px solid gray'

   }}>
    <Box sx={{display:'flex',justifyContent:'space-between'}}>
        <Typography>
           {data.country}
        </Typography>
        <Typography variant="p">
            {data.percent}
        </Typography>
    </Box>
   <LinearProgress variant="determinate" value={57} />
   </Box>))}

    </Card>
  )
}

export default CountryCard