import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Drawer,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import { MdDelete } from 'react-icons/md';
import { FetchAllTemplate } from 'Api/Api';

const AddNotification = ({deletenode, close, open, saveData }) => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        let response = await FetchAllTemplate();
        if (response?.data?.status === true) {
          setTemplates(response?.data?.data);
        }
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    };

    fetchTemplates();
  }, [open]);

  const handleSave = () => {
    if (!selectedTemplate) {
      setErrors(true);
    } else {
      setErrors(false);
      saveData({templateId:selectedTemplate});
    }
  };

  return (
    <Drawer anchor={'right'} onClose={close} open={open}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 350,
          gap: 1,
          mt: 5,
          p: 1,
          px: 3,
        }}
      >
        <Typography variant="h4" sx={{ fontSize: 25 }} gutterBottom>
          Add Template
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Autocomplete
            disablePortal
            id="template-box-demo"
            options={templates}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.templateName}
            value={selectedTemplate || null}
            onChange={(e, newValue) => {
              setSelectedTemplate(newValue);
            }}
            sx={{ width: '100%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Template"
                error={errors && !selectedTemplate}
                helperText={errors && !selectedTemplate && "Field required"}
              />
            )}
          />
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          borderTop: '1px solid gray',
          width: '100%',
          padding: 2,
          height: 60,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div className="flex gap-2">
          <Button
            type="button"
            variant="contained"
            sx={{ bgcolor: '#033A32', color: 'white', fontSize: 13 }}
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            variant="text"
            sx={{ border: '1px solid #033A32', color: '#033A32', fontSize: 13 }}
            onClick={close}
          >
            Cancel
          </Button>
        </div>
        <Button
          sx={{ padding: 0.2, minWidth: 0, pointerEvents: 'all' }}
          variant="text"
          onClick={deletenode}
        >
          <MdDelete color="red" size={25} />
        </Button>
      </Box>
    </Drawer>
  );
};

export default AddNotification;
