import {
  Box,
  Button,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { MdDelete } from 'react-icons/md';
import { FaPlus } from 'react-icons/fa6';
import { FaMinus } from 'react-icons/fa6';
import { useState } from 'react';

const AddDelay = ({deletenode, data, close, open, saveData }) => {

  const [waitingTime, setWaitingTime] = useState(data.waitingTime); 
  const [waitingTimeType, setWaitingTimeType] = useState(data.waitingTimeType);

  const handleWaitingTimeChange = (increment) => {
    setWaitingTime((prev) => Math.min(60, Math.max(0, prev + increment)));  };

  const handleWaitingTimeTypeChange = (event) => {
    setWaitingTimeType(event.target.value);
  };
 const save =()=>{
  saveData({waitingTime:waitingTime,waitingTimeType:waitingTimeType})
 }
  return (
    <Drawer anchor={'right'} onClose={close} open={open}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 350,
          gap: 1,
          mt: 5,
          p: 1,
          px:3
        }}
      >
        <Typography variant="black_h4" sx={{ fontSize: 25 }} gutterBottom>
          Add Delay
        </Typography>
        <Box sx={{ mt: 2 }}>
          <InputLabel sx={{ color: 'black' }}>Waiting Time</InputLabel>

          <Box
            sx={{ mt: 1,display:'flex',alignItems:'center',pl:1 }}
          >
            <Button  onClick={() => handleWaitingTimeChange(-1)} sx={{p:1,minWidth:0,borderRadius:'50%'}} variant="contained">
              <FaMinus />
            </Button>
            <Box sx={{display:'flex',justifyContent:'center',minWidth:35,}}>{waitingTime}</Box>
            <Button onClick={() => handleWaitingTimeChange(1)} sx={{p:1,minWidth:0,borderRadius:'50%'}} variant="contained">
              <FaPlus />
            </Button>
          </Box>
          <Box sx={{ mt: 3 }}>
          <InputLabel sx={{ color: 'black' }}>Waiting Time Type</InputLabel>
          <FormControl sx={{marginTop:1,p:0}} fullWidth>
            <Select name="waitingTimeType" value={waitingTimeType} onChange={handleWaitingTimeTypeChange}>
              <MenuItem value="Minutes">Minutes</MenuItem>
              <MenuItem value="Hours">Hours</MenuItem>
              <MenuItem value="Days">Days</MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          borderTop: '1px solid gray',
          width: '100%',
          padding: 2,
          height: 60,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
         <div className="flex gap-2">
         <Button
            type="submit"
            variant="contained"
            onClick={save}
            sx={{ bgcolor: '#033A32', color: 'white', fontSize: 13 }}
          >
            Save
          </Button>
          <Button
            variant="text"
            sx={{ border: '1px solid #033A32', color: '#033A32', fontSize: 13 }}
          onClick={close}
         >
            Cancel
          </Button>
         
        </div>
        <Button
          sx={{ padding: 0.2, minWidth: 0, pointerEvents: 'all' }}
          variant="text"
          onClick={deletenode}
        >
          <MdDelete color="red" size={25} />
        </Button>
       
      </Box>
    </Drawer>
  );
};

export default AddDelay;
