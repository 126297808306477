import { Box, IconButton, Stack, Typography } from '@mui/material'
import { commonIcons } from 'constants/appConstant'
import React from 'react'

const ConditionsDetails = () => {
  return (
    <Box> 
        <Box sx={{
        border: '1px solid #B9B9B9',
        padding: 2,
        borderRadius: '10px'
      }}>
        <Stack direction={'row'} justifyContent={'space-between' } alignItems={'center'} spacing={2}>
        <Box  sx={{
          background: '#DDF8F4',
          borderRadius: '10px',
          display: 'flex',
          height: '50px',
          alignItems: 'center',
          width: '100%',
        
        }}>
        <Typography pl={2} color={'#747272'}>
            Conditions
        </Typography>
        </Box>
        <IconButton>
            <img src={commonIcons.editIcon} alt='' width={20} />
        </IconButton>
        </Stack>

        <Typography color={'#282828'} my={2} fontSize={'15px'}>{`Subscriber has done or not done  Has  Clicked Template >> Testing Button of   Learn More  At Least  Over Time`}</Typography>
        <Typography color={'#282828'} my={2} fontSize={'15px'}>OR</Typography>
        <Typography color={'#282828'} my={2} fontSize={'15px'}>Subscriber Proprieties  <span style={{color: "#747272"}}>Subscriber ID Equal</span> </Typography>
        <Typography color={'#282828'} my={2} fontSize={'15px'}>4ee7552d-610a-45d8-957a-331516f5b566</Typography>
        </Box>
    </Box>
  )
}

export default ConditionsDetails