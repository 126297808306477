import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import { FetchAllSegment } from 'Api/Api';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  resetState,
  updateSubscribers,
} from '../../../redux/reducers/sequenceReducer';
import { InnerTextField } from 'components/InputFields';
import { CampaignStyle } from '../WebCampaigns/campaignStyle';

const SequenceSubscribers = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const subscriber = useSelector((state) => state.sequence.subscribers);
  const [details, setDetails] = useState({
    sequenceName: subscriber?.sequenceName || '',
    selectedSegment: subscriber?.selectedSegment || null,
  });
  const [segmentDetails, setSegmentDetails] = useState([]);
  const [segmentModalOpen, setSegmentModalOpen] = useState(false);
  const [error, setError] = useState({
    segment: false,
    sequenceName: false,
  });
  const navigate = useNavigate();

  async function getSegment() {
    try {
      const response = await FetchAllSegment();
      if (response?.data?.status === true) {
        setSegmentDetails(response?.data?.data);
      }
    } catch (error) {}
  }

  useEffect(() => {
    getSegment();
  }, []);
  const PreviousPage = () => {
    navigate('/sequence/');
  };
  const NextPage = () => {
    const handleError = () => {
      setError((prevError) => ({
        ...prevError,
        sequenceName: details.sequenceName === '',
      }));
      setError((prevError) => ({
        ...prevError,
        segment: details.selectedSegment == null,
      }));
    };
    const navigateToSequence = (path) => {
      navigate(path);
    };
    dispatch(updateSubscribers(details));
    if (details.sequenceName === '' || details.selectedSegment == null) {
      handleError();
      return;
    }
    if (id) {
      navigateToSequence(`/sequence/${id}/sequence`);
    } else {
      navigateToSequence('/sequence/create/sequence');
    }
  };

  return (
    <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
      <Box sx={{ p: 5, width: 350 }}>
        <Typography
          variant="black_h4"
          sx={{ fontWeight: 'bold', fontSize: 30 }}
        >
          Subscribers
        </Typography>
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <InnerTextField
            fullWidth
            id="outlined-basic"
            value={details?.sequenceName}
            label="Sequence name"
            onChange={(e) =>
              setDetails((prev) => ({ ...prev, sequenceName: e.target.value }))
            }
            variant="outlined"
            error={error.sequenceName}
            helperText={error.sequenceName && 'Field required'}
          />
          <Autocomplete
            disablePortal
            id="segment-combo-box"
            options={segmentDetails}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option?.segmentName}
            value={
              segmentDetails.find(
                (segment) => segment.id === details?.selectedSegment
              ) || null
            }
            onChange={(e, newValue) => {
              setDetails((prev) => ({
                ...prev,
                selectedSegment: newValue ? newValue.id : null,
              }));
              setError((prevState) => ({
                ...prevState,
                segment: newValue ? false : true,
              }));
            }}
            sx={CampaignStyle.autoSelectStyle}
            renderInput={(params) => (
              <TextField
                sx={{ color: 'black' }}
                {...params}
                label="Select Segment"
                error={error.segment}
                helperText={error.segment && 'Field required'}
                InputLabelProps={{
                  style: { color: 'black' },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { color: 'black' },
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} style={{ color: 'black' }}>
                {option.segmentName}
              </li>
            )}
          />
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid gray',
          padding: 2,
          height: 60,
          display: 'flex',
          gap: 3,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          variant="text"
          sx={{ border: '1px solid #033A32', color: '#033A32', fontSize: 13 }}
          onClick={PreviousPage}
        >
          Close
        </Button>
        <Button
          type="submit"
          variant="contained"
          sx={{ bgcolor: '#033A32', color: 'white', fontSize: 13 }}
          onClick={NextPage}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default SequenceSubscribers;
