import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { CustomCheckbox } from 'components/Style';

const CheckBox = ({ checked, onChange }) => {
  return (
    <CustomCheckbox
      checked={checked}
      onChange={onChange}
      icon={<CheckBoxOutlineBlankIcon />}
      checkedIcon={<CheckBoxIcon />}
    />
  );
};

export default CheckBox