import { Box, Button, Typography } from "@mui/material";
import { InnerTextField } from "components/InputFields";
import { useEffect, useState } from "react";
import { formatDateTime } from "utils/commonFunctions";
import EditIcon from '@mui/icons-material/Edit';
import EditConnectModal from "../EditConnectModal";
import { refresh } from "aos";


export const Detail = ({ open, onClose, item, refresh }) => {

    const [connectionDetails, setConnectionDetails] = useState({});
    const [image, setImage] = useState();
    const [openModal, setOpenModal] = useState(false);


    useEffect(() => {
        const { imageURL, ...restItemId } = item || {};
        setConnectionDetails(restItemId);
        if (imageURL) {
            setImage(imageURL);
        }
    }, [item]);

    const handleOpen = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography fontSize={'18px'} fontWeight={600} color={'black'}>
                    Connection Details
                </Typography>
                <Button onClick={handleOpen}>
                    <EditIcon />
                </Button>
            </Box>
            <EditConnectModal open={openModal} onClose={handleClose} itemId={item} refresh={refresh}/>
            <Box marginTop={1}>
                <InnerTextField
                    required
                    name="connectionName"
                    label="Connection Name"
                    value={connectionDetails?.connectionName || ''}
                    placeholder="Enter connection name"
                    readOnly
                />
            </Box>
            <br />
            <Box>
                <InnerTextField
                    required
                    name="connectionconnectionUrlName"
                    label="Connection URL"
                    value={connectionDetails?.connectionUrl || ''}
                    placeholder="Enter connection URL"
                    readOnly
                />
            </Box>
            <br />
            <Typography variant="subtitle3">Connection Image</Typography>
            <Box>
                {image && (
                    <img src={image} alt="Connection Image" width={200} height={500} />
                )}
            </Box>
            <br />
            <Box>
                <Typography variant="subtitle2" my={1}>
                    <strong>Created time : </strong>{formatDateTime(connectionDetails?.createdTime)}
                </Typography>
                <Typography variant="subtitle2" my={1}>
                    <strong> Modified time : </strong>{formatDateTime(connectionDetails?.modifiedTime)}
                </Typography>
            </Box>
        </>
    );
};
