import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  IconButton,
  Typography,
  Zoom,
} from '@mui/material';
import { ResetPassword } from 'Api/Api';
import { RoundedButton } from 'components/Buttons';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import theme from 'styles/app.theme';
import { isEmailValid } from 'utils/commonFunctions';
import { CustomFormInput } from './Style';

export const DeleteModal = ({
  open,
  close,
  deleteFunction,
  TransitionComponent = Zoom,
}) => {
  const dialogStyle = {
    borderRadius: '10px',
    width: '25%',
    height: '23%',
    paddingY: '20px',
    position: 'absolute',
    top: '10%',
    left: '50%',
    overflowY: 'hidden',
    transform: 'translate(-50%, 0)',
    filter: `
      drop-shadow(0px 5px 10px rgba(117, 117, 117, 0.10))
      drop-shadow(0px 19px 19px rgba(117, 117, 117, 0.09))
      drop-shadow(0px 42px 25px rgba(117, 117, 117, 0.05))
      drop-shadow(0px 75px 30px rgba(117, 117, 117, 0.01))
      drop-shadow(0px 117px 33px rgba(117, 117, 117, 0.00))
    `,
  };

  const closeButtonStyle = {
    position: 'absolute',
    right: 8,
    top: 8,
    color: 'black',
    fontSize: 'bold',
    border: '2px solid redd',
  };

  const actionButtonStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '110%',
    height: '40px',
    borderRadius: '6px',
    padding: '12px 16px',
    fontWeight: 'bold',
    textTransform: 'none',
  };

  const handleDialogClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      close();
    }
  };

  return (
    <Dialog
      onClose={handleDialogClose}
      open={open}
      TransitionComponent={TransitionComponent}
      PaperProps={{ sx: dialogStyle }}
    >
      <Box sx={{ marginX: '5%', border: '2px solid redd' }}>
        <Typography
          textAlign={'center'}
          variant="h5"
          sx={{ mt: 0, border: '2px solid reddd' }}
        >
          Delete Record?
        </Typography>
        <IconButton aria-label="close" onClick={close} sx={closeButtonStyle}>
          <CloseIcon />
        </IconButton>

        <Typography
          my={0.5}
          color={'#0D0D0D'}
          fontWeight={400}
          textAlign={'center'}
        >
          Sure you want to delete the record?
        </Typography>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              deleteFunction();
              close();
            }}
            sx={{
              ...actionButtonStyles,
              backgroundColor: '#E10B0B',
              color: '#fff',
            }}
          >
            Delete
          </Button>
          <Button
            // mt={0.5}
            variant="outlined"
            onClick={close}
            sx={{
              ...actionButtonStyles,
              backgroundColor: '#fff',
              color: '#7F7F7F',
              border: '1px solid #000',
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export const ForgotPwdModal = ({ open, handleClose, sendToConfirmPass }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const [loading, setLoading] = useState(false);

  const handleDialogClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
    }
  };

  const handleForgetPassword = async (data) => {
    try {
      setLoading(true);
      const response = await ResetPassword(data?.email);
      setLoading(false);
      toast(
        'Email sent! You will receive the email to reset your password if your email address is valid and registered in our system. Please check your inbox (and spam folder, just in case!) shortly for instructions on how to reset your password using the link provided.'
      );
      sendToConfirmPass();
    } catch (error) {
      setLoading(false);
      toast.error('Server is not responding');
    }
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} maxWidth="xs" fullWidth>
      <Box
        sx={{
          padding: 4,
          borderRadius: 5,
          textAlign: 'center',
          fontFamily: 'inherit',
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
          Forgot Password?
        </Typography>
        <Box
          sx={{ position: 'absolute', right: 10, top: 10, fontWeight: 'bold' }}
        >
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography
          variant="subtitle2"
          textAlign="center"
          sx={{ fontWeight: '500', mb: 2 }}
        >
          Enter the email associated with your account.
        </Typography>
        <form>
          <CustomFormInput
            sx={{ mb: 1 }}
            placeholder="example@gmail.com"
            {...register('email', {
              required: 'Field required*',
              validate: {
                validEmail: (value) =>
                  isEmailValid(value) || 'Email format is not valid',
              },
            })}
          />
          {errors.email && (
            <Typography sx={theme.typography.errorSandyBrown}>
              {errors?.email?.message}
            </Typography>
          )}
          <Box textAlign={'center'} mt={1}>
            <RoundedButton
              title="Submit"
              onClick={handleSubmit(handleForgetPassword)}
            />
          </Box>
        </form>
      </Box>
    </Dialog>
  );
};
