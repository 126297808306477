import React, { useEffect, useState } from 'react';
import NoRecord from 'components/NoRecord';
import CreateCustompromptPage from '../customPrompt/CreateCustomPrompt';
import { FetchAllCustomPrompt } from 'Api/Api';
import EditIcon from '@mui/icons-material/Edit';
import toast from 'react-hot-toast';
import {
  Box,
  Button,
  CircularProgress,
  Switch,
  Typography,
} from '@mui/material';
import { InnerTextField } from 'components/InputFields';
import { Time } from '../customPrompt/constant';

export const CustomPrompt = ({ item }) => {
  const [openModal, setOpenModal] = useState(false);
  const [customPrompt, setCustomPrompt] = useState();
  const [flag, setFlag] = useState(false);
  const [loading, setLoading] = useState();
  const [error, setError] = useState(null);

  const handleCreateModal = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  async function fetch() {
    setLoading(true);
    try {
      let response = await FetchAllCustomPrompt();
      if (response?.data?.status === true) {
        setCustomPrompt(response?.data?.data);
        setFlag(true);
      } else {
        setFlag(false);
      }
    } catch (error) {
      setLoading(false);
      setError('Failed to fetch welcome template.');
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetch();
  }, []);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <>
      {flag ? (
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1.5}
          >
            <Typography fontSize={'18px'} fontWeight={600} color={'black'}>
              Custom Prompt Details
            </Typography>
            <Button>
              <EditIcon />
            </Button>
          </Box>
          <Box width={'95%'}>
            <Box>
              <InnerTextField
                required
                margin="dense"
                name="title"
                label="Title"
                value={customPrompt?.data?.title}
              />
            </Box>

            <Box>
              <InnerTextField
                required
                name="description"
                label="Description"
                value={customPrompt?.data?.description}
              />
            </Box>

            <Box marginY={1}>
              <Typography variant="black_4">Status</Typography>
              <Switch
                name="status"
                size="large"
                checked={customPrompt?.status}
              />
            </Box>
            <Typography variant="subtitle3">Upload Logo</Typography>
            <Box>
              {customPrompt?.iconImageURL && (
                <img
                  src={customPrompt?.iconImageURL}
                  alt="Notification Icon"
                  width={200}
                  height={500}
                />
              )}
            </Box>
            <br />
            <Box my={1}>
              <Typography sx={{ fontSize: '18px' }} variant="black_p">
                Allow Button
              </Typography>
            </Box>

            <Box>
              <InnerTextField
                required
                name="allowButtonText"
                label="Text"
                value={customPrompt?.data?.styles?.allowButtonText}
              />
            </Box>

            <Box>
              <InnerTextField
                required
                name="allowButtonBackgroundColor"
                label="Background Color"
                colorSelection
                value={customPrompt?.data?.styles?.allowButtonBackgroundColor}
              />
            </Box>

            <Box>
              <InnerTextField
                required
                name="allowButtonTextColor"
                label="Text Color"
                colorSelection
                value={customPrompt?.data?.styles?.allowButtonTextColor}
              />
            </Box>

            <Box>
              <InnerTextField
                required
                margin="dense"
                label="Later Button Text"
                name="laterButtonText"
                value={customPrompt?.data?.styles?.laterButtonText}
              />
            </Box>
            <Box mt={1}>
              <Typography
                sx={{ fontSize: '16px', marginBottom: '20px' }}
                variant="subtitle3"
              >
                Opt-in Timings
              </Typography>
            </Box>

            <Box>
              <InnerTextField
                label="Mobile (In Sec)"
                name="mobileTiming"
                margin="normal"
                fullWidth
                value={customPrompt?.data?.styles?.mobileTiming}
              />
            </Box>

            <Box>
              <InnerTextField
                label="Desktop (In Sec)"
                name="desktopTiming"
                margin="dense"
                fullWidth
                options={Time}
                value={customPrompt?.data?.styles?.desktopTiming}
              />
            </Box>

            <Box>
              <InnerTextField
                required
                name="hidePromptFrequency"
                label="Hide Prompt Frequency"
                value={customPrompt?.data?.styles?.hidePromptFrequency}
              />
            </Box>

            <Box>
              <InnerTextField
                required
                name="showPromptFrequency"
                margin="dense"
                label="Show Prompt Frequency"
                value={customPrompt?.data?.styles?.showPromptFrequency}
              />
            </Box>
            <Box mt={1}>
              <Typography
                sx={{ fontSize: '16px', marginBottom: '10px' }}
                variant="subtitle3"
              >
                Position
              </Typography>
            </Box>

            <Box>
              <InnerTextField
                label="Desktop"
                name="desktopPosition"
                margin="normal"
                fullWidth
                value={customPrompt?.data?.styles?.desktopPosition}
              />
            </Box>

            <Box>
              <InnerTextField
                label="Mobile"
                name="mobilePosition"
                margin="normal"
                fullWidth
                value={customPrompt?.data?.styles?.mobilePosition}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <NoRecord
            type="callback"
            moduleName="Custom Prompt"
            onAction={handleCreateModal}
          />
          <CreateCustompromptPage
            onClose={handleClose}
            open={openModal}
            item={item}
          />
        </>
      )}
    </>
  );
};
