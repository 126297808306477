import { Box, Tabs } from '@mui/material';
import EnlargeModal, {
  a11yProps,
  CustomTab,
  CustomTabPanel,
} from 'components/EnlargeModal';
import { useState } from 'react';
import { Welcome } from './Enlarge/Welcome';
import { CustomPrompt } from './Enlarge/CustomPrompt';
import { Detail } from './Enlarge/Detail';
import Activity from './Enlarge/Activity';

const EnlargeCustomModal = ({ open, onCloseModal, item, refresh }) => {
  const [value, setValue] = useState(0);
  const [shouldFetchWelcome, setShouldFetchWelcome] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 2) {
      setShouldFetchWelcome(true);
    }
  };

  return (
    <EnlargeModal open={open} onCloseModal={onCloseModal} title={'Connections'}>
      <Box width={'100%'}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ minHeight: '40px' }}
          >
            <CustomTab
              label="Details"
              {...a11yProps(0)}
              sx={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}
            />
            <CustomTab
              label="Custom Prompt"
              {...a11yProps(1)}
              sx={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}
            />
            <CustomTab
              label="Welcome Template"
              {...a11yProps(2)}
              sx={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}
            />
            <CustomTab
              label="Activity"
              {...a11yProps(3)}
              sx={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Detail item={item} refresh={refresh} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <CustomPrompt item={item} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Welcome
            shouldFetchWelcome={shouldFetchWelcome}
            item={item}
            refresh={refresh}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Activity item={item} />
        </CustomTabPanel>
      </Box>
    </EnlargeModal>
  );
};

export default EnlargeCustomModal;