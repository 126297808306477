import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

export default function DateTimepicker({ label, onChangeval }) {
  const currentDate = dayjs();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
        <DateTimePicker
          label={label}
          defaultValue={currentDate}
          minDate={currentDate}
          onChange={(newDate) => {
            if (newDate.isAfter(currentDate)) {
              const dateString = newDate.format('YYYY-MM-DD HH:mm:ss');
              onChangeval(dateString);
            } else {
              console.log(
                'Selected date must be after the current date and time.'
              );
            }
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
