import { Slide } from '@mui/material';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { DeleteTemplate, FetchAllTemplate, UpdateTemplate } from 'Api/Api';
import ListView from 'components/ListView';
import { DeleteModal } from 'components/Modals';
import SearchBar from 'components/SearchBar';
import { MainDashboard } from 'components/Style';
import { TemplateTableColumns } from 'constants/appConstant';
import CreateTemplateModal from './CreateTemplateModal';
import EditTemplateModal from './EditTemplateModal';
import NoRecord from 'components/NoRecord';
import EnlargeTemplateModal from './EnlargeTemplateModal';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Template = () => {
  const [templateData, setTemplateData] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [editId, setEditId] = useState();
  const [searchTerms, setSearchTerms] = useState({ action: 'search' });
  const [isDetail, setIsDetail] = useState(false);
  const [loading, setLoading] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openEnlarge, setEnlarge] = useState(false);
  const [tempItem, setTempItem] = useState({});

  const handleCreateModal = () => {
    setCreateModalOpen(true);
  };

  const handleDeleteModel = (recordId) => {
    setDeleteModalOpen(true);
    setDeleteId(recordId);
  };

  const handleEditModel = (recordId, isDetail) => {
    setEditModalOpen(true);
    setEditId(recordId);
    setIsDetail(isDetail);
  };

  const onClose = () => {
    setCreateModalOpen(false);
    setEditModalOpen(false);
    setDeleteModalOpen(false);
  };


  async function fetch() {
    try {
      setInitialLoading(true);
      let response = await FetchAllTemplate();
      if (response?.data?.status === true) {
        setTemplateData(response?.data?.data);
        setInitialLoading(false);
      }
    } catch (error) {
      setInitialLoading(false);
    }
  }

  async function deleteTemplates(recordId) {
    try {
      const response = await DeleteTemplate(recordId);
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        fetch();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  async function updateStatus(item) {
    if (!item) {
      return;
    }
    setLoading((prevLoading) => ({
      ...prevLoading,
      [item.id]: true,
    }));

    let data = {
      id: item.id,
      title: item.title,
      pageURL: item.pageURL,
      message: item.message,
      templateName: item.templateName,
      status: item.status === 'Active' ? 'Inactive' : 'Active',
    };
    const response = await UpdateTemplate(item?.id, data);
    if (response?.data?.status === true) {
      fetch();
    }
    setLoading((prevLoading) => ({
      ...prevLoading,
      [item.id]: false,
    }));
  }

  const handleEnlargeModal = (item) => {
    setTempItem(item);
    setEnlarge(true);
  };

  const closeEnlargeModal = () => {
    setEnlarge(false);
  };

  useEffect(() => {
    fetch();
    onClose();
  }, []);

  useEffect(() => {
    let data = templateData.find((item) => item.id === tempItem.id);

    if (data) {
      setTempItem(data);
    }
  }, [templateData]);

  return (
    <MainDashboard>
      <SearchBar placeHolder="Templates" handleOpen={handleCreateModal} />
      <ListView
        tableData={templateData}
        rowData={TemplateTableColumns}
        handleDeleteModel={handleDeleteModel}
        handleEditModel={handleEditModel}
        updateStatus={updateStatus}
        initialLoading={initialLoading}
        loading={loading}
        sortingIndex={['status', 'templateName', 'createdTime', 'modifiedTime']}
        searchTerms={searchTerms}
        setSearchTerms={setSearchTerms}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        handleEnlargeModal={handleEnlargeModal}
        noRecordComponent={
          <NoRecord
            type="callback"
            moduleName="Template"
            onAction={handleCreateModal}
          />
        }
      />
      <EnlargeTemplateModal
        onCloseModal={closeEnlargeModal}
        open={openEnlarge}
        item={tempItem}
        refresh={fetch}
      />
      <CreateTemplateModal
        TransitionComponent={Transition}
        open={createModalOpen}
        onClose={onClose}
        refresh={fetch}
        welcome={false}
      />
      <EditTemplateModal
        TransitionComponent={Transition}
        open={editModalOpen}
        onClose={onClose}
        itemId={editId}
        isDetail={isDetail}
        refresh={fetch}
      />
      <DeleteModal
        open={deleteModalOpen}
        close={onClose}
        placeholder="Template"
        deleteFunction={() => deleteTemplates(deleteId)}
      />
    </MainDashboard>
  );
};

export default Template;
