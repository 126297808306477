import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { protectedRoutes } from 'constants/appRoutes';
import logoSrc from 'assets/Images/Common/logo.png';
import { SideMenuItems, ProfileMenuItems } from '.././constants/appConstant';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import theme from 'styles/app.theme';
import { clearAuthDetails } from '../redux/reducers/authReducer';
import searchIcon from '../assets/Icons/SideBar/search.png';
import { GetUserDetails } from 'Api/Api';
import toast from 'react-hot-toast';

const Drawer = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  boxShadow: '0px 0px 4px gray',
  padding: 1,
}));

const activeItemStyle = {
  backgroundColor: '#07826F',
  borderRadius: '4px',
  padding: '2px 10px',
};

const normalItemStyle = {
  padding: '2px 10px',
};

const RenderListItem = ({
  eachItem,
  index,
  openIndex,
  handleClick,
  isActive,
}) => {
  const IconComponent =
    isActive(eachItem.link) ||
      eachItem.child?.some((child) => isActive(child.link))
      ? eachItem.icon.active
      : eachItem.icon.inactive;

  return (
    <React.Fragment key={index}>
      <ListItem disablePadding>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <ListItemButton
            selected={
              isActive(eachItem.link) ||
              eachItem.child?.some((child) => isActive(child.link))
            }
            onClick={() => handleClick(index)}
            sx={
              isActive(eachItem.link)
                ? {
                  height: '40px',
                  marginTop: 0.5,
                  borderRadius: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#B2D8D2',
                  backgroundColor: '#07826F !important',
                }
                : {
                  height: '40px',
                  marginTop: 0.5,
                  borderRadius: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#B2D8D2',
                }
            }
          >
            <ListItemIcon
              sx={{
                color: isActive(eachItem.link)
                  ? theme.palette.primary.main
                  : theme.palette.grey,
              }}
            >
              <IconComponent />
            </ListItemIcon>
            <ListItemText
              primary={eachItem.name}
              primaryTypographyProps={{
                color:
                  isActive(eachItem.link) ||
                    eachItem.child?.some((child) => isActive(child.link))
                    ? '#fff'
                    : '#B2D8D2',
                fontSize: { xs: '0.875rem', sm: '1rem', md: '0.95rem' },
              }}
              sx={{ opacity: 1, marginX: -3 }}
            />
            {eachItem.child && eachItem.child.length > 0 && (
              <React.Fragment>
                {openIndex === index ? (
                  <ExpandLess
                    sx={{
                      color:
                        isActive(eachItem.link) ||
                          eachItem.child?.some((child) => isActive(child.link))
                          ? '#fff'
                          : '#C0C0C0',
                    }}
                  />
                ) : (
                  <ExpandMore
                    sx={{
                      color:
                        isActive(eachItem.link) ||
                          eachItem.child?.some((child) => isActive(child.link))
                          ? '#fff'
                          : '#C0C0C0',
                    }}
                  />
                )}
              </React.Fragment>
            )}
          </ListItemButton>
          {isActive(eachItem?.link?.toLowerCase()) && (
            <Box
              sx={{
                borderRight: '3.5px solid #49FFE3',
                width: '4px',
                borderRadius: 1,
                marginLeft: 0.5,
                marginTop: 0.8,
                marginX: 0.5,
              }}
            />
          )}
        </div>
      </ListItem>
    </React.Fragment>
  );
};

export const AppSidebar = ({ toggleSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const [userDetails, setUserDetails] = useState({});
  const [openIndex, setOpenIndex] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState(SideMenuItems);
  const [anchorEl, setAnchorEl] = useState(null);

  const name = userDetails?.username || 'Unknown';
  const email = userDetails?.email;
  const firstLetter = name.charAt(0);
  const profileImageUrl = userDetails?.profileImageURL;

  const handlePopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  const handleClick = (index) => {
    const path = SideMenuItems[index]?.link;
    if (path) {
      handleNavigate(path);
      if (toggleSidebar) toggleSidebar();
      setOpenIndex(null);
    } else {
      setOpenIndex(openIndex === index ? null : index);
    }
  };

  const fetch = async () => {
    try {
      const response = await GetUserDetails();
      if (response?.data?.status === true) {
        setUserDetails(response?.data?.data);
      }
    } catch (error) {
      toast.error(error?.response?.data.message);
    }
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleNavigate = (path) => {
    navigate(path);
    clearSearch();
    if (toggleSidebar) toggleSidebar();
  };

  const handleLogout = () => {
    localStorage.clear('token');
    dispatch(clearAuthDetails({}));
    navigate('/login');
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    filterItems(e.target.value);
  };

  const clearSearch = () => {
    setSearchQuery('');
    setFilteredItems(SideMenuItems);
    setSearchOpen(false);
  };

  const filterItems = (query) => {
    if (!query) {
      setFilteredItems(SideMenuItems);
      return;
    }

    const lowerQuery = query.toLowerCase();
    const filtered = [];

    SideMenuItems.forEach((item) => {
      const filteredChildren = item?.child?.filter((child) =>
        child?.name?.toLowerCase().startsWith(lowerQuery)
      );

      if (filteredChildren?.length) {
        filteredChildren.forEach((child) => {
          filtered.push({
            ...child,
            parentName: item.name,
            icon: child.icon,
          });
        });
      } else if (item.name.toLowerCase().startsWith(lowerQuery)) {
        filtered.push({
          ...item,
          parentName: null,
          icon: item.icon,
        });
      }
    });

    setFilteredItems(filtered);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Drawer
      sx={{ position: 'relative', height: '100%', backgroundColor: '#033A32' }}
    >
      <Button
        sx={{
          display: { xs: 'block', md: 'none' },
          position: 'absolute',
          top: 27,
          right: 0,
          zIndex: 10,
          cursor: 'pointer',
          color: 'whitesmoke',
        }}
        onClick={toggleSidebar}
      >
        <CloseIcon sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }} />
      </Button>

      <Button
        sx={{
          display: 'block',
          position: 'absolute',
          top: { xs: 22, sm: 22, md: 18, lg: 16 },
          right: { xs: 40, sm: 50, md: -10 },
          zIndex: 10,
          cursor: 'pointer',
          color: 'whitesmoke',
        }}
      >
        <IconButton>
          <img
            width={25}
            height={25}
            src={searchIcon}
            onClick={() => setSearchOpen(!searchOpen)}
          />
        </IconButton>
      </Button>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={3}
        px={1}
      >
        <Stack direction={'row'} spacing={2}>
          <img src={logoSrc} width={30} height={30} alt="Logo" />
          <Typography
            color={'black'}
            variant="h6"
            noWrap
            component="div"
            sx={{
              color: '#fff',
              fontSize: { xs: '1.5rem', md: '1.25rem' },
            }}
          >
            Lets Notify
          </Typography>
        </Stack>
      </Box>

      {searchOpen && (
        <Paper
          component="form"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '85%',
            backgroundColor: '#f0f2f5',
            marginX: 'auto',
            marginY: '10px',
            borderRadius: 1,
          }}
        >
          <InputBase
            sx={{ marginLeft: 2, flex: 1, color: '#100C08' }}
            placeholder="Search..."
            inputProps={{ 'aria-label': 'search' }}
            autoFocus
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <IconButton
            type="button"
            sx={{ padding: '10px', color: '#100C08' }}
            aria-label="clear"
            onClick={clearSearch}
          >
            <Close />
          </IconButton>
        </Paper>
      )}

      <List>
        {!searchQuery ? (
          SideMenuItems?.map((eachItem, index) => {
            return (
              <React.Fragment key={index}>
                <RenderListItem
                  key={index}
                  eachItem={eachItem}
                  index={index}
                  openIndex={openIndex}
                  handleClick={handleClick}
                  isActive={isActive}
                />
                {eachItem.child && eachItem.child.length > 0 && (
                  <Collapse
                    in={openIndex === index}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Box
                        sx={{
                          width: '30px',
                          borderRight: 1,
                          marginRight: 1.5,
                          marginLeft: 0.5,
                          borderColor: '#E6F3F166',
                        }}
                      />
                      <List
                        component="div"
                        disablePadding
                        sx={{ width: '100%', mr: 3 }}
                      >
                        {eachItem.child.map((childItem, childIndex) => {
                          const SubIconComponent = isActive(childItem.link)
                            ? childItem.icon.active
                            : childItem.icon.inactive;
                          return (
                            <div
                              key={childIndex}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <ListItemButton
                                onClick={() => handleNavigate(childItem.link)}
                                sx={
                                  isActive(childItem.link)
                                    ? activeItemStyle
                                    : normalItemStyle
                                }
                              >
                                <ListItemIcon>
                                  <SubIconComponent sx={{ fontSize: 16 }} />
                                </ListItemIcon>
                                <ListItemText
                                  primaryTypographyProps={{
                                    fontSize: {
                                      xs: '0.85rem',
                                      sm: '0.9rem',
                                      md: '0.88rem',
                                    },
                                    color: isActive(childItem.link)
                                      ? '#fff'
                                      : '#B2D8D2',
                                    marginLeft: -3,
                                  }}
                                  primary={childItem.name}
                                />
                              </ListItemButton>
                              {childItem.comingSoon && (
                                <Box
                                  sx={{
                                    height: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'flex-end',
                                    backgroundColor: '#E6F3F1',
                                    borderRadius: '5px',
                                    padding: '2px 3px',
                                    marginTop: '8px',
                                    marginLeft: '-7px',
                                    fontSize: '8px',
                                    fontWeight: '600',
                                    color: '#066859',
                                  }}
                                >
                                  coming soon
                                </Box>
                              )}
                              {isActive(childItem.link.toLowerCase()) && (
                                <Box
                                  sx={{
                                    border: '2px solid #49FFE3',
                                    width: '2px',
                                    marginLeft: 0.5,
                                    borderRadius: 1,
                                  }}
                                />
                              )}
                            </div>
                          );
                        })}
                      </List>
                    </Box>
                  </Collapse>
                )}
              </React.Fragment>
            );
          })
        ) : (
          <>
            {filteredItems.map((eachItem, index) => {
              const IconComponent =
                isActive(eachItem.link) ||
                  eachItem.child?.some((child) => isActive(child.link))
                  ? eachItem.icon.active
                  : eachItem.icon.inactive;
              return (
                <React.Fragment key={index}>
                  <ListItem disablePadding>
                    <ListItemButton
                      selected={isActive(eachItem.link)}
                      sx={{
                        minHeight: 30,
                        marginTop: 0.5,
                        borderRadius: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#B2D8D2',
                      }}
                      onClick={() => handleNavigate(eachItem.link)}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <IconComponent />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          eachItem.parentName
                            ? `${eachItem.name} (${eachItem.parentName})`
                            : eachItem.name
                        }
                        primaryTypographyProps={{
                          color: isActive(eachItem.link) ? '#fff' : '#B2D8D2',
                          fontSize: {
                            xs: '0.875rem',
                            sm: '1rem',
                            md: '0.95rem',
                          },
                        }}
                        sx={{ opacity: 1, marginX: 1 }}
                      />
                    </ListItemButton>
                  </ListItem>
                </React.Fragment>
              );
            })}
          </>
        )}
      </List>

      <Box
        sx={{
          position: 'absolute',
          bottom: 10,
          left: 0,
          right: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          gap: 1,
          paddingX: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: {
              xs: '50px',
              sm: '60px',
              md: '60px',
              lg: '60px',
              xl: '65px',
            },
            height: {
              xs: '50px',
              sm: '60px',
              md: '60px',
              lg: '60px',
              xl: '65px',
            },
            borderRadius: '1rem',
            overflow: 'hidden',
          }}
        >
          {profileImageUrl ? (
            <Avatar
              src={profileImageUrl}
              alt="Profile Image"
              sx={{
                width: 60,
                height: 60,
              }}
            />
          ) : (
            <Avatar
              sx={{
                backgroundColor: '#058270',
                color: 'white',
                width: 50,
                height: 50,
              }}
            >
              {firstLetter}
            </Avatar>
          )}
        </Box>
        <Box sx={{ flexGrow: 1, minWidth: 0 }}>
          <Stack direction={'column'} ml={0}>
            <Typography
              sx={{
                fontSize: {
                  xs: '1rem',
                  sm: '1rem',
                  md: '1.05rem',
                  lg: '0.9rem',
                },
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: '#fff',
              }}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: '0.80rem',
                  sm: '0.85rem',
                  md: '0.87rem',
                  lg: '0.75rem',
                },
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: '#fff',
              }}
            >
              {email}
            </Typography>
          </Stack>
        </Box>
        <Box>
          <MoreVertIcon
            style={{ cursor: 'pointer', marginRight: '-0.5rem' }}
            onClick={handlePopover}
            fontSize="medium"
            sx={{ color: '#ABABAB' }}
          />
        </Box>
      </Box>

      <Popover
        id={id}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            width: { lg: '20%', md: '25%', sm: '35%', xs: '75%' },
            border: `1px solid ${theme.palette.primary.grey}`,
            backgroundColor: theme.palette.background.paper,
            marginLeft: { lg: '0.5%', md: '1.5%', sm: '0%', xs: '0%' },
          },
        }}
      >
        <Box sx={{ padding: 0, margin: 0 }}>
          <List
            sx={{
              borderRadius: 1,
              padding: 0,
              margin: 0,
              border: '1px solid #e0e0e0',
            }}
          >
            {ProfileMenuItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem sx={{ padding: 0, margin: 0, color: 'black' }}>
                  <ListItemButton
              
                  onClick={() => {
  console.log("Clicked link:", item.link);

  if (item.link === protectedRoutes.logout) {
    handleLogout();
    handleClose();
  } else {
   
    if (item.link.startsWith('mailto:')) {
    
      window.location.href = item.link; 
    } else if (item.link.startsWith('http://') || item.link.startsWith('https://')) {
    
      window.open(item.link, '_blank');
    } else {
     
      navigate(item.link);
    }
    handleClose();
  }
}}
                    sx={{
                      ...theme.listItems?.buttonStyle,
                      borderRadius: 0,
                      padding: 1,
                      '&:hover': {
                        backgroundColor: '#f0f0f0',
                      },
                    }}
                    selected={isActive(item.link)}
                  >
                    <ListItemIcon sx={{ minWidth: '40px', color: 'inherit' }}>
                      <item.icon />
                    </ListItemIcon>
                    <ListItemText
                      primary={item.name}
                      primaryTypographyProps={{ style: { color: '#000000' } }}
                    />
                  </ListItemButton>
                </ListItem>
                {index !== ProfileMenuItems.length - 1 && (
                  <Divider sx={{ margin: 0 }} />
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Popover>
    </Drawer>
  );
};
