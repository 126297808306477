import { Box, Grid, Stack, Typography } from '@mui/material';
import SideImg from 'assets/Images/Landing/Body/sequence.png';
import SparkIcon from 'assets/Images/Landing/sparking.svg';

const Page3 = ({ classname }) => {
  return (
    <Box
      className={classname}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Grid
        container
        sx={{
          justifyContent: 'space-between',
          width: { xs: '90%', lg: '75%' },
          position: 'relative',
          top: { xs: '2rem', lg: 'auto' },
        }}
        spacing={2}
        direction={{ xs: 'column-reverse', lg: 'row' }}
      >
        <Grid item xs={12} lg={6}>
          <Box
            sx={{
              width: { xs: '100%', sm: '60%', md: '50%', lg: '100%' },
              display: 'flex',
              justifyContent: { xs: 'center', lg: 'flex-start' },
            }}
          >
            <div className="card example-2">
              <div className="inner">
                {' '}
                <img
                  src={SideImg}
                  width={'100%'}
                  height={'100%'}
                  style={{ objectFit: 'cover' }}
                />
              </div>
            </div>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '1.4em', lg: '1.9em' },
              fontWeight: 600,
              color: 'rgba(102, 255, 232, 1)',
              marginY: 2,
              textTransform: 'uppercase',
            }}
          >
            Sequence
          </Typography>

          <Typography
            sx={{
              fontSize: { xs: '1.2em', lg: '1.7em' },
              fontWeight: 500,
              color: 'rgba(255, 255, 255, 1)',
            }}
          >
            Keep the conversations going with<br></br>
            <span style={{ color: 'rgba(102, 255, 232, 1)' }}>
              Sequential Push Notification !
            </span>
          </Typography>

          {[
            'Maximise user engagement and retention with strategically timed push notification sequences',
            'Tailor customer journeys based on their interactions to send the right Push notification to the right audience at the perfect time.',
          ].map((item,ind) => (
            <Stack key={ind} direction={'column'} spacing={2} my={1}>
              <Stack direction={'row'} spacing={2} alignItems={'start'}>
                <img src={SparkIcon} alt="" style={{ marginTop: '10px' }} />
                <Typography
                  sx={{
                    color: 'rgba(255, 255, 255, 1)',
                    fontSize: { xs: '0.8em', lg: '1em' },
                    width: { xs: '100%', lg: '70%' },
                  }}
                >
                  {item}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Page3;
