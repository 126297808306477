import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';

const fetchActivityLogs = (itemId, updatedTitle) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const now = new Date().toISOString();
      const creationDate = new Date(); 
      creationDate.setDate(creationDate.getDate() - 30); 
      resolve([
        {
          id: 1,
          action: 'The Connection Title was Created',
          description: `The item "${itemId?.connectionName}" was created.`,
          timestamp: creationDate.toISOString(),
        },
        {
          id: 2,
          action: 'Updated',
          description: `The title was updated to "${updatedTitle || 'N/A'}" and the image was updated.`,
          timestamp: now, 
        },
      ]);
    }, 1000);
  });
};


const ActivityLogDetails = ({ logs, sx }) => { 
  const currentMonthYear = new Date().toLocaleString('default', { month: 'long', year: 'numeric' });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', position: 'relative', ...sx }}> 
  
      <Box
        sx={{
          position: 'absolute',
          left: '8px', 
          top: -16,
          bottom: 0,
          width: '1.5px',
          backgroundColor: 'lightgrey', 
          zIndex: 1,
        }}
      />

   
      <Box sx={{ paddingLeft: '32px', flexGrow: 1, position: 'relative' }}>
        {logs.length > 0 ? (
          logs.map((log) => (
            <Box
              key={log.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
                padding: '16px',
                borderRadius: '8px',
                marginBottom: '16px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                position: 'relative',
                zIndex: 2,
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'black' }}>
                  {log.action}
                </Typography>
                <Typography variant="caption" sx={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>
                  {new Date(log.timestamp).toLocaleString()}
                </Typography>
              </Box>
             
              <Typography variant="body2" sx={{ marginTop: '8px' }}>
                {log.description}
              </Typography>
            </Box>
          ))
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'white',
              padding: '16px',
              borderRadius: '8px',
              marginBottom: '16px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
              position: 'relative',
              zIndex: 2,
            }}
          >
            <Typography variant="h6">{currentMonthYear}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};


const Activity = ({ item, updatedTitle }) => {
  const [activityLogs, setActivityLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadActivityLogs = async () => {
    setLoading(true);
    try {
      const logs = await fetchActivityLogs(item, updatedTitle);
      setActivityLogs(logs);
      setError(null);
    } catch (err) {
      setError('Failed to fetch activity logs');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (item?.id) {
      loadActivityLogs();
    }
  }, [item, updatedTitle]); 

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  const currentMonthYear = new Date().toLocaleString('default', { month: 'long', year: 'numeric' });

  return (
    <Box>
      <Typography variant="h6" sx={{ marginBottom: '25px', marginLeft: '-13px', color: 'grey',marginTop:'16px',fontSize:'20px' }} gutterBottom>
        {currentMonthYear}
      </Typography>
    
      <ActivityLogDetails logs={activityLogs} sx={{ marginTop: '20px' }} />
    </Box>
  );
};

export default Activity;
