import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from '@mui/material';
import { FetchAllActionTracker } from 'Api/Api';

const fetchActivityLogs = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve([
        {
          id: 1,
          title: 'Created Template',
          description: 'Template created successfully.',
          timestamp: new Date().toISOString(),
        },
        {
          id: 2,
          title: 'Updated Template',
          description: 'Template updated with new details.',
          timestamp: new Date().toISOString(),
        },
      ]);
    }, 1000);
  });
};

const ActivityLogDetails = ({ logs }) => (
  <Box sx={{ padding: 2 }}>
    <Typography variant="h6" gutterBottom>
      Activity Logs
    </Typography>
    <List>
      {logs?.length > 0 ? (
        logs?.map((log) => (
          <ListItem key={log.id}>
            <ListItemText
              primary={log.action}
              secondary={`${log.description} | ${new Date(
                log.timestamp
              ).toLocaleString()}`}
            />
          </ListItem>
        ))
      ) : (
        <Typography>No activity logs found.</Typography>
      )}
    </List>
  </Box>
);

const ActivityTemplate = ({ item }) => {
  const [activityLogs, setActivityLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadActivityLogs = async () => {
    setLoading(true);
    const data = {
      recordId: item?.id,
      moduleName: 'Template',
    };
    try {
      const response = await FetchAllActionTracker(data);
      console.log("res", response)
      if (response?.data?.status === true) {
        setActivityLogs(response?.data?.[0]);
        setError(null);
      }
    } catch (error) {
      console.error('Failed to fetch activity logs:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadActivityLogs();
  }, []);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Template Activity Logs
      </Typography>
      <ActivityLogDetails logs={activityLogs} />
    </Box>
  );
};

export default ActivityTemplate;
