import { CustomButton, Outlined, RoundButton } from 'components/Style';

export const RoundedButton = ({ type, title, style, sx, onClick }) => {
  return (
    <RoundButton type={type} style={style} sx={sx} onClick={onClick}>
      {title}
    </RoundButton>
  );
};

export const ContainedButton = ({ title, onClick, variant, sx, startIcon }) => {
  return (
    <CustomButton
      onClick={onClick}
      variant={variant || 'contained'}
      sx={sx}
      startIcon={startIcon}
    >
      {title}
    </CustomButton>
  );
};

export const OutlinedButton = ({ title, onClick, variant, sx, startIcon }) => {
  return (
    <Outlined
      onClick={onClick}
      variant={variant || 'outlined'}
      sx={sx}
      startIcon={startIcon}
    >
      {title}
    </Outlined>
  );
};
