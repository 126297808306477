import React, { useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, CircularProgress, Button, TextField } from '@mui/material';
import { FetchAllActionTracker, CreateActionTracker, FetchAllSegment } from '../../../Api/Api';


const ActivitySegment = () => {
  const [activityLogs, setActivityLogs] = useState([]);
  const [segments, setSegments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newAction, setNewAction] = useState({ title: '', description: '' });


  const loadActivityLogs = async () => {
    setLoading(true);
    try {
      const response = await FetchAllActionTracker();
      if (response?.data) {
        setActivityLogs(response.data);
      } else {
        setError('No data found');
      }
    } catch (err) {
      setError('Failed to load activity logs: ' + (err.response?.data?.message || err.message));
    } finally {
      setLoading(false);
    }
  };


  const loadSegments = async () => {
    try {
      const response = await FetchAllSegment();
      if (response?.data) {
        setSegments(response.data);
      } else {
        setError('No segments found');
      }
    } catch (err) {
      console.error('Failed to load segments data', err);
      setError('Failed to load segments data: ' + (err.response?.data?.message || err.message));
    }
  };


  useEffect(() => {
    loadActivityLogs();
    loadSegments();
  }, []);

  const handleActionChange = (e) => {
    setNewAction({ ...newAction, [e.target.name]: e.target.value });
  };


  const handlePostAction = async () => {
    const action = {
      title: newAction.title || 'Untitled Action',
      description: newAction.description || 'No description provided',
      date: new Date().toLocaleDateString(),
      time: new Date().toLocaleTimeString(),
    };

    try {
      await CreateActionTracker(action);
      await loadActivityLogs(); 
      setNewAction({ title: '', description: '' });
    } catch (error) {
      console.error('Failed to post new action:', error);
      setError('Failed to log the action: ' + (error.response?.data?.message || error.message));
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        Activity Logs and Segment Modifications
      </Typography>

      <TextField
        label="Action Title"
        variant="outlined"
        name="title"
        value={newAction.title}
        onChange={handleActionChange}
        sx={{ marginBottom: 1 }}
      />
      <TextField
        label="Action Description"
        variant="outlined"
        name="description"
        value={newAction.description}
        onChange={handleActionChange}
        sx={{ marginBottom: 1 }}
      />
      <Button variant="contained" onClick={handlePostAction} sx={{ marginBottom: 2 }}>
        Log Action
      </Button>

      <Typography variant="h6" gutterBottom>
        Activity Logs
      </Typography>
      <List>
        {activityLogs.length > 0 ? (
          activityLogs.map((log) => (
            <ListItem key={log.id}>
              <ListItemText
                primary={log.title}
                secondary={`${log.description} | ${log.date} at ${log.time}`}
              />
            </ListItem>
          ))
        ) : (
          <Typography>No activity logs found.</Typography>
        )}
      </List>

      <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
        Segment Modifications
      </Typography>
      <List>
        {segments.length > 0 ? (
          segments.map((segment) => (
            <ListItem key={segment.id}>
              <ListItemText
                primary={segment.name}
                secondary={`Last updated: ${new Date(segment.updatedAt).toLocaleString()}`}
              />
            </ListItem>
          ))
        ) : (
          <Typography>No segment modifications found.</Typography>
        )}
      </List>
    </Box>
  );
};

export default ActivitySegment;
