import styled from '@emotion/styled';
import { FormHelperText, Input, TextField } from '@mui/material';

const ariaLabel = { 'aria-label': 'description' };

export const CustomInput = styled(Input)(({ theme }) => ({
  color: 'black',
  width: '100%',
  height: "45px",
  borderRadius: '6px',
  border: '1px solid #ABABAB',
  '&::before, &::after': {
    borderBottom: 'none !important',
  },
  '& .MuiInputBase-input': {
    padding: '0.75rem',
  },
  '& .MuiInput-underline:before': {
    borderBottom: 'none !important',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottom: 'none !important',
  },
  '& .MuiInput-underline:after': {
    borderBottom: 'none !important',
  },
}));

export const InputField = ({
  placeholder,
  style,
  onChange,
  name,
  type = 'text',
  error,
  helperText,
  margin = 'normal',
  multiline = false,
  value,
  isReadable
}) => {
  return (
    <>
      <CustomInput
        placeholder={placeholder}
        inputProps={ariaLabel}
        style={style}
        onChange={onChange}
        type={type}
        margin={margin}
        name={name}
        error={error}
        {...(multiline ? { multiline: true } : {})}
        value={value}
        readOnly={isReadable}
      />
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </>
  );
};

const CustomTextField = styled(TextField)(({ theme }) => ({
  border: '1px solid red',
}));
export const NotifyInput = () => {
  return <CustomTextField label="Outlined" variant="outlined" />;
};
