import { FaRegCopyright } from "react-icons/fa";
import "./Footer.css";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer_container">
      <p className="tc_footer">
        <span>
          <FaRegCopyright />
        </span>
        Copyright {currentYear} Lets Notify. All rights reserve. Various
        trademarks held by their respective owners.
      </p>
    </div>
  );
}

export default Footer;
