import React, { useState } from 'react';
import { Box, Typography, Switch, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import EditTemplateModal from '../EditTemplateModal';
import { InnerTextField } from 'components/InputFields';
import { formatDateTime } from 'utils/commonFunctions';

const Details = ({ item, refresh, onClose }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize={'18px'} fontWeight={600} color={'black'}>
          Template Details
        </Typography>
        <Button onClick={handleOpen}>
          <EditIcon />
        </Button>
      </Box>

      <EditTemplateModal
        open={openModal}
        onClose={onClose}
        itemId={item}
        refresh={refresh}
      />

      <Box marginTop={1}>
        <InnerTextField
          label="Template Name"
          value={item?.templateName || ''}
          readOnly={true}
          fullWidth 
        />
      </Box>

      <Box>
        <InnerTextField
          required
          name="title"
          label="Title"
          value={item?.title || ''}
          readOnly={true}
        />
      </Box>

      <Box>
        <InnerTextField
          label="Message"
          value={item?.message || ''}
          readOnly={true}
          fullWidth
        />
      </Box>

      <Box>
        <InnerTextField
          label="Redirecting URL"
          value={item?.pageURL || ''}
          readOnly={true}
          fullWidth
        />
      </Box>
      <br />
      <Typography variant="subtitle3">Upload Notification Icon </Typography>
      <Box>
        {item?.icon && (
          <img
            src={item.icon}
            alt="Notification Icon"
            width={200}
            height={500}
          />
        )}
      </Box>
      <br />
      <Typography variant="subtitle3">Upload Banner Image</Typography>
      <Box>
        {item?.banner && (
          <img src={item?.banner} alt="Your Image" width={200} height={500} />
        )}
      </Box>
      <Box>
        <Box>
          <Typography variant="black_4">Status</Typography>
          <Switch
            name="status"
            size="large"
            checked={item?.status === 'Active'}
            readOnly={true}
          />
        </Box>
      </Box>
      <Box>
        <Typography variant="subtitle2" my={1}>
          <strong>Created time : </strong>
          {formatDateTime(item?.createdTime)}
        </Typography>
        <Typography variant="subtitle2" my={1}>
          <strong> Modified time : </strong>{' '}
          {formatDateTime(item?.modifiedTime)}
        </Typography>
      </Box>
    </Box>
  );
};

export default Details;
