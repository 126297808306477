import { Box, Typography, Grid } from '@mui/material';
import { useState } from 'react';
import HTML_Logo from '../../../assets/Images/Connection/HTML_CSS_Icon.jpg';
import Wordpress_Logo from '../../../assets/Images/Connection/Wordpress_Icon.png';
import Shopify_Logo from '../../../assets/Images/Connection/Shopify_Icon.png';
import Java_Logo from '../../../assets/Images/Connection/Java_Icon.png';
import EmailLink from 'components/Email';

const ConnectionPlatform = ({ onPlatformSelect }) => {
  const Platforms = [
    { name: 'Web', icon: HTML_Logo },
    { name: 'Wordpress', icon: Wordpress_Logo },
    { name: 'Shopify', icon: Shopify_Logo },
    { name: 'More Coming Soon', icon: null },
  ];
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const handlePlatformSelect = (platform) => {
    setSelectedPlatform(platform);
    onPlatformSelect(platform);
  };
  const [loading, setLoading] = useState(false);

  return (
    <Box
      sx={{
        width: { xs: '100%' },
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        px: { xs: 2, sm: 4, md: 4 },
      }}
    >
      <Grid container spacing={3} justifyContent="center">
        {Platforms.map((item, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Box
              onClick={() => item.icon && handlePlatformSelect(item)}
              sx={{
                backgroundColor: '#f0f8ff',
                borderRadius: '8px',
                py: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100px',
                border: '1px solid #e0e0e0',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: '#c0fcf3',
                  cursor: 'pointer',
                },
              }}
            >
              {item.icon ? (
                <img
                  src={item.icon}
                  alt={item.name}
                  style={{
                    width: '50px',
                    height: '50px',
                    marginBottom: '8px',
                  }}
                />
              ) : null}
              <Typography variant="body2" align="center">
                {item.name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Typography
        variant="caption"
        component="h3"
        sx={{ mt: 2, textAlign: 'center' }}
      >
        *To connect with specific providers, email us at <EmailLink />
      </Typography>
    </Box>
  );
};

export default ConnectionPlatform;
