import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Paper,
} from '@mui/material';
import connectionImage from '../../../assets/Images/Connection/connecion.png';
import Logo from '../../../assets/Images/Common/logo.png';
import CreateConnections from './CreateConnection';
import CodeInjection from './CodeInjection';
import ConnectionPlatform from './ConnectionPlatform';
import { loginScrollbar } from 'components/Style';
import ConnectionKey from './ConnectionKey';
import { FetchAllConnnection } from 'Api/Api';

export default function Connections() {
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [connectionName, setConnectionName] = useState('');
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [apiKey, setApiKey] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const [connectionId, setConnectionId] = useState('');

  const handlePlatformSelect = (platform) => {
    setSelectedPlatform(platform);
  };

  const steps = [
    {
      label: connectionName || 'Create Connection',
      description:
        connectionName && connectionId
          ? `Connected to  ${connectionId}`
          : 'Connect your project and start exploring',
      component: (
        <CreateConnections
          setConnectionName={setConnectionName}
          connectionId={connectionId}
          setFlag={setFlag}
          setApiKey={setApiKey}
          setSecretKey={setSecretKey}
          setConnectionId={setConnectionId}
        />
      ),
    },
    {
      label: 'API Generation',
      description: '',
      component: <ConnectionKey apiKey={apiKey} secretKey={secretKey} />,
    },
    {
      label: selectedPlatform ? (
        <>
          <img
            src={selectedPlatform.icon}
            alt={selectedPlatform.name}
            style={{
              width: '40px',
              height: '40px',
              marginRight: '8px',
            }}
          />
          {selectedPlatform.icon}
        </>
      ) : (
        'Connection'
      ),
      description: '',
      component: <ConnectionPlatform onPlatformSelect={handlePlatformSelect} />,
    },
    {
      label: 'Code Injection',
      description: '',
      component: (
        <CodeInjection
          apiKey={apiKey}
          secretKey={secretKey}
          connectionId={connectionId}
          setApiKey={setApiKey}
          setSecretKey={setSecretKey}
        />
      ),
    },
  ];

  const fetchConnectionData = async () => {
    try {
      const response = await FetchAllConnnection();
      if (response?.data?.status === true) {
        if (response?.data?.data !== null) {
          setConnectionId(response?.data?.data?.[0].id);
          setActiveStep(steps.length - 1);
        }
      }
    } catch (error) { }
  };

  const handleStep = (step) => () => {

    if ((connectionId && step === 0) || step === 1) {
      return;
    }
    if (!flag || step !== 0) {
      setActiveStep(step);
    }
  };



  const handleReset = () => {
    setActiveStep(0);
    setCompleted(false);
    setFlag(false);
  };

  useEffect(() => {
    fetchConnectionData();
  }, []);

  useEffect(() => {
    if (flag) {
      setActiveStep(1);
    }
  }, [flag]);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        backgroundColor: '#044E43',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <Grid container sx={{ flex: 1, height: '100%', overflow: 'hidden' }}>
        <Grid
          item
          md={6.8}
          xs={12}
          sx={{
            display: { sm: 'none', xs: 'none', md: 'flex' },
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            padding: 3,
            boxSizing: 'border-box',
          }}
        >
          <Box display="flex" alignItems="center" mb={0}>
            <IconButton disableRipple>
              <img width={50} src={Logo} alt="Logo" />
            </IconButton>
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '2rem',
                color: '#FFFFFF',
                border: '2px solid whitee',
              }}
            >
              Lets Notify
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            <Box width="90%" pl={8} pt={0} border={'2px solid whitee'}>
              <Typography color="#FFFFFF" fontSize="1.8rem">
                One platform, endless possibilities — connect with the provider
                that powers your
                <span
                  style={{
                    fontSize: '1.8rem',
                    fontWeight: 'bold',
                    color: '#08FFD9',
                  }}
                >
                  &nbsp;online success.
                </span>
              </Typography>
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start',
                backgroundColor: 'transparent',
                border: '2px solid rede',
                maxHeight: '60%',
              }}
            >
              <img
                src={connectionImage}
                style={{ maxWidth: '60%', height: 'auto' }}
                alt="Form Filling"
              />
            </Box>
          </Box>
        </Grid>

        <Grid item md={5.2} xs={12}>
          <Box
            height="100vh"
            width="100%"
            backgroundColor="#FFFFFF"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              padding: 3,
              boxSizing: 'border-box',
            }}
          >
            <Box
              sx={{
                ...loginScrollbar,
                paddingX: 2,
                boxShadow: 3,
                borderRadius: 1,
                width: '100%',
                height: '100%',
                maxWidth: 650,
                boxSizing: 'border-box',
                overflowY: 'scroll',
              }}
            >
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      onClick={handleStep(index)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: 'bold',
                          border: '2px solid reddd',
                        }}
                      >
                        {index === 2 && selectedPlatform ? (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              mt: 1,
                            }}
                          >
                            <img
                              src={selectedPlatform.icon}
                              alt={selectedPlatform.name}
                              style={{
                                width: '35px',
                                height: '35px',
                                marginRight: '8px',
                              }}
                            />
                            <Typography
                              variant="h6"
                              style={{ fontWeight: 'bold' }}
                            >
                              {selectedPlatform.name}
                            </Typography>
                          </Box>
                        ) : (
                          <>{step.label}</>
                        )}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          marginTop: '4px',
                          color:
                            activeStep >= index
                              ? 'rgba(0, 0, 0, 0.6)'
                              : 'rgba(0, 0, 0, 0.4)',
                        }}
                      >
                        {step.description}
                      </Typography>
                    </StepLabel>
                    <StepContent>{step.component}</StepContent>
                  </Step>
                ))}
              </Stepper>
              {completed && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                  <Typography>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                    Reset
                  </Button>
                </Paper>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
