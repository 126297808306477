export const Time = [
  { name: '0 sec', value: 0 },
  { name: '1 sec', value: 1 },
  { name: '2 sec', value: 2 },
  { name: '4 sec', value: 4 },
  { name: '6 sec', value: 6 },
  { name: '8 sec', value: 8 },
];
export const DesktopPosition = [
  { name: 'Center', value: 'center' },
  { name: 'Top', value: 'top' },
  { name: 'Top Left', value: 'top-left' },
  { name: 'Top Right', value: 'top-right' },
  { name: 'Bottom Left', value: 'bottom-left' },
  { name: 'Bottom Right', value: 'bottom-right' },
];
export const MobilePosition = [
  { name: 'Center', value: 'center' },
  { name: 'Top', value: 'top' },
];
