import HeaderLayout from './HeaderLayout';
import Footer from './LandingScrollPages/Footer';
import Page1 from './LandingScrollPages/Page1';
import Page2 from './LandingScrollPages/Page2';
import Page3 from './LandingScrollPages/Page3';
import Page4 from './LandingScrollPages/Page4';
import Page5 from './LandingScrollPages/Page5';
import Page6 from './LandingScrollPages/Page6';
import Page7 from './LandingScrollPages/Page7';
import VideoContent from './LandingScrollPages/VideoContent';
import './styles/MainLandingStyles.css';

const MainLandingScreen = () => {
  return (
    <div className="w-100vw overflow-x-hidden overflow-y-hidden" style={{ position: 'relative' }}>
      <HeaderLayout />
      <Page1 classname="main-page page1" />
      <VideoContent classname="main-page page2" />
      <Page2 classname="main-page page2" />
      <Page3 classname="main-page page3" />
      <Page4 classname="main-page page4" />
      <Page5 classname="main-page page5" />
      <Page6 classname="main-page page6" />
      <Page7 className="main-page page6" />
      <Footer />
    </div>
  );
};

export default MainLandingScreen;
