import {
  Box,
  Icon,
  Tabs
} from '@mui/material';
import EnlargeModal, {
  CustomTab,
  CustomTabPanel,
  a11yProps,
} from 'components/EnlargeModal';
import { commonIcons } from 'constants/appConstant';
import React from 'react';
import SubscriberDetails from './SubscriberDetails';
import UnSubscriberDetails from './UnsubscribedDetails';
import CampaignDetails from './CampaignDetails';
import ConditionsDetails from './ConditionsDetails';
import ActivityDetails from './ActivitySegment';

const ViewSegmentModal = ({open, onCloseModal}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <EnlargeModal open={open} onCloseModal={onCloseModal} title={'Segment'}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ minHeight: '40px' }}
          >
            <CustomTab label="Subscribers " {...a11yProps(0)} />
            <CustomTab
              label="Unsubscribers"
              {...a11yProps(1)}
             
            />
            <CustomTab
              label=" Campaigns"
              {...a11yProps(2)}
              icon={
                <Icon sx={{ marginTop: '5px' }}>
                  <img src={commonIcons.campaignIcon} alt="" />
                </Icon>
              }
              iconPosition="start"
            />
            <CustomTab
              label="Conditions"
              {...a11yProps(2)}
              icon={
                <Icon sx={{ marginTop: '5px' }}>
                  <img src={commonIcons.conditionIcon} alt="" />
                </Icon>
              }
              iconPosition="start"
            />
            <CustomTab
            label="Activity"
            {...a11yProps(4)}
            // icon={
            //   <Icon sx={{ marginTop: '5px' }}>
            //     <img src={commonIcons.activityIcon} alt="Activity" />
            //   </Icon>
            // }  
            iconPosition="start"
          />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <SubscriberDetails />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <UnSubscriberDetails />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <CampaignDetails />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <ConditionsDetails />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
        <ActivityDetails />
      </CustomTabPanel>
      </Box>
    </EnlargeModal>
  );
}

export default ViewSegmentModal