import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  options: {
    schedule: 'now', 
    convertIntoAutomation: false,
    triggerCampaign: false,
    subscribersInteractionNeeded: false,
    dateTime: null,
  },
  sequence: {
    nodes: [{
      id: '1',
      type: 'startNode',
      data: { id: '1', label: 'Triggers' },
      position: { x: 250, y: 5 },
    },],
    edges: [],
  },
  subscribers: {
    sequenceName:'',
    selectedSegment: null
  }
};

export const sequenceSlice = createSlice({
  name: 'sequence',
  initialState,
  reducers: {
    updateSequence(state, { payload }) {
      const { nodes, edges } = payload;
      if (nodes) state.sequence.nodes = nodes;
      if (edges) state.sequence.edges = edges;
    },
    updateSubscribers(state, { payload }) {
      const { sequenceName, selectedSegment } = payload;
      if (sequenceName !== undefined) state.subscribers.sequenceName = sequenceName;
      if (selectedSegment !== undefined) state.subscribers.selectedSegment = selectedSegment;
    },
    updateOptions(state, { payload }) {
      state.options = { ...state.options, ...payload.formState };
    },
    resetState() {
      return initialState;
    },
    updateToEdit(state,{payload}) {
      state = {
        options: {
          schedule:payload.schedule, 
          convertIntoAutomation: payload.convertIntoAutomation,
          triggerCampaign: payload.triggerCampaign,
          subscribersInteractionNeeded:payload.subscribersInteractionNeeded,
          dateTime: payload.scheduledTriggeredTime,
        },
        sequence: {
          nodes:payload.nodes,
          edges:payload.edges,
        },
        subscribers: {
          sequenceName:payload.sequenceName,
          selectedSegment:payload.segmentId
        }
      };
      return state;
    }
  }
});

export const { updateSequence, updateSubscribers, updateOptions,resetState,updateToEdit } = sequenceSlice.actions;

export default sequenceSlice.reducer;
