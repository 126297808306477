import {
  Box,
  Button,
  Card,
  FormLabel,
  Switch,
  Tab,
  TextField,
  Typography,
} from '@mui/material';
import logoSrc from 'assets/Images/Common/letsnotify.png';
import React, { useState } from 'react';
import { LuUpload } from 'react-icons/lu';
import '@rc-component/color-picker/assets/index.css';
import { DesktopPosition, MobilePosition, Time } from './constant';
import { PannelStyleCP, SwitchForCustomPrompt } from './Style';
import { InnerTextField } from 'components/InputFields';
import { CustomSelect } from 'components/CustomSelect';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { CreateCustomPrompt } from 'Api/Api';
import { Loading } from 'components/Loading';
import { SideDrawer } from 'components/SideDrawer';
import toast from 'react-hot-toast';

function CreateCustompromptPage({ open, onClose }) {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    status: 'Active',
    iconImage: '',
    allowButtonText: '',
    allowButtonBackgroundColor: '#058270',
    allowButtonTextColor: '#e5e5e5',
    laterButtonText: '',
    mobileTiming: 0,
    desktopTiming: 0,
    hidePromptFrequency: '',
    showPromptFrequency: '',
    desktopPosition: 'center',
    mobilePosition: 'center',
  });
  const [image, setImage] = useState({
    logo: '',
  });

  const [tabValue, setTabValue] = useState('1');
  const [loading, setLoading] = useState(false);
  const [isPreview, setIsPreview] = useState(false);

  const handletabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleToggle = (e) => {
    const name = e.target.name;
    if (name === 'status') {
      const newStatus = formData?.status === 'Active' ? 'Inactive' : 'Active';
      setFormData((prevState) => ({
        ...prevState,
        status: newStatus,
      }));
    }
  };

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData((prev) => ({
        ...prev,
        iconImage: file,
      }));
      setImage((prev) => ({
        ...prev,
        logo: URL.createObjectURL(file),
      }));
    }
  };

  const navigateBack = () => {
    window.history.back();
  };

  const Submit = async () => {
    setLoading(true);
    try {
      const response = await CreateCustomPrompt(formData);
      if (response?.data?.status === true) {
        toast.success(response?.data?.message);
        onClose();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Loading state={loading} />
      <SideDrawer
        open={open}
        onClose={onClose}
        title={'Custom Prompt'}
        handleSubmit={Submit}
        EyeIcon={true}
        isPreview={isPreview}
        setIsPreview={setIsPreview}
      >
        {!isPreview && (
          <Box>
            <Box width={'90%'}>
              <Box>
                <InnerTextField
                  required
                  margin="dense"
                  name="title"
                  label="Title"
                  value={formData.title}
                  onChange={handleChange}
                />
              </Box>

              <Box>
                <InnerTextField
                  required
                  name="description"
                  label="Description"
                  value={formData.description}
                  onChange={handleChange}
                />
              </Box>

              {/* <Box >
                <FormLabel
                  sx={{
                    color: 'rgba(116, 114, 114, 1)',
                    fontSize: '18px',
                    marginBottom: '90px',
                  }}
                >
                  Status
                </FormLabel>
                <SwitchForCustomPrompt
                  sx={{ m: 1, ml: 3 }}
                  checked={formData.status}
                  name="status"
                  margin="dense"
                  onChange={(e) => handleInputChange(e)}
                />
              </Box> */}
              <Box marginY={1}>
                <Typography variant="black_4">Status</Typography>
                <Switch
                  name="status"
                  size="large"
                  checked={formData.status === 'Active'}
                  onChange={handleToggle}
                />
              </Box>

              <Box>
                <FormLabel
                  sx={{ color: 'rgba(116, 114, 114, 1)', fontSize: '18px' }}
                >
                  Upload Logo
                </FormLabel>
                <Button
                  variant="contained"
                  sx={{
                    ml: 3,
                    padding: 0,
                    width: 40,
                    minWidth: 0,
                    height: 40,
                    borderRadius: 3,
                  }}
                  component="label"
                >
                  <LuUpload size={20} />
                  <TextField
                    name="iconImage"
                    type="file"
                    hidden
                    onChange={handleLogoUpload}
                  />
                </Button>
              </Box>
              <br />

              <Box>
                <Typography sx={{ fontSize: '18px' }} variant="black_p">
                  Allow Button
                </Typography>

                <InnerTextField
                  required
                  name="allowButtonText"
                  label="Text"
                  value={formData.allowButtonText}
                  onChange={handleChange}
                />
              </Box>
              <Box>
                <InnerTextField
                  required
                  name="allowButtonBackgroundColor"
                  label="Background Color"
                  colorSelection
                  value={formData.allowButtonBackgroundColor}
                  onChange={handleChange}
                />
              </Box>
              <Box>
                <InnerTextField
                  required
                  name="allowButtonTextColor"
                  label="Text Color"
                  colorSelection
                  value={formData.allowButtonTextColor}
                  onChange={handleChange}
                />
              </Box>
              <Box marginBottom="10px">
                <InnerTextField
                  required
                  margin="dense"
                  label="Later Button Text"
                  name="laterButtonText"
                  value={formData.laterButtonText}
                  onChange={handleChange}
                />
              </Box>

              <Typography
                sx={{ fontSize: '16px', marginBottom: '20px' }}
                variant="black_p"
              >
                Opt-in Timings
              </Typography>
              <Box marginY="2%">
                <CustomSelect
                  label="Mobile (In Sec)"
                  name="mobileTiming"
                  margin="normal"
                  fullWidth
                  options={Time}
                  defaultValue={formData.mobileTiming}
                  onChange={handleChange}
                />
              </Box>

              <Typography
                sx={{ fontSize: '16px', marginBottom: '20px' }}
                variant="black_p"
              >
                Opt-in Timings
              </Typography>
              <Box marginY="2%">
                <CustomSelect
                  label="Desktop (In Sec)"
                  name="desktopTiming"
                  margin="normal"
                  fullWidth
                  options={Time}
                  defaultValue={formData.desktopTiming}
                  onChange={handleChange}
                />
              </Box>
              <Box>
                <InnerTextField
                  required
                  name="hidePromptFrequency"
                  label="Hide Prompt Frequency"
                  value={formData.hidePromptFrequency}
                  onChange={handleChange}
                />
              </Box>
              <Box>
                <InnerTextField
                  required
                  name="showPromptFrequency"
                  label="Show Prompt Frequency"
                  value={formData.showPromptFrequency}
                  onChange={handleChange}
                />
              </Box>

              <Typography
                sx={{ fontSize: '16px', marginBottom: '10px' }}
                variant="black_p"
              >
                Position
              </Typography>
              <Box marginY="3%">
                <CustomSelect
                  label="Desktop"
                  name="desktopPosition"
                  margin="normal"
                  fullWidth
                  value={formData.desktopPosition}
                  options={DesktopPosition}
                  defaultValue={formData.desktopPosition}
                  onChange={handleChange}
                />
              </Box>
              <Box marginY="4%">
                <CustomSelect
                  label="Mobile"
                  name="mobilePosition"
                  margin="normal"
                  fullWidth
                  value={formData.mobilePosition}
                  options={MobilePosition}
                  defaultValue={formData.mobilePosition}
                  onChange={handleChange}
                />
              </Box>
            </Box>
          </Box>
        )}

        {/* <Box
              sx={{
                height: '60px',
                display: 'flex',
                justifyContent: 'center',
                gap: 3,
                alignItems: 'center',
                borderTop: '1.8px solid rgba(241, 242, 247, 1)',
              }}
            >
              <Button variant="outlined" onClick={navigateBack}>
                Cancel
              </Button>
              <Button onClick={Submit} variant="contained">
                Save
              </Button>
            </Box> */}
        {/* </Card> */}

        {isPreview && (
          <Box sx={{ height: '100%', width: '100%' }}>
            <Box
              sx={{
                height: '60px',
                display: 'flex',
                p: 1,
                alignItems: 'center',
                borderBottom: '1.8px solid rgba(241, 242, 247, 1)',
              }}
            >
              <Typography
                variant="black_h4"
                style={{
                  fontFamily: 'Outfit',
                  fontSize: '20px',
                  fontWeight: 400,
                  textAlign: 'left',
                }}
              >
                Preview
              </Typography>
            </Box>
            <Box
              sx={{
                height: 'calc(100% - 60px)',
              }}
            >
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={handletabChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Desktop" value="1" />
                    <Tab label="Mobile" value="2" />
                  </TabList>
                </Box>
                <TabPanel sx={{ height: '60vh' }} value="1">
                  <Box
                    sx={{
                      mx: 'auto',
                      width: '90%',
                      borderRadius: 4,
                      height: '100%',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                      border: '1.8px solid rgba(0,0,0,.12)',
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        height: '40px',
                        borderBottom: '1.8px solid rgba(0,0,0,.12)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        pl: 2,
                      }}
                    >
                      {['#EC6A5E', '#F5BF4F', '#61C554'].map((color, index) => (
                        <Box
                          key={index}
                          sx={{
                            height: 15,
                            width: 15,
                            borderRadius: '50%',
                            backgroundColor: color,
                          }}
                        ></Box>
                      ))}
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        height: 'calc(100% - 40px)',
                        backgroundColor: '#f6f6f6',
                        display: 'flex',
                        p: 2,
                        justifyContent: 'center',
                        ...getPositionStyles(formData.desktopPosition),
                      }}
                    >
                      <Card sx={{ width: '200px', height: '80px' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            width: '100%',
                            height: '60%',
                            px: 1,
                          }}
                        >
                          {image.logo && (
                            <img
                              src={image.logo}
                              alt="Logo"
                              style={{
                                width: '50px',
                                height: '50px',
                                overflow: 'hidden',
                                objectFit: 'contain',
                              }}
                            />
                          )}
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={'100%'}
                          >
                            <Typography
                              variant="black_p"
                              sx={{ fontSize: '12px', fontWeight: 500 }}
                            >
                              {formData?.title}
                            </Typography>
                            <Typography
                              variant="black_p"
                              sx={{ fontSize: '8px' }}
                            >
                              {formData?.description}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'right',
                            width: '100%',
                            fontSize: 9,
                            gap: 0.5,
                            px: 1,
                            height: '14px',
                          }}
                        >
                          {formData?.laterButtonText && (
                            <Box
                              sx={{
                                borderColor: 'gray',
                                borderWidth: '.5px',
                                color: 'gray',
                                p: '1px 2px',
                                borderRadius: 1,
                              }}
                            >
                              {formData?.laterButtonText}
                            </Box>
                          )}
                          {formData?.allowButtonText && (
                            <Box
                              sx={{
                                bgcolor: formData.allowButtonBackgroundColor,
                                color: formData.allowButtonTextColor,
                                p: '1px 3px',
                                borderRadius: 1,
                              }}
                            >
                              {formData?.allowButtonText}
                            </Box>
                          )}
                        </Box>
                        <Box
                          display={'flex'}
                          width={'100%'}
                          justifyContent={'end'}
                          alignItems={'center'}
                          px={1}
                          gap={1}
                        >
                          <p className="text-[8px]">Powered by</p>
                          <img className="h-3" src={logoSrc} />
                        </Box>
                      </Card>
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel sx={{ height: '70vh' }} value="2">
                  <Box
                    sx={{
                      position: 'relative',
                      mx: 'auto',
                      width: '50%',
                      borderRadius: 4,
                      height: '100%',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                      border: '1.8px solid rgba(0,0,0,.12)',
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        height: '40px',
                        borderBottom: '1.8px solid rgba(0,0,0,.12)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        pl: 2,
                      }}
                    >
                      {['#EC6A5E', '#F5BF4F', '#61C554'].map((color, index) => (
                        <Box
                          key={index}
                          sx={{
                            height: 15,
                            width: 15,
                            borderRadius: '50%',
                            backgroundColor: color,
                          }}
                        ></Box>
                      ))}
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        height: 'calc(100% - 40px)',
                        backgroundColor: '#f6f6f6',
                        display: 'flex',
                        justifyContent: 'center',
                        ...getPositionStyles(formData.mobilePosition),
                      }}
                    >
                      <Card sx={{ width: '200px', height: '80px', mt: 2 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            width: '100%',
                            height: '60%',
                            px: 1,
                          }}
                        >
                          {image.logo && (
                            <img
                              src={image.logo}
                              alt="Logo"
                              style={{
                                width: '50px',
                                height: '50px',
                                overflow: 'hidden',
                                objectFit: 'contain',
                              }}
                            />
                          )}
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={'100%'}
                          >
                            <Typography
                              variant="black_p"
                              sx={{ fontSize: '12px', fontWeight: 500 }}
                            >
                              {formData?.title}
                            </Typography>
                            <Typography
                              variant="black_p"
                              sx={{ fontSize: '8px' }}
                            >
                              {formData?.description}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'right',
                            width: '100%',
                            fontSize: 9,
                            gap: 0.5,
                            px: 1,
                            height: '14px',
                          }}
                        >
                          {formData?.laterButtonText && (
                            <Box
                              sx={{
                                borderColor: 'gray',
                                borderWidth: '.5px',
                                color: 'gray',
                                p: '1px 2px',
                                borderRadius: 1,
                              }}
                            >
                              {formData?.laterButtonText}
                            </Box>
                          )}
                          {formData?.allowButtonText && (
                            <Box
                              sx={{
                                bgcolor: formData.allowButtonBackgroundColor,
                                color: formData.allowButtonTextColor,
                                p: '1px 3px',
                                borderRadius: 1,
                              }}
                            >
                              {formData?.allowButtonText}
                            </Box>
                          )}
                        </Box>
                        <Box
                          display={'flex'}
                          width={'100%'}
                          justifyContent={'end'}
                          alignItems={'center'}
                          px={1}
                          gap={1}
                        >
                          <p className="text-[8px]">Powered by</p>
                          <img className="h-3" src={logoSrc} />
                        </Box>
                      </Card>
                    </Box>
                  </Box>
                </TabPanel>
              </TabContext>
            </Box>
          </Box>
        )}
        {/* </Box> */}
      </SideDrawer>
    </>
  );
}

export default CreateCustompromptPage;

const getPositionStyles = (position) => {
  switch (position) {
    case 'center':
      return {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      };
    case 'top':
      return { display: 'flex', justifyContent: 'center' };
    case 'top-left':
      return { display: 'flex', justifyContent: 'start', alignItems: 'start' };
    case 'top-right':
      return { display: 'flex', justifyContent: 'end', alignItems: 'start' };
    case 'bottom-left':
      return { display: 'flex', justifyContent: 'start', alignItems: 'end' };
    case 'bottom-right':
      return { display: 'flex', justifyContent: 'end', alignItems: 'end' };
    default:
      return {};
  }
};
