import React from 'react';
import './TermsAndCondition.css';
import logo from '../../../assets/Images/Common/logo.png';
import { useLoadingContext } from 'react-router-loading';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import EmailLink from 'components/Email';

const TermsAndCondition = () => {
  const loadingContext = useLoadingContext();
  setInterval(function () {
    loadingContext.done();
  }, 1000);

  const navigate = useNavigate();
  return (
    <div>
      <div className=" w-full md:h-15 h-20 bg-[#F5F5F5] flex items-center md:pl-14 pl-4 md:pr-14 justify-between  z-10 fixed top-0 ">
        <div
          className="flex h-10 items-center"
          onClick={() => {
            navigate('/');
          }}
        >
          <img src={logo} alt="logo" className="md:h-12 h-8" loading="lazy" />
          <div className=" text-black h-7  w-32 pl-2 font-bold text-xl ml-3 border-l border-teal-800">
            Lets Notify
          </div>
        </div>
      </div>
      <div className="tc-body  mt-24">
        <h1 className="tc-heading text-3xl font-semibold">
          Terms & Conditions
        </h1>
        <div>
          <br />
          <h4>1.INTRODUCTION</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            The Site (defined hereinafter), www.letsnotify.co.in is owned,
            hosted and operated by M/s. NotifySuite Techonology India Pvt Ltd.{' '}
            <br /> The following terms and conditions, together with any
            documents referred to
            <span className="tc-bold">(“Terms of Use”)</span> incorporated
            herein constitute a legally binding agreement made by and between
            NotifySuite Techonology India Pvt Ltd, a company incorporated under
            the laws of provisions of Indian Companies Act, 1956{' '}
            <span className="tc-bold">
              (hereinafter referred to as “the Company” or “Letsnotify” or “we”
              or “our” or “us” and its successors and assigns)
            </span>{' '}
            and whether personally or on behalf of an entity or any person{' '}
            <span className="tc-bold">
              (hereinafter referred to as “you,” “your” or “yourself” or “user”)
            </span>
            , concerning your access to concerning your access to and use of the
            “www.letsnotify.co.in” website/application as well as any other
            media form, media channel, mobile website related linked, or
            otherwise connected thereto (collectively, the “Site”). The Site
            provides an online facility to send push notifications to your
            users. In order to make the Site a secure environment, all users are
            required to accept and comply with these Terms and Conditions.
          </p>
          <br />
          <h4> 2.USING OUR SERVICES</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            The Terms of Use contained herein along with the Privacy Policy
            (captured under a separate link), form an agreement regulating the
            relationship with regard to the use of the Site. These Terms of Use
            constitute a legally binding agreement between you and the Company
            and shall govern the use of the Site. You shall be required to read
            and accept these Terms of Use for availing the services. Therefore,
            it is clarified that use and access of the Site by the user
            constitutes an acknowledgement and acceptance by the user of these
            Terms of Use.
            <span className="tc-bold">
              BY ACCESSING, BROWSING OR OTHERWISE USING THE SITE YOU AGREE AND
              ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND
              BY THESE TERMS OF USE. IF AT ANY TIME YOU DO NOT AGREE WITH ALL OR
              ANY OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM
              USING THE SITE AND/OR SERVICES OFFERED HEREIN AND YOU MUST
              DISCONTINUE USE OF THE SITE IMMEDIATELY.
            </span>
            The Company reserves the right to revise and update these Terms of
            Use at any time and for any reason in its sole discretion, by
            posting changes at www.letsnotify.co.in (or another URL that the
            Company may provide from time to time). You are expected to keep
            yourself updated with these Terms of Use. You will be subject to and
            will be deemed to have been made aware of and kept up to date of
            such revised Terms of Use. If You do not agree to the modified Terms
            of Use, you should discontinue use of the Site and/or Services.
          </p>{' '}
          <br></br>
          <h4>3.SERVICES TO BE OFFERED BY THE SITE</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            The Company offers a platform that facilitates Push Notifications
            (“Services”) by or through www.letsnotify.co.in( Note: please
            incorporate the various modes of providing services E.g.
            website/mobile applications) to its end users i.e. “Consumers”. The
            Terms of Use set out hereof, exclusively regulate the offer,
            transmission and acceptance of Services offered by the Site between
            the user of Site and the Company. However, use of the Site,
            supplemental terms and conditions or documents that may be posted on
            the Site from time to time are hereby expressly incorporated herein
            by reference. The Company reserves the right, in its sole
            discretion, to make changes or modifications to these Terms of Use
            at any time and for any reason. It is your responsibility to
            periodically review these Terms of Use to stay informed of updates.
            You will be subject to and will be deemed to have been made aware of
            and to have accepted, the changes in any revised Terms of Use by
            your continued use of the Site after the date such revised Terms of
            Use are posted. information provided on the Site is not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation or which would subject to any registration requirement
            within such jurisdiction or country. Accordingly, those persons who
            choose to access the Site from other locations do so on their own
            initiative and are solely responsible for compliance with local
            laws, if and to the extent local laws are applicable.
          </p>{' '}
          <br></br>
          <h4>4.ELIGIBILITY TO USE SITE</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            <ol Type="a">
              <li>
                For availing the facilities provided herein, you shall comply
                with the eligibility criteria before using the facilities
                provided by the Site.
              </li>
              <li>
                The use of the Services is available only to the Person (defined
                hereinafter) who can form legally binding contract as per your
                local laws or under Indian Contract Act, 1872. Persons who are
                "incompetent to contract" within the meaning of respective local
                laws including minors, un-discharged insolvents etc. are not
                eligible to register on the Site or use the Services.
              </li>
              <li>
                The Company reserves rights to reject or grant access to a new
                user or terminate the access of existing user(s), at any time
                without prior intimation and without cause.
              </li>
              <li>
                In general, a Person who has attained the age of (18) eighteen
                years is not considered a minor. However, we advise to check
                your local laws to comply with the provisions.
              </li>
            </ol>
          </p>{' '}
          <br></br>
          <h4>5.SIGNUP/ REGISTRATION</h4>
          <p>
            <br />
            <ol Type="a">
              <li>
                {' '}
                You shall be required to register on Site, which shall be made
                available only to person(s) who meet or fulfill the eligibility
                terms as specified herein.
              </li>
              <li>
                {' '}
                You shall be required to register on Site to avail Services, the
                option of registration is available exclusively to the person
                who meets or fulfil the eligibility criteria as specified
                herein.
              </li>
              <li>
                The registration process enables You to access the Services or
                use the Services. You shall be required to register on the Site
                by providing username, email address, name, cell-phone number,
                password, et al. You shall be requested to upload or provide an
                identity proof at the time of registration or thereafter in
                order to comply with the legal provisions of the respective
                jurisdiction.
              </li>
              <li>
                You shall be responsible for maintaining the confidentiality of
                your username/ display name and password and for all activities
                that occur under/ from your account. You agree and acknowledge
                that you will provide accurate, complete and updated information
                in order to register on the Site to use the Services.
              </li>
              <li>
                You further covenant that if any information is found untrue,
                inaccurate or incomplete or the Company identifies reasonable
                grounds to suspect that such information is untrue, inaccurate,
                not current, incomplete, or not in line with the Terms of Use,
                the Company shall have the right to forthwith suspend or
                terminate or block access of your account and refuse to provide
                the access to the Site.
              </li>
            </ol>
          </p>
          <br></br>
          <h4>6.REPRESENTATIONS</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            <h5>a) User Representations :</h5> By using the Site, user/ you
            represent and warrant that
            <br /> <br />
            <ol Type="1">
              <li>
                all registration information will be true, accurate, current,
                and complete.
              </li>
              <li>
                {' '}
                you will maintain the accuracy of such information and promptly
                update such registration information as necessary.
              </li>
              <li>
                you have the legal capacity and you agree to comply with these
                Terms of Use
              </li>
              <li>
                {' '}
                you are not a minor in the jurisdiction in which you reside
              </li>
              <li>
                you will not access the Site through automated or non-human
                means, whether through a script or otherwise
              </li>
              <li>
                you will not use the Site for any illegal or lawful purpose
              </li>
              <li>
                you shall not use the Services in a manner that it
                <ul>
                  <li>
                    infringes or violates the intellectual property rights or
                    any other rights of any third party
                  </li>
                  <li>violates any law or regulation, </li>
                  <li>
                    is harmful, fraudulent, deceptive, threatening, harassing,
                    defamatory, obscene, or otherwise objectionable,
                  </li>
                  <li>
                    attempts, in any manner, to obtain the password, account, or
                    other security information from any other user(s), and{' '}
                  </li>
                  <li>
                    violates the security of any computer network, or cracks any
                    passwords, or security encryption codes.
                  </li>
                </ul>
              </li>
            </ol>
            In all of the above conditions, the Company has the right to suspend
            or terminate your account and refuse any and all current or future
            use of the Site. <br /> <br />
            <br />
            <h5>b) Company’s Representation</h5>
            <br />
            <ol>
              <li Type="1">
                It is dully incorporated under the law and it has the full
                power, right and authority to execute this arrangement and
                perform its obligations.
              </li>
              <li>
                It has obtained all necessary approvals, consents, sanctions or
                authorizations required to enter into and perform these Terms of
                Use and no other approvals, consents, sanctions or
                authorizations of any regulatory authority or any other person
                are required to be obtained by it for the execution, delivery
                and performance of these Terms of Use.
              </li>
              <li>
                The Site and all Services made available to you through this
                Site are provided on "as is" basis without any representations
                or warranties except otherwise provided in writing. The
                execution, delivery and performance of this arrangement does not
                constitute a breach of applicable laws, its charter documents or
                any agreement, arrangement or understanding, oral or written,
                executed by it.
              </li>
              <li>
                You understand and agree that the content of the Site does not
                contain or constitute representations to be reasonably relied
                upon and agrees not to hold the Company liable from any errors,
                omissions, or misrepresentations contained within the Site’s
                content.
              </li>
              <li>
                Notwithstanding anything contained herein to the contrary, the
                Company does not represent and warrant the safety,
                effectiveness, adequacy, accuracy, availability, or legality of
                any of the information contained or displayed on the Site or
                offered through the Site.
              </li>
            </ol>
          </p>{' '}
          <br></br>
          <h4>7.USE OF MATERIALS</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            Without prejudice as expressly indicated herein, the Company hereby
            grants you a non-exclusive, revocable (upon notice from Company),
            non-transferable access to view, download and print Services
            catalogue or any other materials available on the Site, subject to
            the following conditions:{' '}
            <h6>
              You may access and use the materials solely for own purposes and
              in accordance with the Terms of Use;
            </h6>
            <ul>
              <li>
                You may not modify or alter product catalogues or any other
                materials in which Company’s rights subsists.
              </li>
              <li>
                {' '}
                You may not remove any text, data, copyright, et al. contained
                in the Services or any other materials; Any software that is
                available on the Site is the property of the Company and you
                shall not use, download or install the software, unless
                otherwise expressly permitted by the Company.
              </li>
            </ul>
            <br />
            <span className="tc-bold">
              We may also collect the following information:
            </span>
            <ul>
              <li>About the pages you visit / access</li>
              <li>The links you click</li>
              <li>The number of times you access a page</li>
            </ul>
          </p>
          <br />
          <h4>8.PAYMENT</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            We intend to remain free monetization, but for any external factors,
            if we make Letsnotify and any of our other products paid. The
            following conditions for payment will apply.{' '}
            <h6>
              {' '}
              You agree to provide current, complete, and accurate information
              for all Services availed by you via the Site. You further agree to
              promptly update account and payment information, including email
              address, payment method, and payment card expiration date, so that
              the Company can complete your transaction and contact you as if
              needed. All the prices are exclusive of taxes the same will be
              added to the service price as per the applicable laws.
            </h6>
            <span className="tc-bold">
              We will accept the following methods of payment:{' '}
            </span>{' '}
            <br /> <br />
            <ul>
              <li>MasterCard (Credit/ Debit card)</li>
              <li>Visa (Credit/ Debit card)</li>
              <li>American Express (Credit/ Debit card)</li>
              <li>Net banking</li>
            </ul>
            You hereby undertake and agree to pay all/ complete charges in
            advance in consideration of Services and any applicable taxes, and
            you authorize us to charge your chosen payment provider for any such
            amounts. We reserve the right to correct any errors or mistakes in
            pricing, even if we have already requested or received the payment.
          </p>{' '}
          <h4>9.INTELLECTUAL PROPERTY RIGHTS</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            <span className="tc-bold">“Intellectual Property”</span> shall mean
            and include patents, trademarks, service marks, trade names,
            registered designs, copyrights, rights of privacy and publicity and
            other forms of intellectual or industrial property, know how,
            confidential or secret processes, trade secrets, any other protected
            rights or assets and any licenses and permissions in connection
            therewith, whether or not registered or pending registrations and
            for the full period thereof, all extensions and renewals thereof.{' '}
            <br /> <br /> The information provided on the Site and the processes
            are our confidential and proprietary property, including but not
            limited to all text, graphics, user interfaces, visual interfaces,
            software(s), database, code, design etc. (if any), (collectively,
            the <span className="tc-bold">“Content”</span>) on the Site are
            owned and controlled by the Company. The trademarks, service marks,
            and logos contained therein (collectively, the
            <span className="tc-bold"></span> “Marks”) are owned or controlled
            by the Company or licensed to Company, and are protected by
            copyright, patent and trademark laws, and various other intellectual
            property laws. <br /> <br />
            The Company owns all rights to the trademark “Letsnotify” and
            variants thereof. In addition to the aforesaid, the Company owns all
            rights in the domain name including without limitation, any and all
            rights, title and interests in and to the copyright, related rights,
            know-how, goodwill, icons and hyperlinks. You hereby promise to
            abide by all copyright, trademark and other intellectual property
            rules and shall not use, copy, reproduce, modify, translate,
            publish, broadcast, transmit, distribute, perform, upload, display,
            license, sell or otherwise exploit for any purpose without the prior
            written consent of the Company. <br /> <br />
            The Content and the Marks are provided on the Site “AS IS” for your
            information and personal use only. Except as expressly provided in
            these Terms of Use, no part of the Site, Content or Marks may be
            copied, reproduced, aggregated, republished, uploaded, posted,
            publicly displayed, encoded, translated, transmitted, distributed,
            sold, licensed, or otherwise exploited for any commercial purpose
            whatsoever, without our express prior written permission. Subject to
            the information disclosed by you at the time of registration that
            you are eligible to use the Site, you are granted a limited license
            to access and use the Site to which you have properly gained access
            solely for your business use. <br /> <br />
            <span className="tc-bold">
              {' '}
              THE COMPANY RESERVES ALL RIGHTS EXPRESSLY GRANTED TO YOU IN AND TO
              THE SITE, THE CONTENT AND THE MARKS.
            </span>
          </p>
          <br />
          <h4>10.CONFIDENTIAL INFORMATION</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            You shall be bound to comply and abide with all the Terms of Use
            hereof and all guidelines that may be communicated by the Company to
            you. You shall be bound to maintain strict confidentiality with
            respect to all information provided by the Company to you including
            but not limited to Intellectual Property. All information shall be
            kept strictly confidential by you and shall not without prior
            written consent of the Company be divulged and/or disclosed to a
            third party. <br /> <br />
            You further understand and acknowledge that any disclosure or
            unauthorized use of Confidential Information is in violation of
            Terms of Use and shall cause the Company irreparable harm, the
            amount of which may be difficult to ascertain and, therefore, you
            agree that the Company shall have the right to apply to a court of
            competent jurisdiction for an order restraining any such further
            disclosure or unauthorized use and for such other relief as the
            Company deems appropriate. Such right of the Company shall be
            exercisable in addition to the remedies otherwise available at law
            or in equity.
          </p>
          <h4>11.PROHIBITED ACTIVITIES</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            You may not access or use the Site for any purpose other than the
            use or avail the benefits of the Services. The Site may not be used
            in connection with any commercial endeavors (except those that are
            specifically endorsed or approved by the Company) including but not
            limited to: <br /> <br />
            <ul>
              <li>
                Systematically retrieve data or other content from the Site to
                create or compile, directly or indirectly, a collection,
                compilation, database, or directory without written permission
                from the Company
              </li>
              <li>
                Make any unauthorized use of the Site, including collecting
                usernames and/or email addresses of the users by electronic or
                other means for the purpose of sending unsolicited email, or
                creating user account(s) by automated means or under false
                pretenses
              </li>
              <li>
                Make improper use of Our support services or submit false
                reports of abuse or misconduct.
              </li>
              <li>
                Defraud, or mislead us and other users, especially in any
                attempt to hack sensitive account information such as user
                passwords.
              </li>
              <li>Engage in unauthorized framing of or linking to the Site.</li>
              <li>
                Interfere with, disrupt, or create an undue burden on the Site
                or the networks or services connected to the Site.
              </li>
              <li>Sell or otherwise transfer your profile.</li>
              <li>
                Use any information obtained from the Site in order to harass,
                abuse, or harm another person or third-party.
              </li>
              <li>
                Use the Site as part of any effort to compete with the Company
                or otherwise use the Site and/or the Content for any
                revenue-generating endeavor or commercial enterprise without the
                permission of the Company.
              </li>
              <li>
                Decompile, disassemble, or reverse engineer any of the software
                comprising or in any way making up a part of the Site.
              </li>
              <li>
                Copy or adapt the Site’s software, including but not limited to
                Flash, PHP, HTML, JavaScript, or other code.
              </li>
              <li>
                Upload or transmit (or attempt to upload or to transmit)
                viruses, Trojan horses, or other material, including spamming
                (continuous posting of repetitive text), that interferes with
                any party’s uninterrupted use and enjoyment of the Site or
                modifies, impairs, disrupts, alters, or interferes with the use,
                features, functions, operation, etc.
              </li>
              <li>
                Upload or transmit (or attempt to upload or to transmit) any
                material that acts as a passive or active information collection
                or transmission mechanism, including without limitation, clear
                graphics, web bugs, cookies, or other similar devices (sometimes
                referred to as “spyware”).
              </li>
              <li>
                Except as may be the result of standard search engine or
                internet browser usage, launch, develop, or distribute any
                automated system, including without limitation, any spider,
                robot, cheat utility, scraper, or offline reader that accesses
                the Site, or using or launching any unauthorized script or other
                software.
              </li>
              <li>
                Decompile, disassemble, or reverse engineer any of the software
                comprising or in any way making up a part of the Site.
              </li>
            </ul>
          </p>
          <br />
          <h4>12.CONTRIBUTION LICENSE</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            By posting your contributions in the form of reviews/ comments/
            feedback to any part of the Site (collectively, the“Contributions” )
            or making Contributions accessible to the Site by linking your
            account from the Site to any of your social networking accounts, you
            automatically grant, and you represent and warrant that you have the
            right to grant, to the Company an unrestricted, unlimited,
            irrevocable, perpetual, non-exclusive, transferable, royalty-free,
            fully-paid, worldwide right, and license to host, use, copy,
            disclose, sell, resell, publish, broadcast, retitle, archive, store,
            cache, publicly perform, publicly display, reformat, translate,
            transmit, excerpt (in whole or in part), and distribute such
            Contributions (including, without limitation, your image and voice)
            for any purpose, commercial, advertising, or otherwise, and to
            prepare derivative works of, or incorporate into other works, such
            Contributions, and grant and authorize sublicenses of the foregoing.
            The use and distribution may occur in any media formats and through
            any media channels. <br /> <br />
            This license will apply to any forms, media, or technology now known
            or hereafter developed, and includes use of your name, company name,
            and franchise name, as applicable, and any of the trademarks,
            service marks, trade names, logos, and personal and commercial
            images you provide. You waive all moral rights in your
            Contributions, and you warrant that moral rights have not otherwise
            been asserted in your Contributions. <br /> <br /> The Company has
            the right, in its sole and absolute discretion, (1) to edit, redact,
            or otherwise change any Contributions; (2) to re-categorize any
            Contributions to place them in more appropriate locations on the
            Site; and (3) to pre-screen or delete any Contributions at any time
            and for any reason, without notice. Notwithstanding the foregoing,
            the Company has no obligation to monitor your Contributions.
          </p>
          <h4>13.GUIDELINES FOR CONTRIBUTIONS</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            We may provide you areas on the Site to leave Contributions. When
            posting Contributions, you must comply with the following criteria:{' '}
            <ul>
              <li>
                you should have firsthand experience with the person/entity;
              </li>
              <li>
                your Contributions should not contain offensive profanity, or
                abusive, racist, offensive, or hate language
              </li>
              <li>
                your Contributions should not contain discriminatory references
                based on religion, race, gender, national origin, age, marital
                status, sexual orientation, or disability
              </li>
              <li>
                {' '}
                your Contributions should not contain references to illegal
                activity
              </li>
              <li>
                you should not be affiliated with competitors if posting
                negative Contributions
              </li>
              <li>you may not post any false or misleading statements; and</li>
              <li>
                you may not organize a campaign encouraging others to post
                reviews/feedbacks, whether positive or negative.
              </li>
              <li>
                We may accept, reject, or remove Contributions in our sole
                discretion. We have absolutely no obligation to screen
                Contributions or to delete Contributions, even if anyone
                considers the Contributions objectionable or inaccurate.
                Contributions are not endorsed by us, and do not necessarily
                represent our opinions or the views of any of our affiliates or
                partners. We do not assume liability for any review or for any
                claims, liabilities, or losses resulting from any Contributions.
                By posting Contributions, you hereby grant to us a perpetual,
                non-exclusive, worldwide, royalty-free, fully-paid, assignable,
                and sub-licensable right and license to reproduce, modify,
                translate, transmit by any means, display, perform, and/or
                distribute all content relating to Contribution.
              </li>
            </ul>
          </p>
          <br />
          <h4>14.SOCIAL MEDIA</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            As part of the functionality of the Site, you may link your account
            with online accounts you have with third-party service providers
            (each such account, a
            <span className="tc-bold">“Third -Party Account”</span> ) by either:
            <ol>
              <li Type="1">
                providing your Third-Party Account login information through the
                Site; or
              </li>
              <li>
                allowing us to access your Third-Party Account, as is permitted
                under the applicable terms and conditions that govern your use
                of each Third-Party Account. You represent and warrant that you
                are entitled to disclose your Third-Party Account login
                information to us and/or grant us access to your Third-Party
                Account, without breach by you of any of the terms and
                conditions that govern your use of the applicable Third-Party
                Account, and without obligating us to pay any fees or making us
                subject to any usage limitations imposed by the third-party
                service provider of the Third-Party Account.
              </li>
            </ol>
            By granting us access to any Third-Party Accounts, you understand
            that
            <ul>
              <li Type="1">
                we may access, make available, and store (if applicable) any
                content that you have provided to and stored in your Third-Party
                Account (the{' '}
                <span className="tc-bold">“Social Network Content”</span> ) so
                that it is available on and through the Site via your account,
                including without limitation any friend lists; and{' '}
              </li>
              <li Type="1">
                we may submit to and receive from your Third-Party Account
                additional information to the extent you are notified when you
                link your account with the Third-Party Account. Depending on the
                Third-Party Accounts you choose and subject to the privacy
                settings that you have set in such Third-Party Accounts,
                personally identifiable information that you post to your
                Third-Party Accounts may be available on and through your
                account on the Site. Please note that if a Third-Party Account
                or associated service becomes unavailable or our access to such
                Third-Party Account is terminated by the third-party service
                provider, then Social Network Content may no longer be available
                on and through the Site. You will have the ability to disable
                the connection between your account on the Site and your
                Third-Party Accounts at any time.
              </li>
            </ul>
            <span className="tc-bold">
              {' '}
              PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE
              PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED
              SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE
              PROVIDERS.{' '}
            </span>
            The Company makes no effort to review any Social Network Content for
            any purpose, including but not limited to, for accuracy, legality,
            or non-infringement, and we are not responsible for any Social
            Network Content. You acknowledge and agree that we may access your
            email address book associated with a Third-Party Account and your
            contacts list stored on your mobile device or tablet computer solely
            for purposes of identifying and informing you of those contacts who
            have also registered to use the Site. You can deactivate the
            connection between the Site and your Third-Party Account by
            contacting us using the contact information (<EmailLink />) or
            through your account settings (if applicable). We will attempt to
            delete any information stored on our servers that was obtained
            through such Third-Party Account, except the username and profile
            picture that become associated with your account.
          </p>
          <br></br>
          <h4>15.SUBMISSIONS</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            You acknowledge and agree that any questions, comments, suggestions,
            ideas, feedback, advice or other information regarding the Site
            ("Submissions") provided by you are non-confidential and shall
            become our sole property. We shall own exclusive rights, including
            all intellectual property rights, and shall be entitled to the
            unrestricted use and dissemination of these Submissions for any
            lawful purpose, commercial or otherwise, without providing any
            acknowledgment or compensation to you. You hereby waive all moral
            rights to any such Submissions, and you hereby warrant that any such
            Submissions are original or that you have the right to submit such
            Submissions. You agree there shall be no recourse against the
            Company for any alleged or actual infringement or misappropriation
            of any proprietary right in your Submissions.
          </p>
          <br></br>
          <h4>16.SUBMISSIONS</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            The Company holds and reserves the right, but not the obligation,
            to:{' '}
            <ul>
              <li>Monitor the Site for violations of these Terms of Use;</li>
              <li>
                Take appropriate legal action against anyone who, in our sole
                discretion, violates or intend to violate the laws or these
                Terms of Use, including without limitation, reporting such user
                to law enforcement authorities;
              </li>
              <li>
                In our sole discretion and without limitation, refuse, restrict
                access to, limit the availability of, or disable (to the extent
                technologically feasible) any of your Contributions or any
                portion thereof;
              </li>
              <li>
                In our sole discretion and without limitation, notice, or
                liability, to remove from the Site or otherwise disable all
                files and content that are excessive in size or are in any way
                burdensome to our systems; and
              </li>
              <li>
                Manage the Site in a manner designed to protect our rights and
                property and to facilitate the proper functioning of the Site.
              </li>
            </ul>
          </p>
          <br />
          <h4>17.COPYRIGHT INFRINGEMENTS</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            We respect the intellectual property rights of others. If you
            believe that any material available on or through the Site infringes
            upon any copyright you own or control, please immediately notify the
            Company using the contact information provided (<EmailLink />) (a{' '}
            <span className="tc-bold"> “Notification”</span>). A copy of your
            Notification will be sent to the person who posted or stored the
            material addressed in the Notification. Please be advised that
            pursuant to applicable laws you may be held liable for damages if
            you make material misrepresentations in a Notification. Thus, if you
            are not sure that material located on or linked to by the Site
            infringes your copyright, you should consider first contacting an
            attorney.
          </p>
          <br></br>
          <h4>18.TERMINATION</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            These Terms of Use shall remain in full force and effect while you
            use the Site. The Terms will continue to apply until terminated by
            either you or the Company as set forth below. <br />
            <br />
            If you want to terminate your agreement with the Company, you may do
            so by:
            <br />
            <ul>
              <li>not accessing the Site; OR</li>
              <li>
                closing / deleting of your account (You can write to {" "}
                <EmailLink /> for account and data deletion).
              </li>
            </ul>
            <br />
            Without limiting any other provision of these Terms of Use, the
            Company reserves the right to, at its discretion and at any time
            without notice or liability, deny access to use or terminate your
            account (including blocking certain IP addresses), to any person for
            any cause or for no cause, including without limitation for breach
            of any representation, warranty, or covenant contained in these
            Terms of Use or of any applicable law or regulation if:-
            <ul>
              <li>
                breach of any provisions of the Terms of Use, the Privacy Policy
                or any other terms, conditions, or policies that may be
                applicable to you
              </li>
              <li>
                Company has elected to discontinue, with or without reason,
                access to the Site, the Services (or any part thereof)
                specifically to you; or
              </li>
              <li>For technical or any other reason.</li>
            </ul>
            <br />
            Termination or suspension may include:
            <ul>
              <li>
                removal of access to all or portion of the offerings on the Site
                or with respect to the Services or any portion thereof
              </li>
              <li>deletion of your account and the information thereof</li>
              <li>
                In addition to terminating or suspending your account, the
                Company reserves the right to take appropriate legal action,
                including without limitation pursuing civil, criminal, and
                injunctive redress.
              </li>
              <li>
                You hereby agree and acknowledge that upon termination for
                whatsoever reason, you will stop or refrain Yourself from using
                the Site or any Services provided hereunder and forthwith will
                return all confidential information, proprietary information and
                intellectual property of the Company (if any).
              </li>
              <li>
                {' '}
                Your sole right to terminate these Terms of Use is by deleting
                your account or not accessing the Site.
              </li>
            </ul>
          </p>
          <br />
          <h4>19.MODIFICATIONS AND INTERRUPTIONS</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            <ul>
              <li>
                The Company reserves the right to change, modify, alter, or
                remove the contents of the Site at any time or for any reason at
                its sole discretion without notice. However, we have no
                obligation to update any information on our Site.
              </li>
              <li>
                The Company also reserves the right to modify or discontinue all
                or part without notice at any time.
              </li>
              <li>
                The Company will not be liable to you or any third party for any
                modification, price change, suspension, or discontinuance of the
                Site.
              </li>
              <li>
                The Company cannot guarantee the Site will be available at all
                times as may experience hardware, software, or other problems
                including all the technical issues or need to perform
                maintenance related to the Site, resulting in interruptions,
                delays, or errors.
              </li>
              <li>
                You agree that the Company has no liability whatsoever for any
                loss, damage, or inconvenience caused by your inability to
                access or use the Site during any downtime or discontinuance of
                the Site.
              </li>
              <li>
                Nothing in these Terms of Use will be construed to obligate the
                Company to maintain and support the Site or to supply any
                corrections, updates, or releases in connection therewith
              </li>
            </ul>
          </p>
          <br />
          <h4>20.DISCLAIMER OF WARRANTIES</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            <span>
              EXCEPT AS EXPRESSLY SET FORTH HEREIN, THE SERVICES ARE PROVIDED
              “AS IS” AND “AS AVAILABLE” AND WITHOUT WARRANTY OF ANY KIND. YOU
              AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR RISK. THE COMPANY
              MAKES NO WARRANTIES OF ANY KIND TO YOU OR ANY THIRD PARTY WITH
              RESPECT TO THE SERVICES, INCLUDING, WITHOUT LIMITATION, ANY
              IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, OPERABILITY OR NON-INFRINGEMENT, AND ALL SUCH WARRANTIES
              ARE HEREBY EXCLUDED BY THE COMPANY AND WAIVED BY YOU. FOR EXAMPLE,
              WE DO NOT MAKE ANY COMMITMENTS ABOUT THE CONTENT WITHIN THE
              SERVICES, THE OPERATION OF THE SERVICES, THE OUTPUT OF THE
              SERVICES, OR THE RESULTS FROM THE SERVICES.
            </span>{' '}
            <br />
            <br />
            <h6>
              For greater certainty, the Company does not make any guarantee
              that the Services will be uninterrupted, error-free, or completely
              secure. You acknowledge that there are risks inherent in
              technology introduced in the Services including but not limited to
              servers, networks, programming and other related technology that
              could result in the loss of your business, profits, privacy,
              confidential information and property. Notwithstanding the
              foregoing, the Company shall provide full support in case any
              error or bug in the Services subject to you shall be responsible
              for all or any loss of profit, or damage cause to you.{' '}
            </h6>
          </p>
          <br />
          <h4>21.GOVERNING LAW AND DISPUTE RESOLUTION</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            {' '}
            <br />
            <ul>
              <li>
                All disputes, differences relating to these Terms of Use, its
                enforceability or its termination under these Terms of Use and
                your use of the Site or any interpretation thereof are governed
                by and will be construed under the laws of India, without regard
                to its conflict of law principles, thereof, unless prohibited by
                law.
              </li>
              <li>
                Any legal suit, proceedings or action of whatever nature arising
                out of, or related to, these Terms of Use or the Site, brought
                either by you or the Company (collectively, the “Parties” and
                individually, a “Party”) shall be commenced or prosecuted in the
                courts of Hyderabad(India), and the Parties hereby consent to,
                and waive all defenses of lack of personal jurisdiction and
                forum non convenience with respect to venue and jurisdiction.
              </li>
            </ul>
            THIS CLAUSE SHALL SURVIVE THE TERMINATION OR EXPIRY OF THE TERMS OF
            USE.
          </p>
          <br />
          <h4>22.CORRECTIONS</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            {' '}
            <br />
            There may be information on the Site that contains typographical
            errors, inaccuracies, omissions or mistakes, including descriptions,
            pricing, availability, and various other information. We reserve the
            right to correct any errors, inaccuracies, omissions or mistakes and
            to change, modify, alter or update the information on the Site at
            any time, without prior notice.
          </p>
          <br />
          <h4>23.NOTICES</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            Any notices, requests, complaints, queries, demands and any other
            communications required or permitted to submit in relation to above
            or any other circumstances that arise due to the use of the Site or
            under these Terms of Use shall be communicated at <EmailLink />
            <br /> <br /> User understands that there may be instances of
            difficulty in accessing or receiving said notices and the Company
            will not responsible in any case if any email or replies to the
            user’s notices, requests, complaints, queries or demands, in any
            case, if not delivered or if user do not see the email or reply or
            if user has given us an incorrect email id or address or for any
            other reason.
          </p>
          <br></br>
          <h4>24.INDEMNIFICATION</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            You hereby agree and undertake to indemnify and keep indemnified the
            Company and its representatives against all claims, demands,
            damages, penalties, costs or expenses of any kind which may arise
            against or be incurred by the Company (including reasonable
            attorney’s fees and expenses) to the extent of direct and actual
            loss and/or damages adjudicated by the competent court of law,
            <br />
            <ol>
              <li Type="a">
                as a result of any violation of the Terms of Use or Privacy
                Policies,
              </li>
              <li Type="a">
                any act, omission or commission, negligence or failure to comply
                with Your obligations provided hereunder;
              </li>
              <li Type="a">
                breach of representation and warranties set forth herein;
              </li>
              <li Type="a">
                any harmful act toward the Company or any other user of the
                Site;
              </li>
              <li Type="a">
                claim for any amounts in respect of loss of business, lost
                profits, multiple of profits, goodwill, business reputation,
                consequential damages or punitive damages caused to you due to
                any error(s) or bug(s) in the technology.
              </li>
            </ol>{' '}
          </p>
          <br />
          <h4>25.LIMITATIONS OF LIABILITY</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            <span>
              NOT WITHSTANDING ANYTHING CONTAINED HEREIN TO THE CONTRARY, IN NO
              EVENT SHALL THE COMPANY’S AGGREGATE LIABILITY HEREUNDER FOR ANY
              CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF ACTION, EXCEED THE
              SERVICE AMOUNT PAID IN PRECEDING SIX (6) MONTHS BY YOU TO THE
              COMPANY.
            </span>{' '}
            <br /> <br />
            <span>
              IN NO EVENT THE COMPANY OR ITS DIRECTORS, EMPLOYEES, AGENTS,
              AFFILAIATES, OFFICERS OR VENDORS SHALL BE LIABLE TO YOU OR ANY
              THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY,
              INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST OF
              PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES OR EXPENSES
              ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF
              THE POSSIBILITY OF SUCH DAMAGES.
            </span>
          </p>
          <br />
          <h4>26.ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            Visiting the Site, sending us emails, and completing online forms
            constitute electronic communications. You consent to receive
            electronic communications, and you agree that all agreements,
            notices, disclosures, and other communications We provide to You
            electronically, via email and on the Site, satisfy any legal
            requirement that such communication be in writing.
            <br />
            <br />
            <h6>
              YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS,
              ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES,
              POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US
              OR VIA THE SITE
            </h6>
            <br />
            <h6>
              You hereby waive all and any rights or requirements under any
              statutes, regulations, rules, ordinances, or other laws in any
              jurisdiction which require an original signature or delivery or
              retention of non-electronic records, or to payments or the
              granting of credits by any means other than electronic means.
            </h6>
          </p>
          <br></br>
          <h4>27.MISCELLANEOUS</h4>
          <p
            style={{
              fontFamily: 'arial',
            }}
          >
            <br />
            <ol>
              <li Type="a">
                The Company may assign, in whole or in part, its rights and
                obligations to others at any time. The Company shall not be
                responsible or liable for any loss, damage, delay, or failure to
                act caused by any cause beyond our reasonable control.
              </li>
              <li Type="a">
                If any term or provision of the Terms of Use is found by a court
                of competent jurisdiction to be invalid, illegal or otherwise
                unenforceable, the same shall not affect the other terms or
                provisions thereof or the whole of the Terms of Use, but such
                term or provision will be deemed modified to the extent
                necessary in the court's opinion to render such term or
                provision enforceable, and the rights and obligations of the
                parties will be construed and enforced accordingly, preserving
                to the fullest permissible extent the intent of the Terms of Use
                of the parties herein set forth
              </li>
              <li Type="a">
                The relationship between the Company and You shall be that of an
                independent contractor, and nothing herein should be construed
                to create a partnership, joint venture, or employer-employee
                relationship. Each party shall, at all times during the term,
                perform the duties and responsibilities herein without any
                control by the other party.
              </li>
              <li Type="a">
                The Company shall not be stood to perform under these Terms of
                Use as a result of occurrence of any Force Majeure events like
                acts of God, fire, wars, civil unrest, change in laws, rules and
                regulations or any other events affecting the performance of the
                Company.
              </li>
              <li Type="a">
                The Company and you agreed that the arrangement set out by these
                Terms and Conditions are on a non-exclusive basis.
              </li>
              <li Type="a">
                These Terms of Use constitute the entire agreement and
                understanding of the between the Company and You and shall
                supersede any prior understanding or representation of any kind
                preceding the date of the Terms of Use.
              </li>
              <li Type="a">
                {' '}
                You agree that these Terms of Use will not be construed against
                the Company by virtue of having drafted them. You hereby waive
                any and all defenses You may have based on the electronic form
                of these Terms of Use and the lack of signing by the parties
                hereto to execute these Terms of Use.
              </li>
              <li Type="a">
                You can contact our Customer Care for any information or help on {" "}
                <EmailLink />. The Company to the best of its abilities will try
                to resolve Your quer(y/ies).
              </li>
            </ol>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndCondition;