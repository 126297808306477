import { Box, Typography } from '@mui/material';
import MainImg from 'assets/Images/Landing/Footer/channel-engagement.png';

const Page7 = ({className}) => {
  return (
    <Box
        className={className}
        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 5}}
      >
        <Typography
            textAlign={'center'}
            color={'#00FFD8'}
            fontSize={'24px'}
            fontWeight={600}
            my={3}
            width={'100%'}
          >
            Building Next Level Cross Channel Engagement
          </Typography>

          <Box width={{xs: '90%', lg: '50%'}}>
          <img
            src={MainImg}
            alt=""
            width={'100%'}
            style={{ objectFit: 'cover' }}
          />
          </Box>
      </Box>
  )
}

export default Page7;