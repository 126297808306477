import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export const CustomSelect = ({ label, options, value, name, onChange, readOnly }) => {
  return (
    <FormControl sx={{ minWidth: '100%' }}>
      <InputLabel
        sx={{
          color: '#000000',
          '&.Mui-focused': {
            color: '#000000',
          },
          '&.Mui-disabled': {
            color: '#000000',
          },
        }}
      >
        {label}
      </InputLabel>
      <Select
        value={value}
        label={label}
        name={name}
        onChange={(e) => !readOnly && onChange(e, name)}
        inputProps={{ readOnly }}
        sx={{
          '& .MuiInputBase-input': {
            color: '#000000',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000000',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000000',
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000000',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: readOnly ? '#000000' : undefined,
          },
          '& .MuiSelect-select.Mui-disabled': {
            backgroundColor: 'transparent',
            color: '#000000',
            WebkitTextFillColor: '#000000',
          },
          '& .MuiMenuItem-root': {
            color: '#000000',
          },
        }}
      >
        {options?.map((option, index) => (
          <MenuItem key={index} value={option.value} sx={{ color: '#000000' }}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
