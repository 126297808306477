import { Box, Grid, Stack, Typography } from '@mui/material';
import SideImg from 'assets/Images/Landing/Body/segmention.png';
import SparkIcon from 'assets/Images/Landing/sparking.svg';

const Page4 = ({ classname }) => {
  return (
    <Box
      className={classname}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Grid
        container
        justifyContent={'space-between'}
        sx={{
          width: { xs: '90%', lg: '75%' },
          position: 'relative',
          top: { xs: '2rem', lg: 'auto' },
        }}
      >
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '1.4em', lg: '1.9em' },
              fontWeight: 600,
              color: 'rgba(102, 255, 232, 1)',
              marginY: 2,
              textTransform: 'uppercase',
            }}
          >
            Hyper Personalised <br></br>
            Segmentation 
          </Typography>

          <Typography
            sx={{
              fontSize: { xs: '1.2em', lg: '1.7em' },
              fontWeight: 500,
              color: 'rgba(255, 255, 255, 1)',
            }}
          >
            Deliver right Notifications to
            <br></br>
            <span style={{ color: 'rgba(102, 255, 232, 1)' }}>
              Right People!
            </span>
          </Typography>

          {[
            'Unlock the power of First party data for clear cut personalisation.',
          ].map((item,ind) => (
            <Stack key={ind} direction={'column'} spacing={2} my={2}>
              <Stack direction={'row'} spacing={2} alignItems={'start'}>
                <img src={SparkIcon} alt="" style={{ marginTop: '10px' }} />
                <Typography
                  sx={{
                    color: 'rgba(255, 255, 255, 1)',
                    fontSize: { xs: '0.8em', lg: '1em' },
                    width: { xs: '100%', lg: '70%' },
                  }}
                >
                  {item}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box
            sx={{
              width: { xs: '100%', sm: '60%', md: '50%', lg: '100%' },
              display: 'flex',
              justifyContent: { xs: 'center', lg: 'flex-end' },
            }}
          >
            <img
              src={SideImg}
              width={'100%'}
              height={'100%'}
              style={{ objectFit: 'cover' }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Page4;
