import { DeleteSequence, FetchAllSequence, UpdateSequence } from 'Api/Api';
import DashboardList from 'components/ListView';
import { DeleteModal } from 'components/Modals';
import SearchBar from 'components/SearchBar';
import { MainDashboard } from 'components/Style';
import { SequenceTableColumns } from 'constants/appConstant';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  resetState,
  updateToEdit,
} from '../../../redux/reducers/sequenceReducer';
import NoRecord from 'components/NoRecord';

const SequenceMain = () => {
  const [loading, setLoading] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const [searchTerms, setSearchTerms] = useState({});
  const [sequenceData, setSequenceData] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [selectedRows, setSelectedRows] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const NavigateToCreate = () => {
    navigate('/sequence/create/subscriber');
  };

  const EditSequence = (record) => {
    dispatch(updateToEdit(record));
    navigate(`/sequence/${record.id}/subscriber`);
  };

  const onClose = () => {
    setDeleteModalOpen(false);
  };

  const handleDeleteModel = (recordId) => {
    setDeleteModalOpen(true);
    setDeleteId(recordId);
  };

  async function fetch() {
    try {
      setInitialLoading(true);
      let response = await FetchAllSequence();
      if (response?.data?.status === true) {
        setSequenceData(response?.data?.data);
        setInitialLoading(false);
      }
    } catch (error) {
    } finally {
      setInitialLoading(false);
    }
  }
  async function updateStatus(item) {
    try {
      const { id, status } = item;
      setLoading((prevLoading) => ({
        ...prevLoading,
        [id]: true,
      }));
      const response = await UpdateSequence(id, {
        status: status === 'Active' ? 'Inactive' : 'Active',
      });
      if (response?.data?.status === true) {
        fetch();
      }
      setLoading((prevLoading) => ({
        ...prevLoading,
        [id]: false,
      }));
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteSequenceFunc(recordId) {
    try {
      const response = await DeleteSequence(recordId);
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        fetch();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    dispatch(resetState());
  }, []);
  return (
    <MainDashboard>
      <SearchBar placeHolder="Sequence" handleOpen={NavigateToCreate} />
      {sequenceData.length > 0 ? (
        <>
          <DashboardList
            tableData={sequenceData}
            rowData={SequenceTableColumns}
            handleEditModel={EditSequence}
            handleDeleteModel={handleDeleteModel}
            updateStatus={updateStatus}
            initialLoading={initialLoading}
            loading={loading}
            icons={['edit', 'delete']}
            sortingIndex={['status', 'sequenceName', 'createdTime', 'modifiedTime']}
            searchTerms={searchTerms}
            setSearchTerms={setSearchTerms}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
          <DeleteModal
            open={deleteModalOpen}
            close={onClose}
            placeholder="campaign"
            deleteFunction={() => deleteSequenceFunc(deleteId)}
          />
        </>
      ) : (
        <NoRecord
          type="callback"
          moduleName="Sequence"
          onAction={NavigateToCreate}
        />
      )}
    </MainDashboard>
  );
};

export default SequenceMain;
