import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Checkbox,
  Pagination,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import { listViewIcons, svgBorder } from 'constants/appConstant';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { formatDateTime } from 'utils/commonFunctions';
import { InnerTextField } from './InputFields';
import {
  hoverEffect,
  hoverdName,
  paginationFooter,
  paginationStyle,
  tablePagination,
  tableScrollbar,
} from './Style';



const DashboardList = ({
  rowData,
  tableData,
  handleEditModel,
  handleEnlargeModal,
  handleDeleteModel,
  updateStatus,
  sortingIndex,
  icons,
  initialLoading,
  searchTerms,
  setSearchTerms,
  selectedRows,
  setSelectedRows,
  noRecordComponent,
}) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [hoveredRow, setHoveredRow] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleSearchChange = (e, key) => {
    setSearchTerms((prev) => ({ ...prev, [key]: e.target.value }));
  };

  const handleSearchClick = () => {
    setSearchTerms((prev) => ({ ...prev }));
  };

  const handleSelectedAllRows = (e) => {
    if (e.target.checked) {
      setSelectedRows(paginatedData?.map((item) => item.id));
    } else {
      setSelectedRows([]);
    }
  };

  const handleSelectedRows = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleClickTooltip = (title, item) => {
    switch (title) {
      case 'Edit':
        console.log('Edit');
        handleEditModel(item, false);
        break;
      case 'Enlarge':
        handleEnlargeModal(item);
        break;
      case 'Delete':
        handleDeleteModel(item?.id);
        break;
      default:
        break;
    }
  };

  const filterData = (data) => {
    return data.filter((item) => {
      return Object.keys(searchTerms).every((key) => {
        if (key === 'page' || key === 'rowsPerPage') return true; // Skip page and rowsPerPage
        if (!searchTerms[key]) return true;
        return item[key]
          ?.toString()
          .toLowerCase()
          .includes(searchTerms[key].toLowerCase());
      });
    });
  };

  const sortedData = tableData?.sort((a, b) => {
    if (sortConfig.key && a[sortConfig.key] && b[sortConfig.key]) {
      const comparison = a[sortConfig.key].localeCompare(b[sortConfig.key]);
      return sortConfig.direction === 'asc' ? comparison : -comparison;
    }
    return 0;
  });

  const paginatedData = sortedData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const addProtocol = (connectionUrl) => {
    if (
      !connectionUrl?.startsWith('http://') &&
      !connectionUrl?.startsWith('https://')
    ) {
      return 'http://' + connectionUrl;
    }
    return connectionUrl;
  };

  const handleStatusToggle = async (item) => {
    try {
      await toast.promise(updateStatus(item), {
        loading: 'Updating status...',
        success: 'Status updated successfully',
        error: (error) => `Error: ${error.message}`,
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const truncateName = (name) => {
    return name?.length > 17 ? name.substring(0, 17) + '...' : name;
  };

  useEffect(() => {
    setSearchTerms((prev) => ({ ...prev, page, rowsPerPage }));
  }, [page, rowsPerPage]);

  useEffect(() => {
    setSelectedRows([]);
  }, [rowsPerPage]);

  const renderTableCell = (column, item) => {
    const isHoverable = column?.hoverable;

    return (
      <Box
        onMouseEnter={isHoverable ? () => setHoveredRow(item.id) : null}
        onMouseLeave={isHoverable ? () => setHoveredRow(null) : null}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: column.textAlign,
        }}
      >
        {column.id === 'templateName' ||
        column.id === 'campaignName' ||
        column.id === 'segmentName' ||
        column.id === 'connectionName' ? (
          <span style={hoverdName}>{truncateName(item[column.id])}</span>
        ) : (
          <span>{item[column.id]}</span>
        )}
        {isHoverable && hoveredRow === item.id && (
          <Box sx={hoverEffect}>
            {listViewIcons.map((data, index) => (
              <Tooltip title={data.title} arrow key={index}>
                <span style={{ cursor: 'pointer' }}>
                  <img
                    src={data.icon}
                    width={20}
                    height={20}
                    onClick={() => handleClickTooltip(data.title, item)}
                  />
                </span>
              </Tooltip>
            ))}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      <TableContainer
        sx={{
          ...tableScrollbar,
          height: '100%',
          maxHeight: '80vh',
          width: '100%',
          border: '1px solid #B9B9B9',
          borderRadius: 1,
          backgroundColor: '#f0f2f5',
          marginBottom: tableData?.length <= 0 ? '4.5%' : '',
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                padding="checkbox"
                sx={{
                  width:
                    paginatedData?.length > 5
                      ? '6%'
                      : selectedRows?.length > 1
                      ? '5.5%'
                      : '0.5%',
                }}
              >
                <Checkbox
                  indeterminate={
                    selectedRows?.length > 0 &&
                    selectedRows?.length < paginatedData?.length
                  }
                  checked={
                    paginatedData?.length > 0 &&
                    selectedRows?.length === paginatedData?.length
                  }
                  onChange={handleSelectedAllRows}
                />
                {selectedRows?.length > 1 && (
                  <DeleteIcon sx={{ marginLeft: 0 }} />
                )}
              </TableCell>
              {rowData?.map((item, index) => {
                const isLast = index === rowData.length - 1;
                return (
                  <TableCell
                    key={item.id}
                    align={item.align}
                    width={item.width}
                    sx={{
                      position: 'relative',
                      fontWeight: 'bold',
                      verticalAlign: 'top',
                    }}
                  >
                    {sortingIndex.includes(item.id) ? (
                      <TableSortLabel
                        active={sortConfig.key === item.id}
                        direction={sortConfig.direction}
                        onClick={() => handleSort(item.id)}
                      >
                        {String(item.label)}
                      </TableSortLabel>
                    ) : (
                      item.label
                    )}
                    {!isLast && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '0',
                          right: '0',
                          bottom: '0',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        dangerouslySetInnerHTML={{ __html: svgBorder }}
                      />
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
            {paginatedData?.length > 5 && (
              <TableRow>
                <TableCell
                  padding="checkbox"
                  sx={{ backgroundColor: 'transparent' }}
                >
                  <Box>
                    <Button
                      padding="checkbox"
                      size="small"
                      variant="contained"
                      onClick={handleSearchClick}
                      sx={{
                        width: '80%',
                        marginTop: 0.4,
                        marginX: 0.5,
                        textTransform: 'none',
                      }}
                    >
                      Search
                    </Button>
                  </Box>
                </TableCell>
                {rowData?.map((item, index) => (
                  <TableCell
                    key={`search-${item.id}`}
                    align={item.align}
                    width={item.width}
                    sx={{
                      padding: '4px',
                      background: 'transparent',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Box>
                        <InnerTextField
                          size="small"
                          margin="dense"
                          onChange={(e) => handleSearchChange(e, item.id)}
                          placeholder={`Search ${item.label}`}
                        />
                      </Box>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {initialLoading ? (
              Array.from({ length: 5 })?.map((_, index) => (
                <TableRow key={index}>
                  {rowData?.map((column) => (
                    <TableCell  key={column.id}>
                      <Skeleton variant="text" width="100%" height={20} />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : tableData?.length > 0 ? (
              paginatedData?.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'white',
                    },
                    height: '50px',
                    position: 'relative',
                  }}
                  onMouseEnter={() => setHoveredRow(item.id)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  <TableCell padding="checkbox" sx={{ position: 'relative' }}>
                    {hoveredRow === item.id && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '0',
                          left: '0',
                          bottom: '0',
                          width: '3px',
                          backgroundColor: '#07826F',
                          transition: 'all 0.3s ease',
                        }}
                      />
                    )}
                    <Checkbox
                      checked={selectedRows?.includes(item.id)}
                      onChange={() => handleSelectedRows(item.id)}
                    />
                  </TableCell>
                  {rowData?.map((column) => (
                    <TableCell
                      key={column.id}
                      width={column.width}
                      sx={{ textAlign: column.textAlign, padding: 0.5 }}
                    >
                      <Box>
                        {column.id === 'status' ? (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              position: 'relative',
                            }}
                          >
                            <Switch
                              checked={item.status === 'Active'}
                              onChange={() => handleStatusToggle(item)}
                            />
                          </Box>
                        ) : column.id === 'connectionUrl' ? (
                          <a
                            href={addProtocol(item.connectionUrl)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p className="text-blue-700">
                              {item?.connectionUrl}
                            </p>
                          </a>
                        ) : column.id === 'createdTime' ||
                          column.id === 'modifiedTime' ? (
                          <span>{formatDateTime(item[column.id])}</span>
                        ) : (
                          renderTableCell(column, item)
                        )}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={(rowData?.length ?? 0) + 1}
                  align="center"
                  sx={{ border: 'none', p: 0 }}
                >
                  {noRecordComponent} 
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={paginationFooter}>
        {tableData?.length > 0 && (
          <Pagination
            count={Math.ceil(tableData?.length / rowsPerPage)}
            page={page + 1}
            onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
            color="primary"
            sx={paginationStyle}
          />
        )}
        {tableData?.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={tableData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => ''}
            sx={tablePagination}
          />
        )}
      </Box>
    </>
  );
};

export default DashboardList;