import { Box, Tabs } from '@mui/material';
import EnlargeModal, {
  CustomTab,
  CustomTabPanel,
  a11yProps,
} from 'components/EnlargeModal';
import React from 'react';
import Analytics from './Enlarge/Analytics';
import Details from './Enlarge/Details';
import Preview from './Enlarge/Preview';
import Activity from './Enlarge/Activity';

const ViewTemplateModal = ({ open, onCloseModal, item, refresh }) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <EnlargeModal open={open} onCloseModal={onCloseModal} title={'Template'}>
      <Box width={'100%'}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ minHeight: '40px' }}
          >
            <CustomTab
              label="Preview "
              {...a11yProps(0)}
              sx={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}
            />
            <CustomTab
              label="Analytics"
              {...a11yProps(1)}
              sx={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}
            />
            <CustomTab
              label="Details"
              {...a11yProps(2)}
              sx={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}
            />
            <CustomTab
              label="Activity"
              {...a11yProps(3)}
              sx={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}
            />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <Preview />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Analytics />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Details item={item} refresh={refresh}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Activity item={item} />
        </CustomTabPanel>
      </Box>
    </EnlargeModal>
  );
};

export default ViewTemplateModal;
