import React, { useEffect, useState } from 'react';
import NoRecord from 'components/NoRecord';
import CreateTemplateModal from 'pages/protected/Templates/CreateTemplateModal';
import Details from 'pages/protected/Templates/Enlarge/Details';
import { FetchAllTemplate } from 'Api/Api';
import toast from 'react-hot-toast';
import { CircularProgress, Typography } from '@mui/material';

export const Welcome = ({ item }) => {
  const [openModal, setOpenModal] = useState(false);
  const [welcomeTemplate, setWelcomeTemplate] = useState({});
  const [flag, setFlag] = useState(false);
  const [loading, setLoading] = useState();
  const [error, setError] = useState(null);

  const handleCreateModal = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  async function fetch() {
    setLoading(true);
    try {
      let response = await FetchAllTemplate();
      if (response?.data?.status === true) {
        let alltemplates = response?.data?.data;
        const welcomeTemplate = alltemplates.find(
          (template) => template.welcomeTemplate === true
        );
        if (welcomeTemplate) {
          setWelcomeTemplate(welcomeTemplate);
          setFlag(true);
        } else {
          setFlag(false);
        }
      }
    } catch (error) {
      setLoading(false);
      setError('Failed to fetch welcome template.');
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetch();
  }, []);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <>
      {flag ? (
        <Details item={welcomeTemplate} onClose={handleClose} refresh={fetch} />
      ) : (
        <NoRecord
          type="callback"
          moduleName="Welcome Template"
          onAction={handleCreateModal}
        />
      )}
      <CreateTemplateModal
        onClose={handleClose}
        open={openModal}
        item={item}
        welcome={true}
        refresh={fetch}
      />
    </>
  );
};
