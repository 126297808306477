import { Button } from '@mui/material';
import { FetchAllSubscribers } from 'Api/Api';
import SearchBar from 'components/SearchBar';
import ListView from 'components/ListView';
import NoRecord from 'components/NoRecord';
import { MainDashboard } from 'components/Style';
import { SubscribersTableColumns } from 'constants/appConstant';
import { useEffect, useState } from 'react';
import ViewSubscribersModal from './ViewSubscribersModal';

const Subscribers = () => {
  const [subscribersData, setSubscribersData] = useState([]);
  const [searchTerms, setSearchTerms] = useState({});
  const [loading, setLoading] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);

  const [openModal, setOpenModal] = useState(false);

  async function fetch() {
    try {
      let response = await FetchAllSubscribers();
      if (response?.data?.status === true) {
        setSubscribersData(response?.data?.data);
      }
    } catch {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetch();
  }, []);

  return (
    <MainDashboard>
      <SearchBar placeHolder="Subscribers" createButton={false} />
      <ListView
        tableData={subscribersData}
        rowData={SubscribersTableColumns}
        icons={['', 'delete']}
        sortingIndex={['status', 'createdTime', 'modifiedTime']}
        loading={loading}
        searchTerms={searchTerms}
        setSearchTerms={setSearchTerms}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        noRecordComponent={
          <NoRecord
            type="callback"
            moduleName="Subscriber"
            // onAction={handleCreateModal}
          />
        }
      />

      <ViewSubscribersModal
        open={openModal}
        onCloseModal={() => {
          setOpenModal(false);
        }}
      />
    </MainDashboard>
  );
};

export default Subscribers;
